import React from 'react';
import './NPCoolRadio.css';

/**
 * Parameters
 * 
 *  - noDefault         :   (OPT, default false) The default behaviour is that, if no "prefilled" value is provided, the component
 *                          automatically selects the first value as a default (first option)
 *                          Pass true in the "noDefault" field to inhibit that behaviour: in that case the component will have nothing selected
 */
export default class NPCoolRadio extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.selectOption = this.selectOption.bind(this);
    }

    componentDidMount() {
        // Preselect an option
        this.selectOption(this.props.prefilled ? this.props.prefilled : (this.props.noDefault === true ? null : 1));
    }

    /**
     * Selects option x
     * @param {int} option the selected option number
     */
    selectOption(option) {

        // Change the selection
        this.setState({
            selectedOption: option
        }, () => {

            // Update caller if any
            if (option && this.props.onChange) this.props.onChange(option);

            if (!option || !this.floatingButton) return;

            // Move the floating button
            let left;
            if (option == 2) left = this.option2Button.offsetLeft;
            else left = this.option1Button.offsetLeft;

            this.floatingButton.style.left = left + 'px';

        })

    }

    render() {
        return (
            <div className="np-cool-radio">
                <div className="np-input-label">
                    {this.props.label}
                </div>
                <div className="radio-container">
                    <div className="option-label" ref={(el) => { this.option1Button = el }} onClick={() => { this.selectOption(1) }}>{this.props.option1}</div>
                    <div className="option-label" ref={(el) => { this.option2Button = el }} onClick={() => { this.selectOption(2) }}>{this.props.option2}</div>
                    {this.state.selectedOption && <div className="floating-button fill-accent focused-easeInOut-motion" ref={(el) => { this.floatingButton = el; }}></div>}
                </div>
            </div>
        )
    }
}