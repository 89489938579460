import { useState } from "react";
import { NPCheckBox } from "../../forms/NPCheckBox";
import NPCard from "../NPCard";
import NPIconButton from '../../comp/NPIconButton';

import { ReactComponent as EditSVG } from '../../icons/pencil.svg';
import { useTranslation } from "react-i18next";
import NPButton from "../../comp/NPButton";

export function PrivacyTermsFlagsCard({ profile, onEdit, editMode, onSave, onCancel, editable }) {

    const [privacyAccepted, setPrivacyAccepted] = useState(profile ? profile.acceptedPrivacy : false)
    const [termsAccepted, setTermsAccepted] = useState(profile ? profile.acceptedTerms : false)
    const [specialTermsAccepted, setSpecialTermsAccepted] = useState(profile ? profile.acceptedSpecialTerms : false)

    const { t } = useTranslation()

    const onSaveClick = () => {
        if (onSave) onSave({
            acceptedPrivacy: privacyAccepted,
            acceptedTerms: termsAccepted,
            acceptedSpecialTerms: specialTermsAccepted
        })
    }

    let showEditButton = true;
    if (editable === false) showEditButton = false;

    return (
        <NPCard>
            <div className="card-title font-large">Accettazione Privacy & Terms</div>
            <div className="card-content row space-evenly mobile-col" style={{marginTop: 10, marginBottom: 10}}>
                <div style={{marginTop: 6, marginBottom: 6}}>
                    <NPCheckBox label="Accetazione Privacy" editMode={editMode} onClick={() => { setPrivacyAccepted(!privacyAccepted) }} ticked={privacyAccepted} />
                </div>
                <div style={{marginTop: 6, marginBottom: 6}}>
                    <NPCheckBox label="Accetazione Termini" editMode={editMode} onClick={() => { setTermsAccepted(!termsAccepted) }} ticked={termsAccepted} />
                </div>
                <div style={{marginTop: 6, marginBottom: 6}}>
                    <NPCheckBox label="Accetazione Termini Speciali" editMode={editMode} onClick={() => { setSpecialTermsAccepted(!specialTermsAccepted) }} ticked={specialTermsAccepted} />
                </div>
            </div>
            {!editMode && showEditButton &&
                <div className="card-edit-button">
                    <NPIconButton image={<EditSVG />} onClick={onEdit} size="small" />
                </div>
            }
            {editMode == true &&
                <div class="master-data-buttons-container" style={{ marginTop: "24px" }}>
                    <NPButton label={t('button.label.cancel')} secondary={true} onClick={onCancel} />
                    <div style={{ flex: 1 }}></div>
                    <NPButton label={t('button.label.save')} onClick={onSaveClick} />
                </div>
            }
        </NPCard>
    )

}
