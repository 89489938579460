import React from 'react';
import './ProfileIdentityDocCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import NPPhoto from '../../comp/NPPhoto';
import FileAPI from '../../api/FileAPI';
import ProfileAPI from '../../api/ProfileAPI';
import NPIconButton from '../../comp/NPIconButton';

import { ReactComponent as EditSVG } from '../../icons/pencil.svg';

/**
 * Displays the identity document data of a profile:
 * 
 * Parameters: 
 * 
 *  - showValidatedFields           :   (OPT, default false) - Boolean - Shows a green checkmark on OCR-validated fields, a warn otherwise
 *  - showNonValidatedFields        :   (OPT, default false) - Boolean - Shows a warning sign on non OCR-validated fields
 *  - showOCRData                   :   (OPT, default false) - Boolean - Shows the link to see OCR data
 *  - ownProfile                    :   (OPT, default false) - Boolean - The profile is the connected user's profile - This will load the user's own picture
 * 
 */
class ProfileMasterDataCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.fieldVerified = this.fieldVerified.bind(this);
        this.loadPictures = this.loadPictures.bind(this);
        this.loadOCRData = this.loadOCRData.bind(this);
    }

    /**
     * Checks whether the field has been OCR verified
     */
    fieldVerified(field) {

        if (!this.props.profile) return false;

        // Codice Fiscale is treated differently: there's a field cfPass for that
        if (field == 'cf') {

            if (!this.props.profile.cfPass) return false;

            return true;
        }

        // Other fields are recognized through OCR
        if (!this.props.profile.ocrMatches || this.props.profile.ocrMatches.length == 0) return false;

        return this.props.profile.ocrMatches.includes(field);

    }

    componentDidMount() {
        this.loadPictures();
        this.loadOCRData();
    }

    loadOCRData() {
        new ProfileAPI().getOCRDataByUserId(this.props.profile.userId).then((data) => {
            this.setState({
                ocrTokens: data ? data.tokens : null
            })
        })
    }

    loadPictures() {

        let frontLink = this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocPhotoFrontLink : this.props.profile.docPictureFrontLink;
        let backLink = this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocPhotoBackLink : this.props.profile.docPictureBackLink;

        if (!this.props.ownProfile) {

            new FileAPI().adminDownloadIDPhotoStream(frontLink, this.props.profile.userId).then((imgSrc) => {
                this.setState({ idDocFront: imgSrc });
            })

            new FileAPI().adminDownloadIDPhotoStream(backLink, this.props.profile.userId).then((imgSrc) => {
                this.setState({ idDocBack: imgSrc });
            })

        }
        else {

            new FileAPI().downloadIDPhotoStream(frontLink).then((imgSrc) => {
                this.setState({ idDocFront: imgSrc });
            })

            new FileAPI().downloadIDPhotoStream(backLink).then((imgSrc) => {
                this.setState({ idDocBack: imgSrc });
            })

        }
    }

    render() {

        const { t } = this.props;

        return (
            <NPCard>
                <div className="identity-doc-card">
                    <div className="card-title font-large">{t(this.props.profile.userType == 'company' ? 'card.profile.identity.doc.leg.rep.title' : 'card.profile.identity.doc.title')}</div>
                    <div className="card-content">
                        <div className="column">
                            <NPLabeledValue label="label.doc.type" value={t(this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocType : this.props.profile.docType)} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('docType')} />
                            <NPLabeledValue label="label.doc.num" value={this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocNum : this.props.profile.docNum} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('docNum')} warnIfNoCheckmark={this.props.profile.userType != 'company' && this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('docNum')} />
                            <NPLabeledValue label="label.doc.expiry.date" value={this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocExpiryDate : this.props.profile.docExpiryDate} marginVertical="6px" type="date" checkmark={this.props.showValidatedFields && this.fieldVerified('docExpiryDate')} warnIfNoCheckmark={this.props.profile.userType != 'company' && this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('docExpiryDate')} />
                        </div>
                        <div className="column">
                            <NPPhoto ocrLink={this.props.showOCRData} ocrData={this.state.ocrTokens} photo={this.state.idDocFront} />
                        </div>
                        <div className="column">
                            <NPPhoto photo={this.state.idDocBack} />
                        </div>
                    </div>
                </div>
                <div className="card-edit-button">
                    <NPIconButton image={<EditSVG />} onClick={this.props.onEdit} size="small" />
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(ProfileMasterDataCard);