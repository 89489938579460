import NPAPI from './NPAPI';

export default class AuthAPI {

  activateAccount(activationCode) {
    return new NPAPI().fetch('auth', '/activate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        activationCode: activationCode
      })
    }).then((response) => response.json());

  }

  async deleteUser(userId) {
    return new NPAPI().fetch('auth', `/admin/registrations/${userId}`, { method: 'DELETE' }).then((response) => response.json())
  }

  /**
   * Retrieves the registrations (users)
   */
  async getRegistrations() {
    return new NPAPI().fetch('auth', `/admin/registrations`).then((response) => response.json())
  }

  /**
   * Retrieves a specific registration (user)
   */
  async getRegistrationDetails(userId) {
    return new NPAPI().fetch('auth', `/admin/registrations/${userId}`).then((response) => response.json())
  }

}
