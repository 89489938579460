import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import './NPList.css';
import { ReactComponent as TickSVG } from '../../icons/tick.svg';
import moment from 'moment-timezone';
import NPOnOff from '../NPOnOff';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';

/**
 * Cretes a list out of a dataset, with all the list functionalities
 * 
 * Parameters
 * 
 *  - data                      :   (MAND) the dataset as an array of json objects [{}, {}, ...]
 *  - dataConverter             :   (MAND) a function(item) => itemMetadata that takes an item of the dataset and converts it. See down for the specs of the metadata object
 *  - types                     :   (MAND) and array of types
 *                                  Supported types are ["string", "date", "datetime", "flag", "number", "price", "icon", "short-date"]
 *  - mobileVisible             :   (OPT) a list of booleans that specify if the element needs to be added, when using mobile
 *  - elementsSizing            :   (OPT) a list of sizes of each element in a line
 *                                  The sizes are passed as an array []
 *                                  Each element of the array is either a percentage (e.g. "30%") or null
 *                                      - When the element is a percentage, it will be interpreted as flex-basis: x%
 *                                      - When null, it will be interpreted as a flex: 1
 *  - headers                   :   (MAND) a list of headers (array of strings)
 *  - filtersOn                 :   (OPT, default false) toggles on and off the filters
 *  - filterHandlers            :   (OPT, default null) an array of callbacks called when a filter is changed
 * 
 * Callbacks
 * 
 *  - onItemClick               :   (OPT) a function that will be called when the user clicks on a line.
 *          Note that the rawItem will be passed (json object of the dataset)
 * 
 * Specs for itemMetadata
 * To be able to render the data, NPList has a standard format to describe an item of the dataset. 
 * This spec describes that format. 
 * When NPList passes an item to the dataConverter() function it expects in return this kind of (meta)data structure: 
 * {
 *  - values        :   An array of values. The values are going to be displayed based on the type defined in the "types" property of NPList
 *                      Example_
 *                      { value: item.licensePlates }
 * 
 * }
 * 
 * 
 */
class NPList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.onItemClick = this.onItemClick.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.generateIconFilters = this.generateIconFilters.bind(this);
    }

    /**
     * Reacts to a click of a line
     * @param {object} item the item clicked
     */
    onItemClick(item) {

        if (this.props.onItemClick) this.props.onItemClick(item);
    }

    /**
     * Reacts to the change of the specified filter
     */
    onFilterChange(filterIndex, value) {

        if (this.props.filterHandlers && this.props.filterHandlers.length > filterIndex && this.props.filterHandlers[filterIndex]) {

            if (this.props.types[filterIndex] == 'icon') {

                // Deselect filter if it's selected
                if (this.state.filterSelectedIcon == value) {
                    value = null;
                }

                this.setState({ filterSelectedIcon: value });
            }

            this.props.filterHandlers[filterIndex](value);
        }

    }

    /**
     * This function generates the filter for icon fields
     * The filter is going to show a list of the different icons and allow the user to select or deselect a specific icon
     */
    generateIconFilters(iconIndex) {

        if (!this.props.data) return;

        let icons = [];
        let iconNames = [];
        this.props.data.forEach((rawItem, i) => {

            let item = this.props.dataConverter(rawItem);
            let icon = item.values[iconIndex].value;
            let iconName = item.values[iconIndex].iconName;

            if (!icon) return;
            if (iconNames.includes(iconName)) return;

            iconNames.push(iconName);

            let iconClass = 'filter-icon-container';
            if (this.state.filterSelectedIcon == iconName) iconClass += ' primary';
            else iconClass += ' accent';

            icons.push((
                <div className={iconClass} key={'filter-icon' + i} onClick={() => this.onFilterChange(iconIndex, iconName)}>
                    {icon}
                </div>
            ));

        })

        return (
            <div className="icon-filter-container">
                {icons}
            </div>
        );

    }

    render() {

        const { t } = this.props;

        let filtersClass = "list-filters font-small focused-easeInOut-motion";
        let filtersStyles = { height: 0, margin: '0 -24px', padding: "0 calc(6px + 24px)" };

        if (this.props.filtersOn) {
            filtersStyles.height = '32px';
            filtersStyles.margin = '0 -24px 12px -24px';
            filtersStyles.padding = '6px calc(6px + 24px)';

            filtersClass += ' fill-primary';
        }

        return (
            <div className="np-list focused-easeInOut-motion" style={{ ...this.props.style }}>

                <div className={filtersClass} style={filtersStyles}>

                    {this.props.types.map((type, typeIndex) => {

                        let filter;
                        if (type == 'flag') filter = (<NPOnOff onChange={(value) => { this.onFilterChange(typeIndex, value) }} />)
                        else if (type == 'icon') filter = this.generateIconFilters(typeIndex);

                        // Define styles (based on passed properties)
                        let sizing = this.props.elementsSizing[typeIndex];

                        let styles = {};

                        if (sizing) styles.flexBasis = sizing;
                        else styles.flex = 1;

                        return <div key={'filter-' + typeIndex} className="list-filter" style={styles}>{filter}</div>

                    })}

                </div>

                <div className="list-header font-small">

                    {this.props.headers.map((header, headerIndex) => {

                        let type = this.props.types[headerIndex];

                        // Define styles (based on passed properties)
                        let flexStyle = { flex: 1 };
                        if (this.props.elementsSizing[headerIndex]) flexStyle = { flexBasis: this.props.elementsSizing[headerIndex] };

                        let styles = { ...flexStyle };

                        if (type == 'flag') styles.justifyContent = 'center';
                        else if (type == 'icon') styles.justifyContent = 'center';

                        return <div key={'header-field-' + headerIndex} className="header-field" style={styles}>{header}</div>

                    })}

                </div>

                <div className="list-body">
                    {this.props.data && this.props.data.map((rawItem, index) => {

                        let item = this.props.dataConverter(rawItem);
                        let values = item.values;

                        return (
                            <div className="list-item-container" key={'list-item-' + index} onClick={() => { this.onItemClick(rawItem) }}>
                                <div className="line font-small">

                                    {values.map((value, valueIndex) => {

                                        // Format the value
                                        let formattedValue = value.value;
                                        let type = this.props.types[valueIndex];
                                        let mobileVisible = this.props.mobileVisible ? this.props.mobileVisible[valueIndex] : true;

                                        if (type == 'date') formattedValue = value.value ? moment(value.value, 'YYYYMMDD').format('DD.MM.YYYY') : 'N.A.';
                                        else if (type == 'price') formattedValue = value.value ? value.value.toLocaleString('it-IT', { style: "currency", currency: "EUR" }) : "N.A.";
                                        else if (type == 'short-date') formattedValue = value.value ? moment(value.value, 'YYYYMMDD').format('DD.MM') : 'N.A.';
                                        else if (type == 'datetime') formattedValue = value.value ? moment(value.value, 'YYYYMMDDHHmmss').format('DD.MM.YYYY HH:mm') : 'N.A.';
                                        else if (type == 'flag') formattedValue = value.value ? (<TickSVG />) : '';
                                        else if (type == 'icon') {
                                            if (value.value) {
                                                if (value.tooltip) {
                                                    if (value.tooltipType == 'warning') formattedValue = (<Tippy content={value.tooltip} theme="light"><span className="complementary">{value.value}</span></Tippy>)
                                                    else formattedValue = (<Tippy content={value.tooltip} theme="light"><span className="primary">{value.value}</span></Tippy>)
                                                }
                                                else formattedValue = <span className="primary">{value.value}</span>;
                                            }
                                            else formattedValue = '';
                                        }
                                        else if (type == 'icons') {
                                            formattedValue = (
                                                <div className="icons-list">
                                                    {value.map((item) => {
                                                        if (item.value) {
                                                            if (item.tooltip) {
                                                                if (item.tooltipType == 'warning') return (<Tippy content={item.tooltip} theme="light" key={Math.random()}><span className="complementary">{item.value}</span></Tippy>)
                                                                else return (<Tippy content={item.tooltip} theme="light" key={Math.random()}><span style={{ color: item.fill ? item.fill : "var(--color-primary)" }}>{item.value}</span></Tippy>)
                                                            }
                                                            else return item.value;
                                                        }
                                                    })}
                                                </div>
                                            )
                                        }

                                        // Define the class name for the value
                                        let valueClass = "value";
                                        if (type == 'flag') valueClass += ' flag primary';
                                        else if (type == 'icon' || type == 'icons') valueClass += ' icon';

                                        if (!mobileVisible) valueClass += ' not-mobile-visible';

                                        // Define styles (based on passed properties)
                                        let sizing = this.props.elementsSizing[valueIndex];

                                        let flexStyle = { flex: 1 };
                                        if (sizing) flexStyle = { flexBasis: sizing };

                                        let styles = { ...flexStyle };

                                        return (<div key={"item-value-" + valueIndex} className={valueClass} style={styles}>{formattedValue}</div>)

                                    })}

                                </div>
                                {(index < this.props.data.length - 1) && <div className="separator"></div>}
                            </div>
                        )
                    })}
                </div>

            </div>
        )
    }
}

export default withTranslation()(NPList);