import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './LoginScreen.css';
import './Screen.css';
import loginBackground from '../images/login-illustration.svg';
import { ReactComponent as GoogleSVG } from '../icons/google.svg';
import { ReactComponent as FBSVG } from '../icons/fb.svg';
import NPButton from '../comp/NPButton';
import Cookies from 'universal-cookie';
import AccessAPI from '../api/AccessAPI';
import { withRouter } from 'react-router';
import { storeUser } from '../util/UserUtil';
import * as FacebookAuth from '../util/AuthProviderFB';
import * as GoogleAuth from '../util/AuthProviderGoogle';
import { ReactComponent as LoaderSVG } from '../icons/loader-ball-triangle.svg';
import pushListener from '../evt/PushListener';
import { ReactComponent as PleggitTitle } from '../images/logo/pleggit-title.svg';
import Footer from '../comp/Footer';

const cookies = new Cookies();

class LoginScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingFB: true,
            loadingGoogle: true
        }

        this.checkSigninStatus = this.checkSigninStatus.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signInFB = this.signInFB.bind(this);
        this.clearUserCookie = this.clearUserCookie.bind(this);
        this.getInTheApp = this.getInTheApp.bind(this);
        this.forbidAccess = this.forbidAccess.bind(this);
    }

    componentDidMount() {
        this.checkSigninStatus();
    }

    /**
     * Checks if the user is signed in and in case it is, logs into the site
     */
    checkSigninStatus() {

        this.setState({ loadingGoogle: true, loadingFB: true });

        // Check in signin status with Google
        GoogleAuth.checkSigninStatus().then((result) => {

            this.setState({ loadingGoogle: false });

            if (result && result.signedIn) {

                console.log("[login] - The user is signed in with Google! Checking if the user is actually authorized to login");

                // Check if the user is authorized to enter (temporary)
                GoogleAuth.checkUserAuthorization().then((result) => {

                    if (result.user) this.getInTheApp(result.user, 'google');

                }, (err) => {

                    this.forbidAccess();
                })
            }
            else console.log("[login] - User is not signed in with Google.");


        }, (err) => {

            this.setState({ loadingGoogle: false });

            if (err && err.error === 'notloaded') {
                console.log("[login] - Google API not loaded... waiting..");
            }
        });

        // Check sign in status with Facebook
        // FacebookAuth.checkSigninStatus().then((result) => {

        //     this.setState({ loadingFB: false });

        //     if (result && result.signedIn) {

        //         console.log("[login] - The user is signed in with Facebook! Checking if the user is actually authorized to login");

        //         // Check if the user is authorized to enter (temporary)
        //         FacebookAuth.checkUserAuthorization(result.authResponse).then((result) => {

        //             if (result.user) this.getInTheApp(result.user, 'fb');

        //         }, (err) => {

        //             this.forbidAccess();
        //         })
        //     }
        //     else console.log("[login] - User is not signed in with Facebook.");


        // }, (err) => {

        //     this.setState({ loadingFB: false });

        //     if (err && err.error === 'notloaded') {
        //         console.log("[login] - Facebook API not loaded... waiting..");
        //     }
        // });

    }

    /**
     * Alright, you have signed in and are actually authorized to enter, so let's do that! 
     */
    getInTheApp(user, authProvider) {

        console.log("[login] - User is authorized to access!");

        storeUser(user, authProvider);

        // Initialize Push Listener
        pushListener.onLogin(user.userId);

        this.props.history.push('/dashboard');

    }

    /**
     * Forbids access to the user
     */
    forbidAccess(user) {

        console.log("[login] - User is not authorized");

        this.setState({
            authorizationCheckResult: false
        });

        this.clearUserCookie();
    }

    /**
     * Clears the user cookie (removes it)
     */
    clearUserCookie() {

        console.log("[login] - User is NOT authorized to access..");
        console.log("[login] - Clearing the user cookie..");

        cookies.remove('user');
    }

    /**
     * Google sign in
     */
    signIn() {

        GoogleAuth.signIn().then((result) => {

            if (result.user) this.getInTheApp(result.user, 'google');

        }, (err) => {

            if (err && err.error)
                if (err.error === 'notauthorized') this.forbidAccess();
                else if (err.error === 'cantsignin') console.log("Could not sign in with Google. Check console logs.");
        })

    }

    /**
     * Facebook sign in
     */
    signInFB() {

        // FacebookAuth.signIn().then((result) => {

        //     if (result.user) this.getInTheApp(result.user, 'fb');

        // }, (err) => {

        //     if (err && err.error)
        //         if (err.error === 'notauthorized') this.forbidAccess();
        //         else if (err.error === 'cantsignin') console.log("Could not sign in with Facebook. Check console logs.");
        // })

    }

    render() {

        const { t } = this.props;

        let message;
        if (this.state.authorizationCheckResult == false) message = (
            <div className="auth-failed-message complementary">
                {t('login.access.not.authorized')}
            </div>
        );

        let loginText;
        if (!this.state.loadingGoogle) loginText = (
            <div className="login-text">
                {t('login.welcome.label')}<br />
                {t('login.methods.label')}
            </div>
        )
        
        let loginGoogle;
        if (!this.state.loadingGoogle) loginGoogle = (
            <div className="login-button-container">
                <div className="login-button  focused-easeInOut-motion" onClick={this.signIn}>
                    <div className="icon-container"><GoogleSVG className="icon" /></div>
                    <div className="login-button-text">{t('login.google.label')}</div>
                </div>
            </div>
        )
        
        // let loginFB;
        // if (!this.state.loadingGoogle && !this.state.loadingFB) loginFB = (
        //     <div className="login-button-container">
        //         <div className="login-button fb dark-background bold focused-easeInOut-motion" onClick={this.signInFB}>
        //             <div className="icon-container fb"><FBSVG className="icon" /></div>
        //             <div className="login-button-text">{t('login.fb.label')}</div>
        //         </div>
        //     </div>
        // )

        let loader;
        if (this.state.loadingGoogle) loader = (
            <div className="loader-container">
                <LoaderSVG/>
            </div>
        )

        return (
            <div className="login-screen screen">

                <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <div className="login-container">
                        <div className="login-background">
                            <img src={loginBackground} alt="Login" width="100%" />
                        </div>
                        <div className="login-np image-container" onClick={() => { this.props.history.push('/') }}>
                            <PleggitTitle />
                        </div>
                        {loginText}
                        {loginGoogle}
                        {/* {loginFB} */}
                        {loader}
                        {message}
                    </div>
                </div>

                <div style={{display: 'flex', flex: 1}}></div>

                <Footer/>

            </div>
        )
    }
}

export default withRouter(withTranslation()(LoginScreen));