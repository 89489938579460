import './AdminTransactions.css';
import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import AgencyTranAPI from '../api/AgencyTranAPI';
import NPList from '../comp/list/NPList';
import NPCard from '../cards/NPCard';
import { NPCardOutsideHeader } from '../cards/NPCardOutsideHeader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { ReactComponent as CarSVG } from '../icons/car.svg'
import { ReactComponent as HandsSVG } from '../icons/handshake.svg'

function Content() {

    const [transactions, setTransactions] = useState()

    const history = useHistory()

    /**
     * Loads the transactions
     */
    const loadTransactions = async () => {

        const apiResponse = await new AgencyTranAPI().getTransactionsAdmin();

        if (apiResponse && apiResponse.transactions) setTransactions(apiResponse.transactions);
    }

    /**
     * Converts the data for the NPList
     */
    const dataConverter = (item) => {

        let statusIcon;
        if (item.buyerPaid && !item.propertyTransferred) statusIcon = <CarSVG />
        else if (item.buyerPaid && item.propertyTransferred) statusIcon = <HandsSVG />

        return {
            values: [
                { value: item.licensePlates },
                { value: item.price },
                { value: item.agencyEmail },
                { value: item.createdOn },
                { value: statusIcon }
            ]
        }

    }

    useEffect(() => { loadTransactions() }, [])

    return (
        <div className="admin-transactions-screen">
            <NPCardOutsideHeader
                title="Transazioni Agenzia"
            />
            <NPCard>
                <NPList
                    data={transactions}
                    dataConverter={dataConverter}
                    types={["string", "price", "string", "short-date", "icon"]}
                    mobileVisible={[true, false, true, true, true]}
                    elementsSizing={["20%", "20%", null, "15%", "5%"]}
                    headers={["Targa", "Prezzo", "Agenzia", "Creata il", "Stato"]}
                    onItemClick={(tx) => { history.push('/admin/agencyTransactions/detail', { tx: tx }) }}
                />
            </NPCard>
        </div>
    )

}

function Title() {

    return (
        <div className="admin-transactions-title">
        </div>
    )
}

export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(Content)),
        "wide"
    )
);