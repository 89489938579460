import NPAPI from './NPAPI';

export default class TranAPI {

  /**
   * Creates the transaction
   * 
   * @param {object} data the data to create the transaction
   * Fields have to follow this strict convention: 
   *  - partyType         : ('buyer', 'seller')
   *  - itemType          : ('scooter', 'car', 'bike', 'boat', 'airplane', 'other')
   *  - name              : transaction name
   *  - licensePlates     : plates
   *  - price             : price
   *  - currency          : currency (EUR, etc..)
   *  - iban              : iban
   */
  postTransaction(data) {

    let tran = {
      partyType: data.partyType,
      itemType: data.itemType,
      name: data.name,
      licensePlates: data.licensePlates,
      price: data.price,
      currency: data.currency,
      iban: data.iban
    };

    return new NPAPI().fetch('tran', '/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tran)
    }).then((response) => response.json());

  }

  putTransaction(id, data) {

    let tran = {
      partyType: data.partyType,
      itemType: data.itemType,
      name: data.name,
      licensePlates: data.licensePlates,
      price: data.price,
      currency: data.currency,
      iban: data.iban
    };

    return new NPAPI().fetch('tran', '/transactions/' + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tran)
    }).then((response) => response.json());

  }

  /**
   * Retrieves the list of past transactions
   */
  getPastTransactions() {

    return new NPAPI().fetch('tran', '/transactions?completed=true').then((response) => response.json());

  }

  /**
   * Retrieves the last transaction
   */
  getLatestTransaction() {

    return new NPAPI().fetch('tran', '/transactions/latest').then((response) => response.json());

  }

  /**
   * Retrieves the specified transaction
   */
  getTransaction(id) {

    return new NPAPI().fetch('tran', '/transactions/' + id).then((response) => response.json());
    
  }
  
  getPayoutAmount(transactionId) {
    
    return new NPAPI().fetch('tran', '/transactions/' + transactionId + '/payout').then((response) => response.json());

  }

  /**
   * Checks whether the counterpart has confirmed its transaction's completion
   */
  syncCounterpart(tid) {

    return new NPAPI().fetch('tran', '/transactions/' + tid + '/counterpartsync').then((response) => response.json());

  }

  /**
   * Posts the exchange code received by the counterpart and verifies it. 
   * If successfull, this will have updated the transaction with the counterpart ecode
   * @param {string} tid the transaction id
   * @param {string} code the exchange code received by the counterpart
   * @returns Promise
   */
  postExchangeCode(tid, code) {

    return new NPAPI().fetch('tran', '/transactions/' + tid + '/exchangeCode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ counterpartExchangeCode: code })
    }).then((response) => response.json());

  }

  postPTLink(tid, ptLink) {

    return new NPAPI().fetch('tran', '/transactions/' + tid + '/docs/transfer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ propertyTransferLink: ptLink })
    }).then((response) => response.json());

  }

  postPPLink(tid, link) {

    return new NPAPI().fetch('tran', '/transactions/' + tid + '/docs/property', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ propertyProofLink: link })
    }).then((response) => response.json());

  }

  postIConfirmTx(tid) {

    return new NPAPI().fetch('tran', '/transactions/' + tid + '/iconfirmtx', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json());

  }

  getTransactions(filter) {

    if (!filter) filter = ""

    return new NPAPI().fetch('tran', '/admin/transactions?filter=' + filter).then((response) => response.json());

  }

  /**
   * Forces the document OCR to pass
   * @param {string} tid the transaction id
   * @param {string} docType the type of doc. one of (propertyproof, propertytransfer)
   * @returns 
   */
  adminForceOCR(tid, docType) {

    return new NPAPI().fetch('tran', '/admin/transactions/' + tid + '/docs/' + docType + '/forceOCR', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json());

  }

  /**
   * Pays out the transaction
   * @param {string} tid the transaction id
   * @returns 
   */
  adminPayout(tid) {

    return new NPAPI().fetch('tran', '/admin/transactions/' + tid + '/payout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json());

  }

  /**
   * Finds a transaction
   * @param {object} filter the filter object ({licensePlates: ...})
   * @returns 
   */
  adminSearchTransaction(filter) {

    const licensePlates = filter && filter.licensePlates ? filter.licensePlates : null;

    let queryString = "";
    if (licensePlates) queryString += `licensePlates=${licensePlates}&`;

    return new NPAPI().fetch('tran', `/admin/search/transactions?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json());

  }

}
