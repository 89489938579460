import React from 'react';
import { withTranslation } from 'react-i18next';
import './IncomingPaymentsCard.css';
import NPCard from './NPCard';
import PayAPI from '../api/PayAPI';
import moment from 'moment-timezone';

class IncomingPaymentsCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.loadPayments = this.loadPayments.bind(this);
    }

    componentDidMount() {
        this.loadPayments();
    }

    /**
     * Loads the incoming payments
     */
    loadPayments() {

        // Loads only the payments related to a specific Correlation ID
        if (this.props.cid) {

            new PayAPI().getPaymentsByCid(this.props.cid).then((data) => {

                if (data) this.setState({ payments: data.payments });
            })
        }

    }

    render() {

        const { t } = this.props;

        return (
            <NPCard>
                <div className="incoming-payments-card">
                    <div className="title font-xlarge">{t('card.payments.incoming.title')}</div>
                    <div className="subtitle">{t('card.payments.incoming.found.p1') + (this.state.payments ? this.state.payments.length : 0) + t('card.payments.incoming.found.p2')}</div>
                    {this.props.cid && <div className="opcode font-small">{t('card.payments.incoming.operation.code') + ': ' + this.props.cid}</div>}
                    <div className="list-container">
                        <div className="list-header">
                            <div className="header date">{t('card.payments.incoming.list.header.date')}</div>
                            <div className="header paymentcode">{t('card.payments.incoming.list.header.paymentcode')}</div>
                            <div className="header amount">{t('card.payments.incoming.list.header.amount')}</div>
                        </div>
                        <div className="list-body">
                            {this.state.payments && this.state.payments.map((item, index) => {
                                return (
                                    <div className="list-item-container" key={'ip-' + index}>
                                        <div className="line">
                                            <div className="date">{moment(item.date, 'YYYYMMDD').format('DD.MM.YYYY')}</div>
                                            <div className="paymentcode">{item.paymentCode}</div>
                                            <div className="amount">{item.currency} {item.amount.toLocaleString()}</div>
                                        </div>
                                        <div className="line">
                                            <div className="statement font-small">{item.statement}</div>
                                        </div>
                                        { (index < this.state.payments.length - 1) && <div className="separator"></div> }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(IncomingPaymentsCard);