import './layout.css'

/**
 * 
 * @param {string} size values allowed: "default", "large", "x-large", "xx-large"
 * @returns 
 */
export function HSpacer({size}) {

    return (
        <div className={`hspacer ${size}`}>
        </div>
    )
}