import { useState } from "react"
import { HSpacer } from "../../comp/layout/HSpacer"
import NPButton from "../../comp/NPButton"
import { NPCardMessage } from "../../comp/NPCardMessage"
import NPLabeledValue from "../../comp/NPLabeledValue"
import { NPValidationError } from "../../comp/NPValidationError"
import NPCard from "../NPCard"
import Popup from "reactjs-popup"
import MarketplaceAPI from "../../api/MarketplaceAPI"


export function MarketplaceSellerPaymentCard({ transaction, onSellerPaymentDone }) {

    const [showConfirm, setShowConfirm] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(null)

    // Cases where nothing should be shown
    if (transaction.sellerConfirmedPTOn == null) return <></>

    // If the payment to the Seller has been done already
    if (transaction.sellerPaidOn != null) return (
        <NPCard>
            <NPCardMessage icon="money" noBorder={true} text="Hai effettuato il Bonifico al Venditore." date={transaction.sellerPaidOn} instructions={<>Bonifico effettuato a questo IBAN: <b>{transaction.iban}</b></>} />
        </NPCard>
    )

    /**
     * Confirms the wire to the Seller
     */
    const postSellerWireDone = async () => {

        setShowConfirm(false);
        setUpdating(true)

        const result = await new MarketplaceAPI().postSellerWireDone(transaction.id)

        setUpdating(false)

        if (!result) {
            setError(`Si è verificato un errore. Verificare nei log di backend.`)
            return;
        }

        if (result.code == 400) {
            setError(`Si è verificato un errore: ${result.message}`)
            return;
        }

        if (result.updated == 0) {
            setError(`Per qualche motivo la transazione non è stata aggiornata. Verificare i log di backend.`)
            return;
        }

        if (onSellerPaymentDone) onSellerPaymentDone()
    }

    return (
        <NPCard>
            <NPCardMessage
                icon="money"
                noBorder={true}
                text="Informazioni per l'esecuzione del bonifico al Venditore"
            />
            {error &&
                <>
                    <HSpacer />
                    <NPValidationError errorMessage={error} />
                </>
            }
            {transaction.buyerConfirmedPTOn == null &&
                <>
                    <HSpacer />
                    <NPValidationError errorMessage="Attenzione! Il Compratore non ha ancora confermato di aver ricevuto il bene." />
                </>
            }
            <HSpacer />
            <NPLabeledValue label="IBAN Venditore" value={transaction.iban} copy={true} />

            <HSpacer />
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <NPButton label="Ho eseguito il Bonifico al Venditore" onClick={() => { setShowConfirm(true) }} disabled={updating} />
            </div>

            <Popup open={showConfirm} onClose={() => { setShowConfirm(false) }}>
                <NPCard highlightColor="complementary" highlight="top">
                    <div className="delete-profile-popup">
                        <div className="warn">
                            Conferma del Bonifico effettuato
                        </div>
                        {transaction.buyerConfirmedPTOn == null &&
                            <>
                                <NPValidationError errorMessage="Attenzione! Il Compratore non ha ancora confermato di aver ricevuto il bene." />
                                <HSpacer size="large" />
                            </>
                        }
                        <div>
                            L'IBAN del destinatario deve essere: <b>{transaction.iban}</b>.
                        </div>
                        <div style={{ marginTop: 12 }}></div>
                        <b>Sei sicuro</b> di aver effettuato il bonifico con questi dati?
                        <HSpacer size="large"/>
                        <div className="buttons-container">
                            <NPButton outline={true} filled={false} label="No" onClick={() => { setShowConfirm(false) }} />
                            <div style={{ flex: 1, display: "flex" }}></div>
                            <NPButton warning={true} outline={true} filled={false} label="Si" onClick={postSellerWireDone} />
                        </div>
                    </div>
                </NPCard>
            </Popup>
        </NPCard>
    )
}