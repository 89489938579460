import React from 'react';
import './ProfileMasterDataCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import NPIconButton from '../../comp/NPIconButton';

import { ReactComponent as EditSVG } from '../../icons/pencil.svg';

/**
 * Displays the master data of the profile of a user coming from an Agency:
 *  - First name, last name, sex, etc. 
 *  - Residence
 */
class ProfileMasterDataCardP4AUser extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { t } = this.props;

        return (
            <NPCard>
                <div className="master-data-card">
                    <div className="left">
                        <div className="card-title font-large">{t('card.profile.master.data.title')}</div>
                        <div className="body-container">
                            {this.props.profile.cf &&
                                <div className="column">
                                    <NPLabeledValue label="label.cf" value={this.props.profile.cf} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('cf')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('cf')} />
                                    <NPLabeledValue label="label.first.name" value={this.props.profile.firstName} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('firstName')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('firstName')} />
                                    <NPLabeledValue label="label.last.name" value={this.props.profile.lastName} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('lastName')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('lastName')} />
                                    <NPLabeledValue label="label.mail" value={`${this.props.profile.email}`} marginVertical="6px" />
                                    <NPLabeledValue label="label.userId" value={`${this.props.profile.userId}`} marginVertical="6px" />
                                </div>
                            }
                            {this.props.profile.piva &&
                                <div className="column">
                                    <NPLabeledValue label="label.piva" value={this.props.profile.piva} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('cf')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('cf')} />
                                    <NPLabeledValue label="label.legal.name" value={this.props.profile.legalName} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('firstName')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('firstName')} />
                                    <NPLabeledValue label="label.mail" value={`${this.props.profile.email}`} marginVertical="6px" />
                                    <NPLabeledValue label="label.userId" value={`${this.props.profile.userId}`} marginVertical="6px" />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="right large">
                        <div className="card-title font-large">{t('card.profile.residence.title')}</div>
                        {this.props.profile.cf &&
                            <div className="column">
                                <NPLabeledValue label="label.residence.address" value={this.props.profile.residenceAddress} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('residenceAddress')} />
                                <NPLabeledValue label="label.residence.city" value={this.props.profile.residenceCity} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('residenceCity')} />
                                <NPLabeledValue label="label.residence.cap" value={this.props.profile.residenceCAP} marginVertical="6px" />
                                <NPLabeledValue label="label.residence.province" value={this.props.profile.residenceProvince} marginVertical="6px" />
                                <NPLabeledValue label="label.residence.country" value={this.props.profile.residenceCountry ? this.props.profile.residenceCountry.toUpperCase() : "N.D."} marginVertical="6px" />
                            </div>
                        }
                        {this.props.profile.piva && 
                            <div className="column">
                                <NPLabeledValue label="Indirizzo Sede Legale" value={this.props.profile.regOfficeAddress} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('residenceAddress')} />
                                <NPLabeledValue label="label.residence.city" value={this.props.profile.regOfficeCity} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('residenceCity')} />
                                <NPLabeledValue label="label.residence.cap" value={this.props.profile.regOfficeCAP} marginVertical="6px" />
                                <NPLabeledValue label="label.residence.province" value={this.props.profile.regOfficeProvince} marginVertical="6px" />
                                <NPLabeledValue label="label.residence.country" value={this.props.profile.regOfficeCountry ? this.props.profile.regOfficeCountry.toUpperCase() : "N.D."} marginVertical="6px" />
                            </div>
                        }
                    </div>
                </div>
                <div className="card-edit-button">
                    {this.props.onEdit && <NPIconButton image={<EditSVG />} onClick={this.props.onEdit} size="small" />}
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(ProfileMasterDataCardP4AUser);