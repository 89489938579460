import './TransactionFeesCard.css'
import NPLabeledValue from "../../comp/NPLabeledValue";
import NPCard from "../NPCard";

export function TransactionFeesCard({ transaction, loading }) {

    let currency = "EUR"

    // Define the buyer fee
    let buyerFee = transaction.fees; // Standard case
    if (transaction.marketplaceId) {
        // Marketplaces differentiate the fees
        buyerFee = transaction.buyerFee;
    }

    // Define the seller fee
    let sellerFee = transaction.fees; // Standard case
    if (transaction.marketplaceId) {
        // Marketplaces differentiate the fees
        sellerFee = transaction.sellerFee;
    }

    // Agency fees
    let agencyFees = 0;
    if (transaction.agencyId) agencyFees = transaction.agencyFees ? parseFloat(transaction.agencyFees) : 0;

    return (
        <NPCard>
            <div className="transaction-fees-card">
                <NPLabeledValue label="Commissioni al Compratore" value={buyerFee} type="currency" currency={currency} textAlign="center" />
                <NPLabeledValue label="Commissioni al Venditore" value={sellerFee} type="currency" currency={currency} textAlign="center" />
                {transaction.agencyId && <NPLabeledValue label="Commissioni Agenzia" value={agencyFees} type="currency" currency={currency} textAlign="center" />}
            </div>
        </NPCard>
    )
}