import NPLabeledValue from '../../comp/NPLabeledValue'
import { ReactComponent as MarketplaceSVG } from '../../icons/marketplace.svg'
import NPCard from '../NPCard'
import './ProfileMarketplaceCard.css'

export function ProfileMarketplaceCard({ profile }) {

    // const [agency, setAgency] = useState()

    // const loadAgencyDetails = async () => {

    //     if (!profile && !profile.agencyId) return;

    //     const apiResponse = await new AuthAPI().getRegistrationDetails(profile.agencyId);

    //     setAgency(apiResponse);
    // }

    // useEffect(() => { loadAgencyDetails() }, [])

    return (
        <NPCard>
            <div className='marketplace-card-content'>
                <div className='header'>
                    <div className='icon'><MarketplaceSVG /></div>
                    <div className="title">Utente proveniente da Marketplace</div>
                </div>
                <div className='card-content row mobile-col'>
                    <div style={{ flex: 1 }}>
                        <NPLabeledValue marginVertical={6} label="Id Marketplace" value={profile.marketplaceId} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <NPLabeledValue marginVertical={6} label="Marketplace" value={profile.marketplaceId == 'AFTR' ? "Affare Trattore" : "Marketplace sconosciuto"} />
                    </div>
                </div>
            </div>
        </NPCard>
    )

}