import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { Component } from 'react';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import AdminPaymentsUploadScreen from './screens/AdminPaymentsUploadScreen';
import AdminProfileDetailScreen from './screens/AdminProfileDetailScreen';
import AdminProfiles from './screens/AdminProfiles';
import AdminScreen from './screens/AdminScreen';
import AdminTransactionDetailScreen from './screens/AdminTransactionDetailScreen';
import AdminTransactions from './screens/AdminTransactions';
import LoginScreen from './screens/LoginScreen';
import AdminRebatesScreen from './screens/AdminRebatesScreen';
import AdminNewRebateCodeScreen from './screens/AdminNewRebateCodeScreen';
import AdminMonkey from './screens/AdminMonkey';
import DashboardScreen from './screens/DashboardScreen';
import AdminUsersScreen from './screens/AdminUsersScreen';
import AdminAgencyTransactions from './screens/AdminAgencyTransactions';
import AdminAgencyTransactionDetail from './screens/AdminAgencyTransactionDetail';
import AdminMarketplaceTransactions from './screens/AdminMarketplaceTransactions';
import AdminMarketplaceTransactionDetail from './screens/AdminMarketplaceTransactionDetail';

const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}'.json,
    },
    defaultNS: 'translation',
    ns: ['translation'],
    lng: "it",
    fallbackLng: 'en',
  }, (err, t) => {
    if (err) return console.error(err)
  });

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gapiLoaded: false
    }

    this.loadGoogleAPI = this.loadGoogleAPI.bind(this);
    this.loadFBAPI = this.loadFBAPI.bind(this);

  }

  componentDidMount() {
    this.loadGoogleAPI();
    // this.loadFBAPI();
    this.refreshPushListener();
  }

  /**
   * This function refreshes the push listener, making sure that it hasn't unsubscribed to the channels. 
   */
  refreshPushListener() {

  }

  /**
   * Loads Facebook API
   */
  loadFBAPI() {

    console.log("Loading FB API");

    window.fbAsyncInit = () => {

      console.log("FB API Loaded!");

      window.FB.init({
        appId: process.env.REACT_APP_FB_CLIENT_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0'
      });

      console.log("FB API Initialized!");

      this.setState({ fbapiLoaded: true });

    };

    // load facebook sdk script
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  /**
   * Loads Google API to support later on user authentication  
   */
  loadGoogleAPI() {

    console.log("Loading Google API");

    if (window.gapi) {

      console.log("Google API loaded!");

      window.gapi.load('auth2', () => {

        window.gapi.auth2.init({ client_id: googleClientID }).then((auth) => {

          this.setState({
            gapiLoaded: true
          })

          console.log("Google Auth initiatilzed!");

        });
      });
    }
    else {
      console.log("Google API not loaded... waiting..");
      setTimeout(this.loadGoogleAPI, 50);
    }
  }

  render() {

    if (!this.state.gapiLoaded) return (<div>Loading!!!</div>)

    return (
      <div className="App font-normal">
        <Router>
          <Switch>
            <Route exact path="/">
              <LoginScreen />
            </Route>
            <Route exact path="/login">
            </Route>
            <Route exact path="/admin">
              <AdminScreen />
            </Route>
            <Route exact path="/dashboard">
              <DashboardScreen />
            </Route>
            <Route exact path="/admin/pup">
              <AdminPaymentsUploadScreen />
            </Route>
            <Route exact path="/admin/users">
              <AdminUsersScreen />
            </Route>
            <Route exact path="/admin/profiles">
              <AdminProfiles />
            </Route>
            <Route exact path="/admin/profiles/detail">
              <AdminProfileDetailScreen />
            </Route>
            <Route exact path="/admin/rebates">
              <AdminRebatesScreen />
            </Route>
            <Route exact path="/admin/monkey">
              <AdminMonkey />
            </Route>
            <Route exact path="/admin/rebates/new">
              <AdminNewRebateCodeScreen />
            </Route>
            <Route exact path="/admin/transactions">
              <AdminTransactions />
            </Route>
            <Route exact path="/admin/transactions/detail">
              <AdminTransactionDetailScreen />
            </Route>
            <Route exact path="/admin/agencyTransactions">
              <AdminAgencyTransactions />
            </Route>
            <Route exact path="/admin/agencyTransactions/detail">
              <AdminAgencyTransactionDetail />
            </Route>
            <Route exact path="/admin/mktTransactions">
              <AdminMarketplaceTransactions />
            </Route>
            <Route exact path="/admin/mktTransactions/detail">
              <AdminMarketplaceTransactionDetail />
            </Route>
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;