import NPAPI from './NPAPI';

export default class MonkeyAPI {

  /**
   * Retrieves the logs of the transaction
   * @param {string} tid transaction id
   * @returns the transaction logs
   */
  getRuns() {

    return new NPAPI().fetch('monkey', `/runs`).then((response) => response.json());

  }

  /**
   * Retrieves a specific run's data
   * @param {string} runId mongo id of the run
   */
  getRun(runId) {

    return new NPAPI().fetch('monkey', `/runs/${runId}`).then((response) => response.json());

  }

  getScenarios() {
    return new NPAPI().fetch('monkey', `/scenarios`).then((response) => response.json());
  }

  /**
   * Starts a run with a specific scenario
   * @param {string} scenarioId the id of the scenario (e.g. standard)
   * @returns 
   */
  postRun(scenarioId) {

    return new NPAPI().fetch('monkey', '/runs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        runType: scenarioId
      })
    }).then((response) => response.json());
  }

  /**
   * Cancels a run
   * @param {string} rid the id of the run to cancel
   * @returns 
   */
  cancelRun(rid) {

    return new NPAPI().fetch('monkey', `/runs/${rid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json());
  }

}
