import React from 'react';
import { withTranslation } from 'react-i18next';
import './NewTransactionCard.css';
import NPCard from './NPCard';
import newTxSVG from '../images/card-new-tx-illustration.svg';
import ProfileAPI from '../api/ProfileAPI';
import NPPopup from '../comp/popup/NPPopup';
import popupSorry from '../images/popup-sorry.svg';
import { ReactComponent as CloseSVG } from '../icons/close.svg';
import { withRouter } from 'react-router';

/**
 * Parameters
 * 
 *  - format            :   (OPT default none) Controls the format of this card
 *                          Pass "reduced" to show only a simplified title and no desc
 */
class NewTransactionCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            popupPosition: { top: 0, left: 0 }
        };

        this.startTransaction = this.startTransaction.bind(this);
    }

    componentDidMount() {

        // Define the position of the popup
        let cardHeight = this.card.clientHeight;
        let cardWidth = this.card.clientWidth;

        this.setState({
            popupPosition: {
                top: cardHeight / 2,
                left: cardWidth / 2
            }
        })

    }

    /**
     * Starts a new transaction! 
     * But first, verifies if it is even possible :P 
     */
    startTransaction() {

        // Verify if the profile is complete
        new ProfileAPI().getProfileCompletion().then((completion) => {

            if (!completion || !completion.completed) {

                // Show a warning popup
                this.setState({ showErrorPopup: true });

                return;
            }

            // Start a new transaction!
            this.props.history.push('/tx/new')
        })

    }

    render() {

        const { t } = this.props;

        // Error popup
        let popup;
        if (this.state.showErrorPopup) popup = (
            <NPPopup position={this.state.popupPosition} horizontalPlacement='centered' width="70%">
                <div className="sorry-popup-content">
                    <div className="header light-background">
                        <div className="font-righteous font-xxlarge sorry">{t('card.new.transaction.error.popup.title')}</div>
                        <div style={{ display: 'flex', flex: 1 }}></div>
                        <div className="close-button-container fill-accent dark-background" onClick={() => { this.setState({ showErrorPopup: false }) }}>
                            <CloseSVG />
                        </div>
                    </div>
                    <div className="body light-background">
                        <div className="text-container">
                            <div className="font-normal">
                                {t('card.new.transaction.error.popup.text')}
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={popupSorry} width="100%"></img>
                        </div>
                    </div>
                </div>
            </NPPopup>
        )

        // Let title
        let title = <div className="new-tx-text font-xlarge">{t('card.new.transaction.title')}</div>
        if (this.props.format === 'reduced') title = <div className="font-normal bold">{t('card.new.transaction.title.reduced')}</div>

        // Description
        let desc;
        if (this.props.format != 'reduced') desc = (
            <div className="font-normal bold">
                {t('card.new.transaction.text.part1')} <br />
                {t('card.new.transaction.text.part2')}
            </div>
        )

        return (
            <div className="new-transaction-card">
                {popup}
                <NPCard type="action" onClick={this.startTransaction} padding={this.props.format}>
                    <div className="card-content" ref={(el) => { this.card = el; }}>
                        <div className="image">
                            <img src={newTxSVG} width="100%" />
                        </div>
                        <div className="text">
                            {title}
                            {desc}
                        </div>
                    </div>
                </NPCard>
            </div>
        )
    }
}

export default withRouter(withTranslation()(NewTransactionCard));