import React from 'react';
import './ProfileIdentityDocCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPPhoto from '../../comp/NPPhoto';
import FileAPI from '../../api/FileAPI';

/**
 * Displays the identity document data of a profile:
 * 
 * Parameters: 
 * 
 *  - showOCRData                   :   (OPT, default false) - Boolean - Shows the link to see OCR data
 *  - ownProfile                    :   (OPT, default false) - Boolean - The profile is the connected user's profile - This will load the user's own picture
 * 
 */
class ProfileVisuraDocCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.loadPictures = this.loadPictures.bind(this);
    }


    componentDidMount() {
        this.loadPictures();
    }

    loadPictures() {

        if (!this.props.ownProfile) {

            new FileAPI().adminDownloadVisuraPhotoStream(this.props.profile.userId).then((imgSrc) => {
                this.setState({ visuraPhoto: imgSrc });
            })

        }
        else {

            new FileAPI().downloadVisuraStream().then((imgSrc) => {
                this.setState({ idDocFront: imgSrc });
            })

        }
    }

    render() {

        const { t } = this.props;

        return (
            <NPCard>
                <div className="identity-doc-card">
                    <div className="card-title font-large">{t('card.profile.visura.title')}</div>
                    <div className="card-content">
                        <div className="column">
                            <NPPhoto photo={this.state.visuraPhoto} />
                        </div>
                        <div className="column"></div>
                        <div className="column"></div>
                    </div>
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(ProfileVisuraDocCard);