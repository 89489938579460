import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminMonkey.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import monkeySVG from '../images/monkey-dark.svg';
import { ReactComponent as MonkeySVG } from '../images/monkey-dark.svg';
import { ReactComponent as CopySVG } from '../icons/copy.svg';
import { ReactComponent as TimerSVG } from '../icons/timer.svg';
import { ReactComponent as TickSVG } from '../icons/tick.svg';
import { ReactComponent as ExclamationSVG } from '../icons/exclamation-mark.svg';
import { ReactComponent as RunnerSVG } from '../icons/runner.svg';
import { ReactComponent as StopSVG } from '../icons/stop.svg';
import '../comp/popup/NPErrorPopup.css';
import MonkeyAPI from '../api/MonkeyAPI';
import NPCard from '../cards/NPCard';
import moment from 'moment-timezone';
import NPButton from '../comp/NPButton';
import Popup from 'reactjs-popup';
import NPSelect from '../forms/NPSelect';

const cookies = new Cookies();

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.loadRuns = this.loadRuns.bind(this);
        this.loadScenarios = this.loadScenarios.bind(this);
        this.startRun = this.startRun.bind(this)
        this.startPollingRuns = this.startPollingRuns.bind(this)
        this.onStartRun = this.onStartRun.bind(this)

    }

    componentDidMount() {
        this.loadRuns();
        this.loadScenarios();
        this.startPollingRuns();
    }

    componentWillUnmount() {

        if (this.runTimer) clearInterval(this.runTimer);
    }

    startPollingRuns() {

        this.runTimer = setInterval(this.loadRuns, 500);
    }

    /**
     * When the user clicks on "start run"
     */
    onStartRun() {

        this.setState({
            showStartRunPopup: true
        })

    }

    /**
     * Gets all the scenarios available for testing
     */
    async loadScenarios() {

        const scenarios = await new MonkeyAPI().getScenarios();

        const scenarioOptions = scenarios.map((scenario) => {
            return {
                value: scenario.id,
                label: scenario.desc
            }
        })

        this.setState({ scenarios, scenarioOptions })
    }

    /**
     * Loads all the Monkey Runs
     */
    async loadRuns() {

        const runs = await new MonkeyAPI().getRuns();

        this.setState({ runs })
    }

    /**
     * Starts a run with a specific scenario
     */
    async startRun() {

        this.setState({ showStartRunPopup: false })

        new MonkeyAPI().postRun(this.state.selectedScenario);
    }

    renderRuns() {

        return (
            <NPCard>
                <div className="header">
                    <div className="title">Monkey Tests</div>
                    <div className="actions">
                        <NPButton label="Run, Monkey!" filled={false} outlineLight={true} size="small" onClick={this.onStartRun} />
                    </div>
                </div>
                <div className="list">
                    {this.state.runs && this.state.runs.map((run, i) => {
                        return (
                            <Run run={run} last={i == this.state.runs.length - 1}></Run>
                        )
                    })}
                </div>
            </NPCard>
        )
    }

    render() {

        if (!this.state.scenarioOptions) return (<div></div>);

        const { t } = this.props;

        const runs = this.renderRuns(this.state.runs);

        return (
            <div className="admin-monkey-screen">
                {runs}

                <Popup open={this.state.showStartRunPopup} onClose={() => { this.setState({ showStartRunPopup: false }) }}>
                    <NPCard>
                        <div className="new-monkey-run-popup">
                            <div className="header">
                                <div className="monkey-container"><MonkeySVG /></div>
                                <div className="title">You're about to make the Money run ... </div>
                            </div>
                            <NPSelect label={t('form.label.select.monkey.scenario')} options={this.state.scenarioOptions} onChange={(value) => { this.setState({ selectedScenario: value }) }} defaultValue={this.state.scenarioOptions[0]} />
                            <div className="buttons-container">
                                <NPButton label="Run!" onClick={this.startRun} />
                            </div>
                        </div>
                    </NPCard>
                </Popup>
            </div>
        )

    }
}

class Run extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDetail: false
        }

        this.renderTasksContainer = this.renderTasksContainer.bind(this);
        this.loadRunDetail = this.loadRunDetail.bind(this);
        this.toggleRunDetail = this.toggleRunDetail.bind(this);
        this.cancelRun = this.cancelRun.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);

    }

    componentWillUnmount() {
        if (this.taskTimer) clearInterval(this.taskTimer);
    }

    toggleRunDetail() {

        this.setState((prevState) => {
            return {
                showDetail: !prevState.showDetail
            }
        })

    }

    async cancelRun(event) {

        event.stopPropagation();

        await new MonkeyAPI().cancelRun(this.props.run._id);
    }

    async onRowClicked() {

        console.log(`Show detail: ${this.state.showDetail} - Run status: ${this.props.run.status}`);

        // Start polling, if I opened the detail and the run is still running
        if (this.state.showDetail === false && !this.props.run.status) {

            console.log("starting interval");

            this.taskTimer = setInterval(this.loadRunDetail, 500);

        }
        else if (this.state.showDetail === true) {

            if (this.taskTimer) clearInterval(this.taskTimer);

        }
        else await this.loadRunDetail();

        this.toggleRunDetail();

    }

    /**
     * Loads all the Monkey Runs
     */
    async loadRunDetail() {

        const run = await new MonkeyAPI().getRun(this.props.run._id);

        this.setState({ tasks: run.tasks })
    }

    renderTasksContainer() {

        if (!this.state.tasks || !this.state.showDetail) return;

        return (
            <div className="tasks-container">
                {this.state.tasks.map((task, i) => {
                    return (
                        <Task task={task} last={i == this.state.tasks.length - 1}></Task>
                    )
                })}
            </div>
        )
    }

    render() {

        const { run } = this.props;

        let tickOrWarn = run.status == "failed" ? <ExclamationSVG width="100%" height="100%" /> : <TickSVG width="100%" height="100%" />
        if (run.status == null) tickOrWarn = <RunnerSVG width="100%" height="100%" />

        const taskContainer = this.renderTasksContainer();

        let duration = null;
        if (run.finishedAt) {

            const startTime = moment(`${run.date} ${run.time}`, "YYYYMMDD HH:mm:ss,SSS");
            const endTime = moment(`${run.finishedOn} ${run.finishedAt}`, "YYYYMMDD HH:mm:ss,SSS");

            const dur = moment.duration(endTime.diff(startTime));

            duration = dur.as("seconds");
        }

        return (
            <div className={`run run-${this.props.last ? "last" : "std"}`} key={run._id} onClick={this.onRowClicked}>
                <div className="line">
                    <div className="result-container">
                        <div className={`result-image result-${run.status}`}>
                            {tickOrWarn}
                        </div>
                    </div>
                    <div className="title-container">
                        <div className="title">{run.runType}</div>
                        <div className="time">{`${moment(run.date, "YYYYMMDD").format("YYYY.MM.DD")} ${run.time}`}</div>
                    </div>
                    {duration &&
                        <div className="timer">
                            <div className="image-container"><TimerSVG width="100%" height="100%" /></div>
                            <div className="time">{duration}s</div>
                        </div>
                    }
                    {!duration && 
                        <div className="image-container complementary" onClick={this.cancelRun}><StopSVG width="100%" height="100%" /></div>
                    }
                    <div className="id-container">
                        <div className="image-container"><CopySVG width="100%" height="100%" /></div>
                        <div className="id">{run._id}</div>
                    </div>
                </div>
                {taskContainer}
            </div>
        )
    }
}

class Task extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showChecks: false
        }

        this.renderChecksContainer = this.renderChecksContainer.bind(this);
        this.toggleChecks = this.toggleChecks.bind(this);

    }

    toggleChecks(event) {

        event.stopPropagation();

        this.setState((prevState) => {
            return {
                showChecks: !prevState.showChecks
            }
        })
    }

    renderChecksContainer() {

        const { task } = this.props

        if (!task.checks || !this.state.showChecks) return;

        return (
            <div className="checks-container">
                {task.checks.map((check, i) => {
                    return (
                        <Check check={check} last={i == task.checks.length - 1} />
                    )
                })}
            </div>
        )
    }

    render() {

        const { task } = this.props;

        let tickOrWarn = task.status == "failed" ? <ExclamationSVG width="100%" height="100%" /> : <TickSVG width="100%" height="100%" />
        if (!task.status) tickOrWarn = <RunnerSVG width="100%" height="100%" />

        const checksContainer = this.renderChecksContainer();

        let duration = null;
        if (task.finishedAt) {

            const startTime = moment(`${task.startedOn} ${task.startedAt}`, "YYYYMMDD HH:mm:ss,SSS");
            const endTime = moment(`${task.finishedOn} ${task.finishedAt}`, "YYYYMMDD HH:mm:ss,SSS");

            const dur = moment.duration(endTime.diff(startTime));

            duration = dur.as("milliseconds");
        }

        return (
            <div className={`task task-${this.props.last ? "last" : "std"}`} key={task.taskId} onClick={this.toggleChecks}>
                <div className="line">
                    <div className="result-container">
                        <div className={`result-image result-${task.status}`}>
                            {tickOrWarn}
                        </div>
                    </div>
                    <div className="title-container">
                        <div className="title">{task.taskId}</div>
                    </div>
                    {duration &&
                        <div className="timer">
                            <div className="image-container"><TimerSVG width="100%" height="100%" /></div>
                            <div className="time">{duration}ms</div>
                        </div>
                    }
                </div>
                {checksContainer}
            </div>
        )
    }
}

class Check extends Component {

    render() {

        const { check } = this.props;

        const tickOrWarn = check.passed ? <TickSVG width="100%" height="100%" /> : <ExclamationSVG width="100%" height="100%" />

        return (
            <div className={`check check-${this.props.last ? "last" : "std"}`} key={Math.random()}>
                <div className="line">
                    <div className="result-container">
                        <div className={`result-image result-${check.passed ? "succeeded" : "failed"}`}>
                            {tickOrWarn}
                        </div>
                    </div>
                    <div className="title-container">
                        <div className="title">{check.name}</div>
                        <div className="msg">{check.msg}</div>
                    </div>
                </div>
            </div>
        )
    }
}

class Title extends Component {

    render() {

        const { t } = this.props;

        return (
            <div className="admin-monkey-title">
                <div className="image-container"><img src={monkeySVG} width="100%" /></div>
                <div className="title-container">{t('admin.monkey.title')}</div>
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(Content)),
        "wide"
    )
);