import React from 'react';
import './NPCoolRadioMulti.css';

/**
 * Displays multiple choices picker
 * 
 * Params: 
 * 
 *  - options               : (MAND) An array of strings (the different choices)
 *  - label                 : (OPT) The label to be displayed 
 *  - notes                 : (OPT) An array of notes (string). The notes should be ordered like the options. When an option is selected, the corresponding note will be shown.
 */
export default class NPCoolRadioMulti extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.options = [];

        this.selectOption = this.selectOption.bind(this);
    }

    componentDidMount() {
        
        // Preselect an option
        this.selectOption(this.props.prefilled ? this.props.prefilled : 0);

        // Set the size of the floating button
        this.floatingButton.style.width = this.options[0].clientWidth + 'px';
    }

    /**
     * Selects option x
     * @param {int} option the selected option number
     */
    selectOption(option) {

        // Change the selection
        this.setState({
            selectedOption: option
        })

        // Move the floating button
        let left; 
        left = this.options[option].offsetLeft;
            
        this.floatingButton.style.left = left + 'px';

        // Update caller if any
        if (this.props.onChange) this.props.onChange(option);

    }

    render() {
        return (
            <div className="np-cool-radio-multi">
                <div className="np-input-label">
                    {this.props.label}
                </div>
                <div className="radio-container">
                    {
                        this.props.options.map((option, i) => {
                            return (
                                <div key={"option" + i} className="option-label" ref={(el) => { this.options.push(el) }} onClick={() => { this.selectOption(i) }}>{option}</div>
                            )
                        })
                    }
                    <div className="floating-button fill-accent focused-easeInOut-motion" ref={(el) => { this.floatingButton = el; }}></div>
                </div>
                <div className="notes-container">
                    {this.props.notes && (
                        <div className="note font-small">{this.props.notes[this.state.selectedOption]}</div>
                    )}
                </div>
            </div>
        )
    }
}