import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminTransactions.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import newTxSVG from '../images/card-new-tx-illustration.svg';
import TransactionsListCard from '../cards/TransactionsListCard';
import NPActionCard from '../cards/NPActionCard';

const cookies = new Cookies();

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

    }

    componentDidMount() {
    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-transactions-screen">

                <TransactionsListCard />

            </div>
        )

    }
}

class Title extends Component {

    render() {

        const { t } = this.props;

        return (
            <div className="admin-transactions-title">
                <div className="image-container"><img src={newTxSVG} width="100%" /></div>
                <div className="title-container">{t('admin.transactions.title')}</div>
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(Content)), 
        "wide"
    )
);