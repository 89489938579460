import React from 'react';
import NPCard from './NPCard';
import './NPActionCard.css';

/**
 * Parameters
 */
export default class NPActionCard extends React.Component {

    render() {

        let imageContainerStyles = {
            flexBasis: this.props.imageFlexBasis
        }

        let type = "action";
        if (this.props.type == 'danger-action') type = "strong-warning";

        return (
            <NPCard type={type} onClick={this.props.onClick}>
                <div className={`np-action-card`}>
                    <div className="image-container" style={imageContainerStyles}><img src={this.props.img} width="100%" /></div>
                    <div className="text-container">
                        <div className="title bold">{this.props.title}</div>
                        <div className="font-normal bold">{this.props.text}</div>
                    </div>
                </div>
            </NPCard>
        )
    }
}