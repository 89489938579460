import React from 'react';
import './TransactionPaymentsCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import { ReactComponent as TickSVG } from '../../icons/tick-circle.svg';
import { ReactComponent as WarnSVG } from '../../icons/exclamation-circle.svg';
import NPButton from '../../comp/NPButton';
import PayAPI from '../../api/PayAPI';
import TranAPI from '../../api/TranAPI';
import FeesAPI from '../../api/FeesAPI';
import moment from 'moment-timezone'

/**
 * Displays the key data of the transaction
 */
class TransactionPaymentsCard extends React.Component {

    constructor(props) {
        super(props);

        this.simulatePayment = this.simulatePayment.bind(this);
        this.onRecipientPaid = this.onRecipientPaid.bind(this);
    }

    simulatePayment() {

        let transactionId = this.props.transaction.id;
        if (this.props.transaction.partyType == 'seller') transactionId = this.props.transaction.match;

        let txCreatedOn = moment(this.props.transaction.creationTime, "YYYYMMDDHHmmss").format('YYYYMMDD')

        new FeesAPI().getBuyerSCTAmount(this.props.transaction.price, txCreatedOn).then((sct) => {

            let transferAmount = sct.amount;

            new PayAPI().simulatePayment(transferAmount, this.props.transaction.pcode, this.props.transaction).then(() => {

                if (this.props.onBuyerPaymentSimulated) this.props.onBuyerPaymentSimulated();

            })
        })

    }

    onRecipientPaid() {

        let transactionId = this.props.transaction.id;

        new TranAPI().adminPayout(transactionId).then(() => {

            setTimeout(() => {
                this.props.onPayoutDone()
            }, 300);
        });
    }

    render() {

        const { t } = this.props;

        if (!this.props.transaction) return <div></div>

        let buyerPaymentImg, buyerPaymentText;
        let recipientPaymentImg, recipientPaymentText, recipientPaymentButtonLabel;

        let buyerPaymentAmount;
        let recipientPaymentAmount;

        if (this.props.transaction.buyerPaid) {
            buyerPaymentImg = <div className="accent"><TickSVG /></div>;
            buyerPaymentText = t('card.tx.payments.buyer.paid');
        }
        else {
            buyerPaymentImg = <div className="complementary"><WarnSVG /></div>;
            buyerPaymentText = t('card.tx.payments.buyer.not.paid');
        }

        if (this.props.transaction.stateCode == 'completed') {
            if (this.props.transaction.sellerPaid) {
                recipientPaymentText = t('card.tx.payments.seller.paid')
                recipientPaymentImg = <div className="accent"><TickSVG /></div>;
            }
            else {
                recipientPaymentImg = <div className="complementary"><WarnSVG /></div>;
                recipientPaymentText = t('card.tx.payments.seller.not.paid')
                recipientPaymentButtonLabel = t('button.label.pay.seller');
            }
        }
        else if (this.props.transaction.stateCode == 'cancelled') {
            if (this.props.transaction.buyerPaidBack) {
                recipientPaymentText = t('card.tx.payments.buyer.paid.back')
                recipientPaymentImg = <div className="accent"><TickSVG /></div>;
            }
            else {
                recipientPaymentImg = <div className="complementary"><WarnSVG /></div>;
                recipientPaymentText = t('card.tx.payments.buyer.not.paid.back')
                recipientPaymentButtonLabel = t('button.label.pay.back.buyer');
            }
        }

        return (
            <NPCard>
                <div className="tx-payments-card">

                    <div className="tx-payment-box">
                        <div className="tx-payment-text-container">
                            <div className="image-container">{buyerPaymentImg}</div>
                            <div className="texts-container">
                                <div className="text-container">{buyerPaymentText}</div>
                                <div className="text-container">{buyerPaymentAmount}</div>
                            </div>
                        </div>
                        {!this.props.transaction.buyerPaid && this.props.transaction.match && <div className="button-container"><NPButton label={t('button.label.simulate.payment')} outline={true} filled={false} onClick={this.simulatePayment} /></div>}
                    </div>
                    <div className="tx-payment-box">
                        <div className="tx-payment-text-container">
                            <div className="image-container">{recipientPaymentImg}</div>
                            <div className="texts-container">
                                <div className="text-container">{recipientPaymentText}</div>
                                <div className="text-container">{recipientPaymentAmount}</div>
                            </div>
                        </div>
                        {(!this.props.transaction.sellerPaid && !this.props.transaction.buyerPaidBack) && this.props.transaction.disputeResolutionCode != 'rejected' && this.props.transaction.completed && <div className="button-container"><NPButton label={recipientPaymentButtonLabel} onClick={this.onRecipientPaid} /></div>}
                    </div>

                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(TransactionPaymentsCard);