import './ProfilesListCard.css';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { saveAs } from 'file-saver';
import { NPCardOutsideHeader } from './NPCardOutsideHeader';

import NPCard from './NPCard';
import NPList from '../comp/list/NPList';
import NPIconButton from '../comp/NPIconButton';
import ProfileAPI from '../api/ProfileAPI';

import { ReactComponent as CertifiedSVG } from '../icons/certified-circle.svg';
import { ReactComponent as RequiresActionSVG } from '../icons/exclamation-circle.svg';
import { ReactComponent as DownloadSVG } from '../icons/download.svg';
import { ReactComponent as AdminCreatedSVG } from '../icons/admin-created.svg';
import { ReactComponent as AgencySVG } from '../icons/agency.svg';
import { ReactComponent as MarketplaceSVG } from '../icons/marketplace.svg';


const FILTERS = {
    all: { code: "all", desc: "Tutti i profili" },
    verified: { code: "verified", desc: "Verificati" },
    needVerification: { code: "needVerification", desc: "Da verificare" },
    incomplete: { code: "incomplete", desc: "Non completati" }
}

export default function ProfilesListCard() {

    const [filter, setFilter] = useState(Object.keys(FILTERS)[0])
    const [profiles, setProfiles] = useState()
    const [stateFilters, setStateFilters] = useState([])

    const history = useHistory()

    /**
     * Initializes the component by loading profiles and counts
     */
    const init = () => {
        loadProfiles(filter);
        countProfiles();
    }

    /**
     * Counts the profiles in each category
     */
    const countProfiles = async () => {

        const profileAPI = new ProfileAPI();

        const filters = await Promise.all(Object.keys(FILTERS).map(async (filterKey) => {

            const filterName = FILTERS[filterKey].code

            const data = await profileAPI.countProfiles(filterName)

            return {
                code: filterName,
                count: data.count,
                label: FILTERS[filterName].desc
            }

        }))

        setStateFilters(filters);

    }

    /**
     * Loads the incoming payments
     */
    const loadProfiles = async (filterName) => {

        const data = await new ProfileAPI().getProfiles(filterName);

        setProfiles(data.profiles);
        setFilter(filterName)

    }

    /**
     * Converter for the NPList
     */
    const listDataConverter = (item) => {

        // Define the icon and tooltip that has to be shown for the item
        let icon = null, tooltip = null, tooltipType = null, iconName = null;

        if (item.profileVerified) {
            icon = <CertifiedSVG />;
            iconName = "Certified";
            tooltip = "Il profile è stato verificato";
            tooltipType = 'ok';
        }
        else if (item.userComplete) {
            icon = <RequiresActionSVG />;
            iconName = "RequiresAction";
            tooltip = "Il profilo richiede una verifica manuale";
            tooltipType = 'warning';
        }

        // If the profile was created by an Admin, add an "admin-created" flag
        let adminCreatedIcon = null;
        if (item.createdBy != null) {
            adminCreatedIcon = {
                value: <AdminCreatedSVG />,
                iconName: "AdminCreated",
                tooltip: `Created by ${item.createdBy}`,
                tooltipType: "ok"
            }
        }

        // If the profile is bound to an agency or marketplace
        let agencyIcon = null;
        let mktIcon = null;
        if (item.agencyId != null) agencyIcon = { value: <AgencySVG />, iconName: "Agency", tooltip: "Utente proveniente da un'Agenzia", fill: "var(--color-accent-mkt)" }
        if (item.marketplaceId != null) mktIcon = { value: <MarketplaceSVG />, iconName: "Marketplace", tooltip: `Utente proveniente da Marketplace ${item.marketplaceId}`, fill: "var(--color-accent-mkt)" }

        // Define the list of icons to visualize
        let icons = []
        if (icon) icons.push({ value: icon, iconName: iconName, tooltip: tooltip, tooltipType: tooltipType });
        if (adminCreatedIcon) icons.push(adminCreatedIcon);
        if (agencyIcon) icons.push(agencyIcon);
        if (mktIcon) icons.push(mktIcon);

        return {
            values: [
                { value: item.userType == 'company' ? item.piva : item.cf },
                { value: item.userType == 'company' ? item.legalName : (item.firstName + ' ' + item.lastName) },
                { value: item.creationDate },
                icons
            ]
        }

    }

    /**
     * Filters based on the status
     * @param {string} value name of the icon (as provided as iconName)
     */
    const onFilterStatus = (value) => {

        let filter = {};
        if (value == 'Certified') filter = { profileVerified: true };
        else if (value == 'RequiresAction') filter = { profileVerified: false, userComplete: true };

        loadProfiles(filter);

    }

    const onProfileClick = (item) => {

        history.push('/admin/profiles/detail', { profile: item });
    }

    const createAndDownloadFile = () => {

        if (!profiles) return;

        // Transform the profiles to only get the needed data
        // Transform to a csv string
        const csvProfilesList = profiles.map((profile) => {

            if (profile.userType == "company") return `${profile.legalName},${profile.email},${profile.creationDate},${profile.userComplete},${profile.profileVerified}`;

            return `${profile.firstName},${profile.email},${profile.creationDate},${profile.userComplete},${profile.profileVerified}`
        })

        // Append all the profiles to a file
        let fileContent = "Nome o Ragione Sociale,Email,Profilo creato il,Completato,Verificato\n";
        for (let i = 0; i < csvProfilesList.length; i++) {
            fileContent += csvProfilesList[i] + "\n"
        }

        // Convert the file content to a Blob
        const blob = new Blob([fileContent], { type: 'text/plain' });

        // Generate a unique URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Use file-saver library to trigger the download
        saveAs(blob, 'profiles.csv');

        // Clean up by revoking the URL object to release resources
        URL.revokeObjectURL(blobUrl);
    }

    let listStyle = { marginTop: 0 };

    useEffect(init, [])

    return (
        <div>
            <NPCardOutsideHeader
                title="Profili Pleggit"
                actions={[
                    <NPIconButton image={<DownloadSVG />} onClick={createAndDownloadFile} tooltip="Scarica i Profili" />
                ]}
                filters={[
                    { label: "Stato", filters: stateFilters }
                ]}
                selectedFilters={[
                    filter
                ]}
                onFilterSelected={(filterKey) => { loadProfiles(filterKey) }}
            />
            <NPCard>
                <div className="profiles-list-card">

                    <NPList
                        style={listStyle}
                        data={profiles}
                        dataConverter={listDataConverter}
                        types={['string', 'string', 'date', 'icons']}
                        elementsSizing={["30%", null, "20%", "15%"]}
                        mobileVisible={[false, true, true, true]}
                        headers={["Codice Fiscale / P.Iva", "Nome / Ragione Sociale", "Creato il", "Info"]}
                        onItemClick={onProfileClick}
                    />

                </div>
            </NPCard>
        </div>
    )
}
