import NPAPI from './NPAPI';

const labelMap = {
  firstName: "label.first.name",
  lastName: "label.last.name",
  cf: "label.cf",
  birthDate: "label.birth.date",
  birthCity: "label.birth.city",
  birthCountry: "label.birth.country",
  residenceAddress: "label.residence.address",
  residenceCity: "label.residence.city",
  docType: "label.doc.type",
  docNum: "label.doc.num",
  docExpiryDate: "label.doc.expiry.date"
}

export default class ProfileAPI {

  getLabel(fieldName) {
    return labelMap[fieldName];
  }

  /**
   * Check user auth
   */
  getProfileCompletion() {

    return new NPAPI().fetch('profile', '/completion').then((response) => response.json());

  }

  countProfiles(filterName) {
    return new NPAPI().fetch('profile', `/count/profiles?filter=${filterName}`).then((response) => response.json());
  }

  getProfiles(filterName) {

    return new NPAPI().fetch('profile', `/profiles?filter=${filterName}`).then((response) => response.json());
  }

  getProfileById(profileId) {

    return new NPAPI().fetch('profile', '/profiles/' + profileId).then((response) => response.json());
  }

  deleteProfile(id) {

    return new NPAPI().fetch('profile', `/admin/profiles/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json())

  }

  getProfileByUserId(userId) {

    return new NPAPI().fetch('profile', '/users/' + userId + '/profile').then((response) => response.json());
  }

  getOCRMandatoryFields() {

    return new NPAPI().fetch('profile', '/ocr/mandatory').then((response) => response.json());
  }

  getOCRData() {

    return new NPAPI().fetch('profile', '/profile/ocrData').then((response) => response.json());
  }

  getOCRDataByUserId(userId) {

    return new NPAPI().fetch('profile', '/users/' + userId + '/profile/ocrData').then((response) => response.json());
  }

  getOCRPartialMatchesByUserId(value) {

    return new NPAPI().fetch('profile', '/profile/ocrData/partial?value=' + value).then((response) => response.json());
  }

  postOCRCorrections(corrections) {

    return new NPAPI().fetch('profile', '/profile/ocrData/corrections', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(corrections)
    }).then((response) => response.json())

  }


  /**
   * Updates the profile with the provided data
   * 
   * @param {object} data the data to update the profile with. 
   * Fields have to follow this strict convention: 
   *  - cf                : codice fiscale
   *  - firstName         : first name
   *  - lastName          : last name
   *  - residenceCity     : city of residence
   *  - residenceAddress  : address of residence
   *  - birthDate         : date of birth, as a moment() object
   *  - birthCity         : city of birth
   *  - birthCountry      : country of birth
   *  - docType           : document type
   *  - docNum            : document number
   *  - docExpiryDate     : date of expiry of the doc, as a moment() object
   *  - docPhotoFrontLink : link of the photo front
   *  - docPhotoBackLink  : link of the back of the photo
   */
  updateProfile(data, additionalData) {

    let profile = {};

    if (data.cf) profile.cf = data.cf;
    if (data.firstName) profile.firstName = data.firstName;
    if (data.middleNames) profile.middleNames = data.middleNames;
    if (data.lastName) profile.lastName = data.lastName;
    if (data.sex) profile.sex = data.sex;
    if (data.residenceCity) profile.residenceCity = data.residenceCity;
    if (data.residenceAddress) profile.residenceAddress = data.residenceAddress;
    if (data.residenceCountry) profile.residenceCountry = data.residenceCountry;
    if (data.residenceCAP) profile.residenceCAP = data.residenceCAP;
    if (data.birthDate) profile.birthDate = data.birthDate;
    if (data.birthCity) profile.birthCity = data.birthCity;
    if (data.birthCountry) profile.birthCountry = data.birthCountry;
    if (data.birthProvince) profile.birthProvince = data.birthProvince;
    if (data.docType) profile.docType = data.docType;
    if (data.docNum) profile.docNum = data.docNum;
    if (data.docExpiryDate) profile.docExpiryDate = data.docExpiryDate;
    if (data.docPhotoFrontLink) profile.docPictureFrontLink = data.docPhotoFrontLink;
    if (data.docPhotoBackLink) profile.docPictureBackLink = data.docPhotoBackLink;
    if (data.acceptedPrivacy != null) profile.acceptedPrivacy = data.acceptedPrivacy;
    if (data.acceptedTerms != null) profile.acceptedTerms = data.acceptedTerms;
    if (data.acceptedSpecialTerms != null) profile.acceptedSpecialTerms = data.acceptedSpecialTerms;

    if (!additionalData) additionalData = {}

    return new NPAPI().fetch('profile', '/profile', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...profile, 
        ...additionalData
      })
    }).then((response) => response.json())

  }

  /**
   * Sets the profile as verified
   * @param {string} profileId the id of the profile
   * @param {string} rejectionMessage an optional rejection message
   */
  setProfileVerified(profileId, verificationResult, rejectionMessage) {

    return new NPAPI().fetch('profile', '/admin/profiles/' + profileId + '/verification', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        profileVerificationResult: verificationResult, 
        optionalMsgForUser: rejectionMessage
      })
    }).then((response) => response.json())

  }

  /**
   * Retrieves the user profile
   */
  getProfileData() {

    return new NPAPI().fetch('profile', '/profile').then((response) => response.json());

  }

  getCountries() {

    return new NPAPI().fetch('profile', '/countries').then((response) => response.json());

  }

  getITProvinces() {

    // return new NPAPI().fetch('profile', '/provinces/it').then((response) => response.json());
    return fetch("https://comuni-ita.herokuapp.com/api/province").then((response) => response.json());

  }

  getITComuni(provName) {

    if (!provName) return fetch("https://comuni-ita.herokuapp.com/api/comuni").then((response) => response.json());
    else return fetch("https://comuni-ita.herokuapp.com/api/comuni/provincia/" + provName).then((response) => response.json());

  }

}
