import React from 'react';
import './ProfileMasterDataCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import NPIconButton from '../../comp/NPIconButton';

import { ReactComponent as EditSVG } from '../../icons/pencil.svg';

/**
 * Displays the master data of a profile:
 *  - First name, last name, sex, etc. 
 *  - Residence
 *  - Birth info
 * 
 * Parameters: 
 * 
 *  - showValidatedFields           :   (OPT, default false) - Boolean - Shows a green checkmark on OCR-validated fields, a warn otherwise
 *  - showNonValidatedFields        :   (OPT, default false) - Boolean - Shows a warning sign on non OCR-validated fields
 * 
 */
class ProfileMasterDataCard extends React.Component {

    constructor(props) {
        super(props);

        this.fieldVerified = this.fieldVerified.bind(this);
    }

    /**
     * Checks whether the field has been OCR verified
     */
    fieldVerified(field) {

        if (!this.props.profile) return false;

        // Codice Fiscale is treated differently: there's a field cfPass for that
        if (field == 'cf') {

            if (this.props.profile.cfPass === false) return false;

            return true;
        }

        // Other fields are recognized through OCR
        if (!this.props.profile.ocrMatches || this.props.profile.ocrMatches.length == 0) return false;

        return this.props.profile.ocrMatches.includes(field);

    }

    render() {

        const { t } = this.props;

        let birthProvince = this.props.profile.birthProvince ? this.props.profile.birthProvince.toUpperCase() : null;

        return (
            <NPCard>
                <div className="master-data-card">
                    <div className="left">
                        <div className="card-title font-large">{t('card.profile.master.data.title')}</div>
                        <div className="body-container">
                            <div className="column">
                                <NPLabeledValue label="label.first.name" value={this.props.profile.firstName} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('firstName')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('firstName')} />
                                <NPLabeledValue label="label.middle.names" value={this.props.profile.middleNames} marginVertical="6px" />
                                <NPLabeledValue label="label.last.name" value={this.props.profile.lastName} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('lastName')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('lastName')} />
                                <NPLabeledValue label="label.sex" value={this.props.profile.sex} marginVertical="6px" />
                            </div>
                            <div className="column">
                                <NPLabeledValue label="label.cf" value={this.props.profile.cf} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('cf')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('cf')} />
                                <NPLabeledValue label="label.birth.date" value={this.props.profile.birthDate} type="date" marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('birthDate')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('birthDate')} />
                                <NPLabeledValue label="label.birth.city" value={this.props.profile.birthCity + ' (' + birthProvince + ')'} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('birthCity')} />
                                <NPLabeledValue label="label.birth.country" value={this.props.profile.birthCountry ? this.props.profile.birthCountry.toUpperCase() : null} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('birthCountry')} />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="card-title font-large">{t('card.profile.residence.title')}</div>
                        <div className="column">
                            <NPLabeledValue label="label.residence.address" value={this.props.profile.residenceAddress} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('residenceAddress')} />
                            <NPLabeledValue label="label.residence.city" value={this.props.profile.residenceCity} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('residenceCity')} />
                            <NPLabeledValue label="label.residence.cap" value={this.props.profile.residenceCAP} marginVertical="6px" />
                            <NPLabeledValue label="label.residence.country" value={this.props.profile.residenceCountry ? this.props.profile.residenceCountry.toUpperCase() : "N.D."} marginVertical="6px" />
                            <NPLabeledValue label="label.phone" value={`${this.props.profile.phoneCountryCode} ${this.props.profile.phone}`} marginVertical="6px" checkmark={this.props.profile.phoneVerifiedOn != null ? true : false} />
                            <NPLabeledValue label="label.mail" value={`${this.props.profile.email}`} marginVertical="6px" />
                        </div>
                    </div>
                </div>
                <div className="card-edit-button">
                    <NPIconButton image={<EditSVG />} onClick={this.props.onEdit} size="small" />
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(ProfileMasterDataCard);