import { useState } from 'react';
import NPButton from '../../comp/NPButton';
import { NPCardMessage } from '../../comp/NPCardMessage';
import NPLabeledValue from '../../comp/NPLabeledValue'
import NPCard from '../NPCard'
import './AgencyTransactionSellerWireCard.css'
import Popup from 'reactjs-popup';
import AgencyTranAPI from '../../api/AgencyTranAPI';
import { NPLoadingBar } from '../../comp/NPLoadingBar';
import moment from 'moment-timezone';

export function AgencyTransactionSellerWireCard({ tx, seller, onWireDone }) {

    const [showConfirm, setShowConfirm] = useState(false)
    const [paying, setPaying] = useState(false)

    if (!tx.buyerPaid || !tx.propertyTransferred) return (<></>)

    if (!seller) return (
        <NPCard type="strong-warning">
            <NPCardMessage text="Il Venditore non si è ancora registrato. Non è quindi ancora disponibile l'IBAN al quale effettuare il bonifico." />
        </NPCard>
    )

    let beneficiary;
    if (seller.cf) beneficiary = `${seller.lastName} ${seller.firstName}`
    else beneficiary = seller.legalName;

    /**
     * Wire to the Seller
     */
    const postSellerWireDone = async () => {

        setShowConfirm(false)

        const timer = setTimeout(() => { setPaying(true) }, 400)

        await new AgencyTranAPI().postSellerWireDone(tx.id)

        clearTimeout(timer)
        setPaying(false)

        if (onWireDone) onWireDone();
    }

    /**
     * If the transaction is being paid, show a "in progress"
     */
    if (paying) return (
        <NPCard>
            <div className='agency-tx-seller-wire'>
                <NPLoadingBar label="Aggiornamento Transazione in corso.." />
            </div>
        </NPCard>
    )

    /**
     * If the transaction has already been paid, 
     * return a simple message
     */
    if (tx.sellerWireDone) return (
        <NPCard>
            <NPCardMessage icon="ok" text={`Il pagamento al Venditore è stato effettuato il ${moment(tx.sellerWireDoneOn, "YYYYMMDD").format('DD.MM.YYYY')}.`} />
        </NPCard>
    )

    // Calculate the total amount to wire
    // Amount to wire = price - fees
    const amountToWire = parseFloat(tx.price) - parseFloat(tx.fees)

    return (
        <NPCard>
            <div className='agency-tx-seller-wire'>
                <div className='title'>E' possibile procedere con il bonifico al Venditore.</div>
                <div className="row">
                    <NPLabeledValue label="label.payment.beneficiary" value={beneficiary} marginVertical={6} copy={true} />
                    <NPLabeledValue label="label.payment.iban.beneficiary" value={tx.iban} marginVertical={6} copy={true} />
                    <NPLabeledValue label="label.payment.amount" value={amountToWire} type="currency" marginVertical={6} />
                </div>
                <div className='buttons-container'>
                    <NPButton label="Bonifico effettuato" onClick={() => { setShowConfirm(true) }} />
                </div>
            </div>

            <Popup open={showConfirm} onClose={() => { setShowConfirm(false) }}>
                <NPCard highlightColor="complementary" highlight="top">
                    <div className="delete-profile-popup">
                        <div className="warn">
                            Attenzione!
                        </div>
                        <div>
                            L'importo deve essere di <b>€ {tx.price.toLocaleString({ type: "currency", currency: "EUR" })}</b>. <br />
                            L'IBAN deve essere: <b>{tx.iban}</b>.
                        </div>
                        <div style={{ marginTop: 12 }}></div>
                        <div className="title">
                            <b>Sei sicuro</b> di aver effettuato il bonifico con questi dati?
                        </div>
                        <div className="buttons-container">
                            <NPButton outline={true} filled={false} label="No" onClick={() => { setShowConfirm(false) }} />
                            <div style={{ flex: 1, display: "flex" }}></div>
                            <NPButton warning={true} outline={true} filled={false} label="Si" onClick={postSellerWireDone} />
                        </div>
                    </div>
                </NPCard>
            </Popup>

        </NPCard>
    )

}