import NPAPI from './NPAPI';

export default class InvoiceAPI {

  /**
   * Retrieves the invoice data
   * @param {string} tid transaction id
   * @returns the invoice data
   */
  getInvoiceData(tid) {

    return new NPAPI().fetch('invoice', '/invoices/' + tid).then((response) => response.json());

  }

}
