import NPAPI from './NPAPI';
import moment from 'moment-timezone';

export default class PayAPI {

  /**
   * Check user auth
   */
  getPaymentsByCid(cid) {

    return new NPAPI().fetch('payments', '/payments/incoming?cid=' + cid).then((response) => response.json());
    
  }
  
  simulatePayment(amount, paymentCode, transaction) {

    return new NPAPI().fetch('payments', '/payments/incoming', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          amount: amount, 
          currency: transaction.currency, 
          statement: paymentCode, 
          paymentCode: paymentCode, 
          date: moment().format('YYYYMMDD')
      })
    }).then((response) => response.json());
    
  }

}
