import React from 'react';
import './TransactionPayoutCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import TranAPI from '../../api/TranAPI';

/**
 * Displays the summary of the payment that needs to be done
 */
class TransactionPayoutCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.getPayoutAmount = this.getPayoutAmount.bind(this);
    }

    componentDidMount() {
        this.getPayoutAmount();
    }

    async getPayoutAmount() {

        if (!this.props.transaction.match) return;

        const payoutInfo = await new TranAPI().getPayoutAmount(this.props.transaction.id)

        this.setState({ payoutInfo: payoutInfo })
    }

    render() {

        const { t, transaction } = this.props;

        if (!transaction.match) return <div></div>;

        let payoutRecipient = "Destinatario del pagamento da determinare";
        if (this.state.payoutInfo) {
            if (this.state.payoutInfo.partyType == 'buyer') payoutRecipient = "Pagamento al Compratore";
            else if (this.state.payoutInfo.partyType == 'seller') payoutRecipient = "Pagamento al Venditore";
        }

        return (
            <NPCard>
                {this.state.payoutInfo &&
                    <div className="tx-payout-card">

                        <div className="card-title font-large">{payoutRecipient}</div>
                        <div className="payout-amt">
                            <NPLabeledValue label="" size="large" value={this.state.payoutInfo.payoutAmount} type="currency" currency={this.props.transaction.currency} textAlign="center" />
                        </div>
                        <div className="beneficiary-container">
                            <div style={{ flex: 1 }}>
                                {this.state.payoutInfo.partyType != null && <NPLabeledValue label="Beneficiario" size="large" value={`${this.state.payoutInfo.beneficiary}`} textAlign="center" />}
                            </div>
                            <div style={{ flex: 1 }}>
                                {this.state.payoutInfo.partyType != null && <NPLabeledValue label="IBAN" size="large" value={this.state.payoutInfo.partyType == 'buyer' ? "IBAN del Compratore" : this.state.payoutInfo.iban} textAlign="center" copy={this.state.payoutInfo.iban} />}
                            </div>
                        </div>

                        <div className="card-bottom-greyout">
                            <div className="card-title font-normal">Composizione dell'importo</div>
                            <div className="payout-composition">
                                <NPLabeledValue label="Importo bonifico" value={this.state.payoutInfo.payoutAmount} type="currency" currency={this.props.transaction.currency} textAlign="center" />
                                <div className='container font-normal'>=</div>
                                <NPLabeledValue label="Prezzo veicolo" value={this.state.payoutInfo.price} type="currency" currency={this.props.transaction.currency} textAlign="center" />
                                <div className='container font-normal'>-</div>
                                <NPLabeledValue label="Fee Pleggit" value={this.state.payoutInfo.fee} type="currency" currency={this.props.transaction.currency} textAlign="center" />
                                <div className='container font-normal'>-</div>
                                <NPLabeledValue label="Fee Disputa" value={this.state.payoutInfo.disputeFee} type="currency" currency={this.props.transaction.currency} textAlign="center" />
                            </div>
                        </div>

                    </div>
                }
            </NPCard>
        )
    }
}

export default withTranslation()(TransactionPayoutCard);