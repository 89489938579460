import './NPCardOutsideHeader.css';

export function NPCardOutsideHeader({ title, actions, filters, selectedFilters, onFilterSelected }) {

    return (
        <div className="np-card-outside-header">
            <div className='title-filters'>
                <div className='title'>{title}</div>
                <div className="filters">
                    {filters && filters.map((filter, i) => (
                        <Filter filter={filter} key={Math.random()} onFilterSelected={onFilterSelected} selectedOptionCode={selectedFilters[i]} />
                    ))}
                </div>
            </div>
            <div className='flexed'></div>
            <div className='actions-container'>
                {actions && actions.map((action) => <div className='single-action' key={Math.random()}>{action}</div>)}
            </div>
        </div>
    )
}

function Filter({ filter, onFilterSelected, selectedOptionCode }) {

    const selectedFilter = filter.filters.filter((f) => f.code == selectedOptionCode)

    return (
        <div className='filter'>
            <div className="counter">
                {selectedFilter && selectedFilter.length > 0 && selectedFilter[0].count}
            </div>
            <FilterDropDown options={filter.filters} selectedOptionCode={selectedOptionCode} onChange={(event) => { onFilterSelected(event.target.value) }} />
        </div>
    )

}

function FilterDropDown({ options, onChange, selectedOptionCode }) {

    return (
        <div className='dropdown-container'>
            <select onChange={onChange} value={selectedOptionCode}>
                {options && options.map((option) => (
                    <option key={option.code} value={option.code}>{option.label}</option>
                ))}
            </select>
        </div>
    )
}