import React from 'react';
import { withTranslation } from 'react-i18next';
import './RebatesListCard.css';
import NPCard from './NPCard';
import TranAPI from '../api/TranAPI';
import NPList from '../comp/list/NPList';
import { ReactComponent as FilterSVG } from '../icons/filter.svg';
import NPToggleIconButton from '../comp/NPToggleIconButton';
import { withRouter } from 'react-router';
import { ReactComponent as CertifiedSVG } from '../icons/certified-circle.svg';
import { ReactComponent as MatchedSVG } from '../icons/matched.svg';
import Cookies from 'universal-cookie';
import NPTextInput from '../forms/NPTextInput';
import { ReactComponent as SearchSVG } from '../images/search.svg';

const cookies = new Cookies();

/**
 * Params
 * 
 *  - rebateCodes       :   (MAND) the list of rebate codes
 */
class RebatesListCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.listDataConverter = this.listDataConverter.bind(this);
        this.onChangeSearchFilter = this.onChangeSearchFilter.bind(this);
        this.onRebateClick = this.onRebateClick.bind(this);

    }

    componentDidMount() {
    }

    onChangeSearchFilter(filter) { }

    onRebateClick(item) { }

    /**
     * Converter for the NPList
     */
    listDataConverter(item) {

        const { t } = this.props;

        return {
            values: [
                { value: item.code },
                { value: item.rebateClass },
                { value: item.generatedOn },
                { value: item.count },
            ]
        }

    }


    onTransactionClick(item) {

        this.props.history.push('/admin/transactions/detail', { transaction: item });
    }

    render() {

        const { t } = this.props;

        let headerClass = "header focused-easeInOut-motion";

        let listStyle = { marginTop: 12 };

        return (
            <NPCard>
                <div className="rebates-list-card">

                    <div className={headerClass}>
                        <div className="title font-large">{t('card.rebates.list.title')}</div>
                        <div className="search-container">
                            <NPTextInput label="" placeholder="Cerca un Codice Rebate" transform="uppercase" onChange={this.onChangeSearchFilter} disableValidation={true} icon={<SearchSVG />}  />
                        </div>
                    </div>

                    <NPList
                        style={listStyle}
                        data={this.props.rebateCodes}
                        dataConverter={this.listDataConverter}
                        types={['string', 'string', 'date', 'number']}
                        elementsSizing={["15%", "15%", null, "20%"]}
                        headers={[t('card.rebates.list.list.header.code'), t('card.rebates.list.list.header.class'), t('card.rebates.list.list.header.creation.date'), t('card.rebates.list.list.header.count')]}
                        onItemClick={this.onRebateClick}
                    />

                </div>
            </NPCard>
        )
    }
}

export default withRouter(withTranslation()(RebatesListCard));