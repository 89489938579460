import './NPCheckBox.css'

import { ReactComponent as TickSVG } from '../icons/tick.svg';

/**
 * Parameters
 *  - label             :   (MAND) label to show
 *  - ticked            :   (OPT) pass true to show as ticked
 *  - editMode          :   (OPT) pass true to make the checkbox editable
 *  - style             :   (OPT) extra styles
 * 
 * Callbacks:
 *  - onClick           :   (OPT) reacts to the click
 */
export function NPCheckBox({ label, ticked, editMode, onClick, style }) {

    return (
        <div className={`np-checkbox edit-${editMode}`} onClick={onClick} style={{style}}>
            <div className="box">
                {ticked === true && <TickSVG />}
            </div>
            <div className="label">
                {label}
            </div>
        </div>
    )
}