import * as config from '../Config';
import {newCid} from './NPAPI';

export default class AccessAPI {

  /**
   * Checks if the user is authorized to access
   * @param {string} token the token provided by the auth provider
   * @param {string} authProvider the auth provider used. Can be 'google' or 'fb'
   * @returns 
   */
  checkUserAuth(token, authProvider) {

    let options = {
      headers: {}
    }

    // Adding standard headers
    options.headers['Accept'] = 'application/json';
    options.headers['x-correlation-id'] = newCid();
    options.headers['Authorization'] = 'Bearer ' + token;
    options.headers['auth-provider'] = authProvider;
    options.method = 'POST';

    return fetch(config.APIS['access'] + '/token', options).then((response) => response.json());

  }

}
