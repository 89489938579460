import NPAPI from './NPAPI';
import moment from 'moment-timezone';

/**
 * API for the Marketplace Transactions
 */
export default class MarketplaceAPI {

  /**
   * Get the list of transactions
   */
  async getTransactionsAdmin() {

    return new NPAPI().fetch('mkt', `/admin/transactions`).then((response) => response.json());

  }

  /**
   * Get the detail of a transaction
   */
  async getTransactionDetail(tid) {

    return new NPAPI().fetch('mkt', `/admin/transactions/${tid}`).then((response) => response.json());

  }

  /**
   * Posts a "payment received" event for the given transaction
   * 
   * @param {string} tid The Transaction Id
   * @param {string} paymentCode The payment code of the transaction
   */
  async postPaymentReceived(tid, paymentCode) {

    return new NPAPI().fetch('mkt', `/admin/transactions/${tid}/payment`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ paymentCode: paymentCode })
    }).then((response) => response.json());

  }

  /**
   * Posts a "payment sent to seller" event for the given transaction
   * 
   * @param {string} tid The Transaction Id
   */
  async postSellerWireDone(tid) {

    return new NPAPI().fetch('mkt', `/admin/transactions/${tid}/seller/payment`, {
      method: "POST",
    }).then((response) => response.json());

  }


}
