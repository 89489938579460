import { Component } from 'react';
import './NPToggleIconButton.css';

/**
 * Generic button
 * 
 * Properties: 
 * 
 *  - image                 :   (MAND) the image as a react component (ReactComponent)
 *  - on                    :   (OPT, default false) toggles on and off the button
 * 
 * Listeners:
 * 
 *  - onClick               :   (OPT) called when the button is clicked
 */
export default class NPButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pressed: false,
            released: false
        }

        this.onClick = this.onClick.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

    }

    onMouseDown() {
        this.setState({
            pressed: true,
            released: false
        })
    }

    onMouseUp(event) {

        // Let's start the ripple! 
        // 1. Get the mouse coordinates relative to the button: that's where the ripple is going to start from! 
        // 1.1 Get the button
        let button = event.target;
        let i = 0;
        while (!button.classList.contains('np-toggle-icon-button') && i < 5) {
            button = button.parentNode;
            i++;
        }

        // 1.2 Get the button coordinates
        let buttonCoord = { x: button.getBoundingClientRect().left, y: button.getBoundingClientRect().top };

        // 1.3 Get the mouse coordinates
        let mouseCoord = { x: event.clientX, y: event.clientY };

        // 1.4. Get the mouse coord relative to the button
        let mouseRelativeCoord = { x: mouseCoord.x - buttonCoord.x, y: mouseCoord.y - buttonCoord.y };

        // 2. Define the ripple coordinates
        // The ripple should have its top left corner where the mouse is, but should rather have its center there
        // so the ripple top left coord will have to be shifted of half its width and height
        // The ripple size is going to be: 
        //   - width: half the width of the button 
        //   - height: twice the height of the button
        let rippleSize = { width: button.offsetWidth / 2, height: button.offsetHeight * 2 };
        let rippleCoord = { x: mouseRelativeCoord.x - rippleSize.width / 2, y: mouseRelativeCoord.y - rippleSize.height / 2 };

        this.setState({
            pressed: false,
            released: true,
            rippleCoord: rippleCoord,
            rippleSize: rippleSize
        })
    }

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    render() {

        let buttonClass = "np-toggle-icon-button focused-easeInOut-motion";
        if (this.props.on) buttonClass += ' on';
        
        let ripple;
        if (this.state.released) ripple = (
            <div className="ripple" style={{ left: this.state.rippleCoord.x, top: this.state.rippleCoord.y, width: this.state.rippleSize.width, height: this.state.rippleSize.height }}></div>
        )

        return (
            <div className={buttonClass} onClick={this.onClick} onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
                {this.props.image}
                {ripple}
            </div>
        )
    }
}