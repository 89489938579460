import React from 'react';
import { withTranslation } from 'react-i18next';
import NPCard from './NPCard';
import './PastTransactionCard.css';
import moment from 'moment-timezone';

class PastTransactionCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    translateCurrency(c) {

        if (c == 'EUR') return '€';
        if (c == 'USD') return '$';
        if (c == 'GBP') return '£';
        if (c == 'DKK') return 'kr.';

        return c;

    }

    render() {

        if (!this.props.transaction) return null;

        const { t } = this.props;

        let name = this.props.transaction.name;
        if (!name) name = this.props.transaction.licensePlates;

        return (
            <NPCard spacing="small">
                <div className="past-tx-card-content">
                    <div className="left">
                        <div className="font-normal bold name primary">{name}</div>
                        <div className="font-small price">{this.translateCurrency(this.props.transaction.currency) + ' ' + this.props.transaction.price.toLocaleString('en', {minimumFractionDigits: 2})}</div>
                    </div>
                    <div className="right">
                        <div className="label font-xsmall uppercase">{t('label.completed.on')}</div>
                        <div className="date font-small">{this.props.transaction.completedOn ? moment(this.props.transaction.completedOn, 'YYYYMMDD').format('DD.MM.YYYY') : 'N.A.'}</div>
                    </div>
                </div>
            </NPCard>
        )
    }

}

export default withTranslation()(PastTransactionCard);