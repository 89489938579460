import moment from 'moment-timezone'
import { NPCardMessage } from '../../comp/NPCardMessage'
import NPCard from '../NPCard'
import './AgencyPropertyTransferCard.css'

export function AgencyPropertyTransferCard({ tx }) {

    return (
        <NPCard>
            <div className='agency-pt-card row'>
                <NPCardMessage icon={tx && tx.propertyTransferred === true ? "ok" : null} color="accent-mkt" text={tx && tx.propertyTransferred === true ? `Il Passaggio di Proprietà è stato effettuato il ${moment(tx.propertyTransferredOn, 'YYYYMMDD').format('DD.MM.YYYY')}.` : "E' possibile procedere con il Passaggio di Proprietà per questo veicolo."} />
            </div>
        </NPCard>
    )
}