import React from 'react';
import './TransactionHeaderCard.css';
import NPLabeledValue from '../../comp/NPLabeledValue';
import NPCard from '../NPCard';
import { useTranslation } from 'react-i18next';

/**
 * Displays the key data of the transaction
 */
export default function TransactionHeaderCard({ transaction }) {

    const currency = transaction.currency ? transaction.currency : "EUR";

    const { t } = useTranslation();

    const isMarketplaceTransaction = transaction.marketplaceId != null;
    const isAgencyTransaction = transaction.agencyId != null;

    return (
        <NPCard>
            <div className="tx-header-card">

                {!isMarketplaceTransaction &&
                    <div className="tx-header-box">
                        <NPLabeledValue textAlign="center" size="large" label="label.license.plates" value={transaction.licensePlates} />
                    </div>
                }
                {isMarketplaceTransaction &&
                    <div className="tx-header-box">
                        <NPLabeledValue textAlign="center" size="large" label="Oggetto della compravendita" value={transaction.adTitle} />
                    </div>
                }
                {transaction.itemType &&
                    <div className="tx-header-box">
                        <NPLabeledValue textAlign="center" size="large" label="label.vehicle.type" value={t('catalog.' + transaction.itemType)} />
                    </div>
                }
                <div className='tx-header-box'>
                    <NPLabeledValue textAlign="center" size="large" label="Creata il" type="date" value={transaction.createdOn} />
                </div>
                <div className="tx-header-box">
                    <NPLabeledValue textAlign="center" size="large" label="label.amount" type="currency" currency={currency} value={transaction.price} />
                </div>
                {isAgencyTransaction &&
                    <div className="tx-header-box">
                        <NPLabeledValue textAlign="center" size="large" label="label.fee.agency" type="currency" currency={currency} value={transaction.agencyFees ? parseFloat(transaction.agencyFees) : 0} />
                    </div>
                }

            </div>
        </NPCard>
    )
}
