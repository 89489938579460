import React, { Component } from 'react';
import './NPCard.css';

/**
 * Standard card to use in the app dashboard
 * 
 * Parameters: 
 * 
 *  - layout            :   (OPT, default 'row'). Specifies the layout that the card will have
 *                          Can be: 'row' or 'column'
 *  - type              :   (OPT, default 'normal'). Specifies the type of card. 
 *                          It can be one of the following: 'normal', 'warning', 'strong-warning', 'action', 'primary-fill', 'complementary-fill
 *  - spacing           :   (OPT, default 'normal). Specifies the spacing between cards. 
 *                          Can be "normal", "small"
 *  - highlight         :   (OPT, default null) Highlights the card
 *                          Pass "top" to highlight the top of the card (thick grey border) - to change the color, pass highlightColor
 *  - highlightColor    :   (OPT, default grey) pass "primary", "complementary" or "accent"
 *  - padding           :   (OPT, default none) sets the internal padding
 *                          Pass "reduced" to have little padding compared to the default
 * 
 * Callback: 
 *  - onClick           :   (OPT, default none). Callback to react on the click of the card
 */
export default class NPCard extends Component {

    render() {

        let classes = "np-card focused-easeInOut-motion";
        if (this.props.layout == 'column') classes += ' column';
        if (this.props.type) classes += ' ' + this.props.type;
        if (this.props.type == 'action') classes += ' action fill-accent dark-background';
        else if (this.props.type == 'strong-warning') classes += ' fill-complementary dark-background';
        else if (this.props.type == 'primary-fill') classes += ' fill-primary dark-background';
        else if (this.props.type == 'complementary-fill') classes += ' fill-complementary dark-background';
        else classes += ' fill-white';
        if (this.props.spacing == 'small') classes += ' spacing-small';
        if (this.props.padding) classes += ' padding-' + this.props.padding;

        // Border color
        if (this.props.type == 'warning' || this.props.type == 'strong-warning') classes += ' border-complementary';
        else if (this.props.type == 'action') classes += ' border-accent';
        else classes += ' border-normal';

        // Highlight
        if (this.props.highlight) classes += ' highlight ' + this.props.highlight;
        if (this.props.highlight) classes += ' highlight-' + this.props.highlightColor;

        return (
            <div className={classes} onClick={() => { if (this.props.onClick) this.props.onClick(); }}>
                {this.props.children}
            </div>
        )
    }
}