import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminTransactionDetailScreen.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import ProfileAPI from '../api/ProfileAPI';
import TransactionHeaderCard from '../cards/transaction/TransactionHeaderCard';
import ProfileForTxCard from '../cards/profile/ProfileForTxCard';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AgencyTranAPI from '../api/AgencyTranAPI';
import { TransactionPaymentCard } from '../cards/transaction/TransactionPaymentCard';
import { AgencyPropertyTransferCard } from '../cards/transaction/AgencyPropertyTransferCard';
import { AgencyTransactionSellerWireCard } from '../cards/transaction/AgencyTransactionSellerWireCard';
import MarketplaceAPI from '../api/MarketplaceAPI';
import { TransactionFeesCard } from '../cards/transaction/TransactionFeesCard';
import { MarketplacePropertyTransferCard } from '../cards/transaction/MarketplacePropertyTransferCard';
import { MarketplaceSellerPaymentCard } from '../cards/transaction/MarketplaceSellerPaymentCard';

function AdminMarketplaceTransactionDetail() {

    const [transaction, setTransaction] = useState()
    const [buyerProfile, setBuyerProfile] = useState()
    const [sellerProfile, setSellerProfile] = useState()

    const location = useLocation()

    const init = () => {
        loadTransactionAndProfiles();
    }

    /**
     * Load the transaction data
     */
    const loadTransactionAndProfiles = async () => {

        const tid = location.state.tx.id;

        const apiResponse = await new MarketplaceAPI().getTransactionDetail(tid);

        setTransaction(apiResponse);

        // Load the buyer profile
        if (apiResponse.buyerUserId) {

            const profile = await loadProfile(apiResponse.buyerUserId);

            setBuyerProfile(profile)
        }

        // Load the seller profile
        if (apiResponse.sellerUserId) {

            const profile = await loadProfile(apiResponse.sellerUserId);

            setSellerProfile(profile)
        }
    }

    /**
     * Load a profile
     */
    const loadProfile = async (userId) => {

        const apiResponse = await new ProfileAPI().getProfileByUserId(userId)

        return apiResponse;

    }

    useEffect(init, [])

    if (!transaction) return <div></div>

    return (
        <div className="admin-transaction-detail-screen">

            <div className='tx-id'>{transaction.id}</div>

            <TransactionHeaderCard transaction={transaction} />

            <TransactionFeesCard transaction={transaction} />

            <TransactionPaymentCard transaction={transaction} onPaymentReceived={init} />

            <MarketplacePropertyTransferCard transaction={transaction} />

            <MarketplaceSellerPaymentCard transaction={transaction} onSellerPaymentDone={init} />

            <AgencyTransactionSellerWireCard tx={transaction} seller={sellerProfile} onWireDone={init} />

            <div className="two-cols">
                <div className="col left">
                    <div className="card-container"><ProfileForTxCard profile={buyerProfile} partyType="buyer" highlight={true} highlightColor="primary" /></div>
                </div>
                <div className="col right">
                    <div className="card-container"><ProfileForTxCard profile={sellerProfile} partyType="seller" highlight={true} /></div>
                </div>
            </div>

        </div>
    )
}

function Title() {

    return (
        <div className="admin-transaction-detail-title">
        </div>
    )
}

export default withProtection(
    withSingleColumnLayout(
        withRouter(withTranslation()(Title)),
        withRouter(withTranslation()(AdminMarketplaceTransactionDetail)),
        "wide"
    )
);