import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ProfileCard from '../cards/ProfileCard';
import './DashboardScreen.css';
import withTwoColumnsLayout from './hoc/WithTwoColumnsLayout';
import withProtection from './hoc/WithProtection';
import Cookies from 'universal-cookie';
import NewTransactionCard from '../cards/NewTransactionCard';
import TranAPI from '../api/TranAPI';
import ProfileAPI from '../api/ProfileAPI';
import PastTransactionCard from '../cards/PastTransactionCard';
import { withRouter } from 'react-router-dom';
import NewProfileCard from '../cards/profile/NewProfileCard';
import eventBus from '../evt/NPEventBus';
import pushListener from '../evt/PushListener';
import * as config from '../Config';

const cookies = new Cookies();

class DashboardLeft extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.load = this.load.bind(this);
        this.isNewTransactionVisible = this.isNewTransactionVisible.bind(this);

    }

    componentDidMount() {

        this.load();

        // Subscriptions
        eventBus.subscribeToEvent(config.EVENTS.profileVerified, this.load);
        eventBus.subscribeToEvent(config.EVENTS.profileOCRFailed, this.load);
        eventBus.subscribeToEvent(config.EVENTS.txCreated, this.load);
        eventBus.subscribeToEvent(config.EVENTS.txCompleted, this.load);
    }

    componentWillUnmount() {

        // Unsubscribe
        eventBus.unsubscribeToEvent(config.EVENTS.profileVerified, this.load);
        eventBus.unsubscribeToEvent(config.EVENTS.profileOCRFailed, this.load);
        eventBus.unsubscribeToEvent(config.EVENTS.txCreated, this.load);
        eventBus.unsubscribeToEvent(config.EVENTS.txCompleted, this.load);
    }

    load() {

        this.setState({ loading: true });

        new ProfileAPI().getProfileData().then((data) => {
            this.setState({ profile: data });

            new TranAPI().getLatestTransaction().then((tx) => {
                this.setState({ latestTx: Object.keys(tx).length === 0 ? null : tx, loading: false });

                // Activate PushListener to listen to transaction notifications
                pushListener.subscribeToTransactionNotifications(tx.id);
            })
        }, () => { this.setState({ loading: false }) })

    }

    /**
     * Defines if the New Transaction button should be visible
     * The button should NOT be visible if:
     *  - there is already an ongoing transaction (so that we keep the focus on the ongoing transaction)
     *  - the profile has not been verified
     */
    isNewTransactionVisible() {

        let visible = this.state.profile && this.state.profile.profileVerified === true;

        if (this.state.latestTx && this.state.latestTx.id && this.state.latestTx.completed != true) visible = false;

        return visible;

    }

    render() {

        const { t } = this.props;

        if (this.state.loading) return <div></div>

        // If there's no profile only show the "Create profile" button
        if (!this.state.profile || !this.state.profile.id) return (
            <NewProfileCard />
        )

        return (
            <div>
                <ProfileCard />
                {this.isNewTransactionVisible() && <NewTransactionCard />}
            </div>
        )

    }
}
/*****************************************************************************************
 * RIGHT
 *****************************************************************************************/
class DashboardRight extends Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();

        // Subscriptions
        eventBus.subscribeToEvent(config.EVENTS.profileVerified, this.load);
        eventBus.subscribeToEvent(config.EVENTS.profileOCRFailed, this.load);
        eventBus.subscribeToEvent(config.EVENTS.txCreated, this.load);
        eventBus.subscribeToEvent(config.EVENTS.txCompleted, this.load);
    }

    componentWillUnmount() {

        // Unsubscribe
        eventBus.unsubscribeToEvent(config.EVENTS.profileVerified, this.load);
        eventBus.unsubscribeToEvent(config.EVENTS.profileOCRFailed, this.load);
        eventBus.unsubscribeToEvent(config.EVENTS.txCreated, this.load);
        eventBus.unsubscribeToEvent(config.EVENTS.txCompleted, this.load);
    }

    load() {

        this.setState({ loading: true });

        new ProfileAPI().getProfileData().then((data) => {
            this.setState({ profile: data });

            new TranAPI().getLatestTransaction().then((tx) => {
                this.setState({ latestTx: Object.keys(tx).length === 0 ? null : tx });

                new TranAPI().getPastTransactions().then((data) => {

                    this.setState({ transactions: data.transactions, loading: false });

                })
            })
        }, () => { this.setState({ loading: false }) })

    }

    /**
     * Defines if the New Transaction button should be visible
     * The button should NOT be visible if:
     *  - there is already an ongoing transaction (so that we keep the focus on the ongoing transaction)
     *  - the profile has not been verified
     */
    isNewTransactionVisible() {

        let visible = false;
        
        if (this.state.profile && this.state.profile.profileVerified === true) {

            if (this.state.latestTx && this.state.latestTx.id && this.state.latestTx.completed != true) visible = true;
        }


        return visible; 

    }

    render() {

        const { t } = this.props;

        if (this.state.loading) return <div></div>

        let pastTxBox;
        if (this.state.transactions && this.state.transactions.length > 0) pastTxBox = (
            this.state.transactions.map((tx, i) => {
                return (
                    <PastTransactionCard transaction={tx} key={'past-tx-' + i} />
                )
            })
        )
        else pastTxBox = (
            <div className="past-tx-none font-small">
                {t('dashboard.past.tx.no.tx')}
            </div>
        )

        // New Transaction button
        let newTxBox;
        if (this.isNewTransactionVisible()) newTxBox = <NewTransactionCard format="reduced" />

        return (
            <div className="dashboard-right">
                <div className="section-label font-righteous">{t('dashboard.past.tx')}</div>
                {pastTxBox}
                {newTxBox}
            </div>
        )

    }
}

/*****************************************************************************************
 * TITLE
 *****************************************************************************************/
class Title extends Component {

    constructor(props) {
        super(props);

        this.state = { loading: true };
    }

    componentDidMount() {

        new ProfileAPI().getProfileData().then((profile) => {
            this.setState({ profile: profile, loading: false });
        })
    }

    render() {

        const { t } = this.props;

        let user = cookies.get('user');
        if (!user || !user.givenName) console.log("[dashboard] - For some reason the user cookie or the user's given name was not found...");

        let name;
        if (!this.state.loading) {
            if (this.state.profile && this.state.profile.firstName) name = this.state.profile.firstName;
            else name = user.givenName;
        }

        return (
            <div>{t('dashboard.hey')} {name},</div>
        )
    }
}

export default withProtection(
    withTwoColumnsLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(DashboardLeft)),
        withTranslation()(DashboardRight))
);