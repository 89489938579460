import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminTransactionDetailScreen.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import newTxSVG from '../images/card-new-tx-illustration.svg';
import NPCard from '../cards/NPCard';
import ProfileAPI from '../api/ProfileAPI';
import TranAPI from '../api/TranAPI';
import TransactionHeaderCard from '../cards/transaction/TransactionHeaderCard';
import TransactionPaymentsCard from '../cards/transaction/TransactionPaymentsCard';
import ProfileForTxCard from '../cards/profile/ProfileForTxCard';
import TransactionKeyDetailsCard from '../cards/transaction/TransactionKeyDetailsCard';
import TransactionDocsCard from '../cards/transaction/TransactionDocsCard';
import TranlogCard from '../cards/transaction/TranlogCard';
import moment from 'moment-timezone';
import DisputeCard from '../cards/dispute/DisputeCard';
import TransactionPayoutCard from '../cards/transaction/TransactionPayoutCard';
import { TransactionFeesCard } from '../cards/transaction/TransactionFeesCard';

const cookies = new Cookies();

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            navData: this.props.location.state,
        };

        this.reload = this.reload.bind(this);
        this.onForcedOCR = this.onForcedOCR.bind(this);
        this.onBuyerPaymentSimulated = this.onBuyerPaymentSimulated.bind(this);
    }

    componentDidMount() {
        this.reload();
    }

    reload() {

        this.setState({ loadingTx: true, loadingProfile: true, loadingCounterpartTx: true });

        new TranAPI().getTransaction(this.state.navData.transaction.id).then((tx) => {

            this.setState({ transaction: tx, loadingTx: false })

            // Get the counterpart tx
            if (tx.match) {

                this.setState({ loadingTx2: true })

                new TranAPI().getTransaction(tx.match).then((tx2) => {

                    if (Object.keys(tx2) == null || Object.keys(tx2).length == 0) { this.setState({ loadingTx2: false, loadingCounterpartTx: false, loadingProfile2: false }); return; }

                    this.setState({ counterpartTransaction: tx2, loadingTx2: false, loadingProfile2: true, loadingCounterpartTx: false })

                    // Get the counterpart Profile
                    new ProfileAPI().getProfileByUserId(tx2.user).then((data) => {
                        this.setState({ counterpart: data, loadingProfile2: false })
                    }, () => { this.setState({ loadingProfile2: false }) });

                }, () => { this.setState({ loadingTx2: false, loadingCounterpartTx: false }) });
            }
            else this.setState({ loadingCounterpartTx: false })
        }, () => { this.setState({ loadingTx: false, loadingCounterpartTx: false }) })

        new ProfileAPI().getProfileByUserId(this.state.navData.transaction.user).then((data) => {
            this.setState({ profile: data, loadingProfile2: false })
        }, () => { this.setState({ loadingProfile: false }) })
    }

    onForcedOCR() {
        this.reload();
    }

    onBuyerPaymentSimulated() {
        this.reload();
    }

    isPayoutInfoVisible(transaction) {

        const status = transaction.stateCode;

        if (status == 'completed' || status == 'cancelled' || status == 'dispute') return true;

        return false;
    }

    render() {

        const { t } = this.props;

        if (!this.state.transaction || !this.state.profile || this.state.loadingCounterpartTx) return <div></div>

        let sellerTransaction;
        if (this.state.transaction.partyType == 'seller') sellerTransaction = this.state.transaction;
        else sellerTransaction = this.state.counterpartTransaction;

        // Counterpart info
        let counterpartProfile;
        let counterpartTransactionLog;
        if (this.state.counterpart && this.state.counterpartTransaction) {

            counterpartProfile = (
                <div>
                    <div className="card-container"><ProfileForTxCard profile={this.state.counterpart} partyType={this.state.counterpartTransaction.partyType} highlight={true} /></div>
                    <div className="card-container"><TransactionKeyDetailsCard transaction={this.state.counterpartTransaction} /></div>
                </div>
            )

            counterpartTransactionLog = <TranlogCard transaction={this.state.counterpartTransaction} />

        }

        // Complete transaction
        let completedCard;
        if (this.state.transaction.completed) completedCard = (
            <NPCard type={this.state.transaction.stateCode == 'completed' ? "primary-fill" : "complementary-fill"}>
                <div className="completed-card bold">
                    {this.state.transaction.stateCode == 'completed' && t('card.tx.completed.on') + ' ' + ((this.state.transaction.completedOn) ? moment(this.state.transaction.completedOn, 'YYYYMMDD').format('DD.MM.YYYY') : '(?)')}
                    {this.state.transaction.stateCode == 'cancelled' && t('card.tx.cancelled.on') + ' ' + ((this.state.transaction.completedOn) ? moment(this.state.transaction.completedOn, 'YYYYMMDD').format('DD.MM.YYYY') : '(?)')}
                    {this.state.transaction.disputeResolutionCode == 'rejected' && t('card.tx.blocked')}
                </div>
            </NPCard>
        )

        // Transactions docs
        let transDocs;
        if (this.state.transaction.partyType == 'seller') transDocs = <TransactionDocsCard transaction={this.state.transaction} onForcedOCR={this.onForcedOCR} />
        else if (this.state.counterpartTransaction) transDocs = <TransactionDocsCard transaction={this.state.counterpartTransaction} onForcedOCR={this.onForcedOCR} />

        return (
            <div className="admin-transaction-detail-screen">

                <TransactionHeaderCard transaction={this.state.transaction} />

                <TransactionFeesCard transaction={this.state.transaction} />

                {completedCard}

                {this.isPayoutInfoVisible(this.state.transaction) && <TransactionPayoutCard transaction={this.state.transaction} />}

                {this.state.counterpartTransaction && <TransactionPaymentsCard transaction={this.state.transaction} onPayoutDone={this.reload} />}

                {this.state.transaction.disputeId != null && <DisputeCard sellerTransaction={sellerTransaction} onPayoutDone={this.reload} onDeadlineForced={this.reload} />}

                <div className="two-cols">
                    <div className="col left">
                        <div className="card-container"><ProfileForTxCard profile={this.state.profile} partyType={this.state.transaction.partyType} highlight={true} highlightColor="primary" /></div>
                        <div className="card-container"><TransactionKeyDetailsCard transaction={this.state.transaction} /></div>
                    </div>
                    <div className="col right">
                        {counterpartProfile}
                    </div>
                </div>

                {transDocs}

                <div className="two-cols">
                    <div className="col left">
                        <TranlogCard transaction={this.state.transaction} />
                    </div>
                    <div className="col right">
                        {counterpartTransactionLog}
                    </div>
                </div>

            </div>
        )

    }
}

class Title extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.location.state
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-transaction-detail-title">
                <div className="image-container"><img src={newTxSVG} width="100%" /></div>
                <div className="text-container">
                    <div className="">{t('admin.transaction.detail.title')}</div>
                    <div className="font-large">{this.state.transaction.id}</div>
                </div>
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withRouter(withTranslation()(Title)),
        withRouter(withTranslation()(Content)),
        "wide"
    )
);