import { HSpacer } from "../../comp/layout/HSpacer";
import { NPCardMessage } from "../../comp/NPCardMessage";
import NPLabeledValue from "../../comp/NPLabeledValue";
import NPCard from "../NPCard";

export function MarketplacePropertyTransferCard({ transaction }) {

    // None of the parties have confirmed OT
    if (transaction.buyerConfirmedPTOn == null && transaction.sellerConfirmedPTOn == null) return (
        <NPCard>
            <NPCardMessage text="Le parti possono procedere con lo scambio del bene." />
        </NPCard>
    )

    // Both parties have confirmed the PT
    if (transaction.buyerConfirmedPTOn != null && transaction.sellerConfirmedPTOn != null) return (
        <NPCard>
            <NPCardMessage icon="ok" noBorder={true} text="Entrambe le parti hanno confermato lo scambio del bene." />
        </NPCard>
    )

    // Only the seler has confirmed the PT
    if (transaction.sellerConfirmedPTOn != null && transaction.buyerConfirmedPTOn == null) return (
        <NPCard>
            <NPCardMessage
                icon="carrier"
                date={transaction.sellerConfirmedPTOn}
                noBorder={true}
                text={<>Il Venditore ha confermato di aver consegnato il bene. <b>In attesa della conferma del Compratore</b>.</>}
                instructions={transaction.carrier ? <>Il Venditore ha indicato di aver <b>spedito</b> il bene. Di seguito le informazioni sul Corriere.</> : <>Il Venditore ha indicato di aver personalmente consegnato il bene al Compratore.</>}
            />
            {transaction.carrier != null &&
                <>
                    <HSpacer />
                    <div style={{ display: "flex" }}>
                        <NPLabeledValue label="Corriere" value={transaction.carrier} />
                        <div style={{ marginRight: 'var(--default-spacing)' }}></div>
                        <NPLabeledValue label="Numero di Telefono del Corriere" value={`${transaction.carrierPhoneCountryCode} ${transaction.carrierPhone}`} />
                        {transaction.trackingId &&
                            <>
                                <div style={{ marginRight: 'var(--default-spacing)' }}></div>
                                <NPLabeledValue label="Tracking ID" value={transaction.trackingId} />
                            </>
                        }
                    </div>
                </>
            }
        </NPCard>
    )

    // Only the buyer has confirmed the PT
    if (transaction.buyerConfirmedPTOn != null && transaction.sellerConfirmedPTOn == null) return (
        <NPCard>
            <NPCardMessage icon="ok" noBorder={true} text="Il Compratore ha confermato di aver ricevuto il bene. Il Venditore non ha ancora confermato di aver consegnato il bene." />
        </NPCard>
    )
}