import React from 'react';
import './TransactionDocsCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPPhoto from '../../comp/NPPhoto';
import FileAPI from '../../api/FileAPI';
import { ReactComponent as TickSVG } from '../../icons/tick-circle.svg';
import { ReactComponent as WarnSVG } from '../../icons/exclamation-circle.svg';
import { ReactComponent as RobotSVG } from '../../images/robot.svg';
import { ReactComponent as LoadingSVG } from '../../icons/loader-ball-triangle.svg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import Popup from 'reactjs-popup';
import NPButton from '../../comp/NPButton';
import TranAPI from '../../api/TranAPI';

/**
 * Displays the key data of the transaction
 * 
 * Parameters
 *  - transaction           :   (MAND) the transaction
 * 
 * Callbacks
 *  - onForcedOCR           :   (MAND) called when the OCR is forced to pass
 */
class TransactionDocsCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            popup: false
        }

        this.loadPictures = this.loadPictures.bind(this);
        this.onPropertyProofClick = this.onPropertyProofClick.bind(this);
        this.onPropertyTransferClick = this.onPropertyTransferClick.bind(this);
        this.forceOCR = this.forceOCR.bind(this);
    }

    componentDidMount() {
        this.loadPictures();
    }

    loadPictures() {

        this.setState({ loading: true });

        if (!this.props.transaction) {
            setTimeout(100, this.loadPictures);
            return;
        }

        new FileAPI().downloadTransactionDoc(this.props.transaction.id, 'propertyproof', this.props.transaction.user).then((imgSrc) => {
            this.setState({ propertyproof: imgSrc, loading: false });
        }, () => { this.setState({ loading: false }) })

        new FileAPI().downloadTransactionDoc(this.props.transaction.id, 'propertytransfer', this.props.transaction.user).then((imgSrc) => {
            this.setState({ propertytransfer: imgSrc, loading: false });
        }, () => { this.setState({ loading: false }) })

    }

    onPropertyProofClick() {
        if (!this.props.transaction.ppOCRPass) this.setState({
            docBeingReviewed: 'propertyproof',
            popup: true
        })
    }
    onPropertyTransferClick() {
        if (!this.props.transaction.ptOCRPass) this.setState({
            docBeingReviewed: 'propertytransfer',
            popup: true
        })
    }

    forceOCR() {

        this.setState({ popup: false, docBeingReviewed: null });

        new TranAPI().adminForceOCR(this.props.transaction.id, this.state.docBeingReviewed).then(() => {

            if (this.props.onForcedOCR) this.props.onForcedOCR();
        });

    }

    render() {

        const { t } = this.props;

        let ppOCRPass = <Tippy content={t('tooltip.ocr.failed')} theme="light"><div className="image-container complementary"><WarnSVG /></div></Tippy>
        if (this.props.transaction.ppOCRPass === true) ppOCRPass = <Tippy content={t('tooltip.ocr.verified')} theme="light"><div className="image-container"><TickSVG /></div></Tippy>

        let ptOCRPass = <Tippy content={t('tooltip.ocr.failed')} theme="light"><div className="image-container complementary"><WarnSVG /></div></Tippy>
        if (this.props.transaction.ptOCRPass === true) ptOCRPass = <Tippy content={t('tooltip.ocr.verified')} theme="light"><div className="image-container"><TickSVG /></div></Tippy>

        let pp;
        if (this.state.propertyproof) pp = (
            <div className="row" onClick={this.onPropertyProofClick}>
                <div className="doc-container"><NPPhoto photo={this.state.propertyproof} /></div>
                <div className="text-container">{t('label.doc.property.proof')}</div>
                <div className="status-container">{ppOCRPass}</div>
            </div>
        )

        let pt;
        if (this.state.propertytransfer) pt = (
            <div>
                <div className="separator"></div>
                <div className="row" onClick={this.onPropertyTransferClick}>
                    <div className="doc-container"><NPPhoto photo={this.state.propertytransfer} /></div>
                    <div className="text-container">{t('label.doc.property.transfer')}</div>
                    <div className="status-container">{ptOCRPass}</div>
                </div>
            </div>
        )

        // Loading
        let loading;
        if (this.state.loading) loading = <div className="loader-container"><LoadingSVG /></div>

        return (
            <NPCard>
                <div className="tx-docs-card">

                    <div className="header">
                        <div className="title font-large">
                            {t('label.loaded.docs')}
                        </div>
                        {loading}
                    </div>

                    <div className="doc-list-container">
                        {pp}
                        {pt}
                    </div>

                    <Popup open={this.state.popup} onClose={() => { this.setState({ popup: false }) }}>
                        <NPCard>
                            <div className="tx-doc-force-ocr">
                                <div className="header">
                                    <div className="image-container"><RobotSVG /></div>
                                    <div className="title font-xlarge">{t('card.tx.doc.ocr.override.title')}</div>
                                </div>
                                <div className="desc">{t('card.tx.doc.ocr.override.desc1')}</div>
                                <div className="desc paragraph">{t('card.tx.doc.ocr.override.desc2')}</div>
                                <div className="desc">{t('card.tx.doc.ocr.override.desc3')}</div>

                                <div className="button-container">
                                    <NPButton label={t('button.label.doc.ocr.override')} onClick={this.forceOCR} />
                                </div>
                            </div>
                        </NPCard>
                    </Popup>

                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(TransactionDocsCard);