import React from 'react';
import './ProfileMasterDataCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPTextInput from '../../forms/NPTextInput';
import NPCoolRadio from '../../forms/NPCoolRadio';
import NPDateInput from '../../forms/NPDateInput';
import NPButton from '../../comp/NPButton';
import moment from 'moment-timezone';

/**
 * Displays the master data of a profile:
 *  - First name, last name, sex, etc. 
 *  - Residence
 *  - Birth info
 * 
 * Parameters: 
 * 
 *  - showValidatedFields           :   (OPT, default false) - Boolean - Shows a green checkmark on OCR-validated fields, a warn otherwise
 *  - showNonValidatedFields        :   (OPT, default false) - Boolean - Shows a warning sign on non OCR-validated fields
 * 
 * Callbacks:
 * 
 *  - onSave                        :   (OPT) callback, called when the user saves. Will be passed the updated info as an object
 *  - onCancel                      :   (OPT) callback, called when the user cancels the edit operation
 * 
 */
class EditProfileMasterDataCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props.profile
        }

        this.fieldVerified = this.fieldVerified.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    /**
     * Checks whether the field has been OCR verified
     */
    fieldVerified(field) {

        if (!this.props.profile) return false;

        // Codice Fiscale is treated differently: there's a field cfPass for that
        if (field == 'cf') {

            if (this.props.profile.cfPass === false) return false;

            return true;
        }

        // Other fields are recognized through OCR
        if (!this.props.profile.ocrMatches || this.props.profile.ocrMatches.length == 0) return false;

        return this.props.profile.ocrMatches.includes(field);

    }

    /**
     * Saves the data by providing it back to the controlling component
     */
    onSave() {
        if (this.props.onSave) this.props.onSave({
            firstName: this.state.firstName,
            middleNames: this.state.middleNames,
            lastName: this.state.lastName,
            sex: this.state.sex,
            cf: this.state.cf,
            birthDate: this.state.birthDate,
            birthCity: this.state.birthCity,
            birthProvince: this.state.birthProvince,
            birthCountry: this.state.birthCountry,
            residenceAddress: this.state.residenceAddress,
            residenceCity: this.state.residenceCity,
            residenceCAP: this.state.residenceCAP,
            residenceCountry: this.state.residenceCountry,
            phoneCountryCode: this.state.phoneCountryCode,
            phone: this.state.phone,
            email: this.state.email
        })
    }

    /**
     * Upon cancelling 
     */
    onCancel() {
        if (this.props.onCancel) this.props.onCancel();
    }

    render() {

        const { t } = this.props;

        let birthProvince = this.props.profile.birthProvince ? this.props.profile.birthProvince.toUpperCase() : null;

        let sexOptionNumber = null;
        if (this.props.profile.sex) sexOptionNumber = this.props.profile.sex == 'M' ? 1 : 2;

        return (
            <NPCard highlight="left" highlightColor="complementary">
                <div className="master-data-card">
                    <div className="left">
                        <div className="card-title font-large">{t('card.profile.master.data.title')}</div>
                        <div className="body-container">
                            <div className="column">
                                <NPTextInput label={t('label.first.name')} prefilled={this.props.profile.firstName} onChange={(value) => { this.setState({ firstName: value }) }} />
                                <NPTextInput label={t('label.middle.names')} prefilled={this.props.profile.middleNames} onChange={(value) => { this.setState({ middleNames: value }) }} />
                                <NPTextInput label={t('label.last.name')} prefilled={this.props.profile.lastName} onChange={(value) => { this.setState({ lastName: value }) }} />
                                <NPCoolRadio label={t('label.sex')} option1="M" option2="F" prefilled={sexOptionNumber} noDefault={true} onChange={(value) => { this.setState({ sex: value == 1 ? "M" : "F" }) }} />
                            </div>
                            <div className="column">
                                <NPTextInput label={t('label.cf')} prefilled={this.props.profile.cf} transform="uppercase" onChange={(value) => { this.setState({ cf: value }) }} />
                                <NPDateInput label={t('label.birth.date')} prefilled={this.props.profile.birthDate ? moment(this.props.profile.birthDate, "YYYYMMDD").format('DD.MM.YYYY') : ""} onChange={(value) => { this.setState({ birthDate: value ? moment(value, 'DD.MM.YYYY').format('YYYYMMDD') : null }) }} />
                                <NPTextInput label={t('label.birth.city')} prefilled={this.props.profile.birthCity} onChange={(value) => { this.setState({ birthCity: value }) }} />
                                <NPTextInput label={t('label.birth.province')} prefilled={this.props.profile.birthProvince} onChange={(value) => { this.setState({ birthProvince: value }) }} />
                                <NPTextInput label={t('label.birth.country')} prefilled={this.props.profile.birthCountry} onChange={(value) => { this.setState({ birthCountry: value }) }} />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="card-title font-large">{t('card.profile.residence.title')}</div>
                        <div className="body-container">
                            <div className="column">
                                <NPTextInput label={t('label.residence.address')} prefilled={this.props.profile.residenceAddress} onChange={(value) => { this.setState({ residenceAddress: value }) }} />
                                <NPTextInput label={t('label.residence.city')} prefilled={this.props.profile.residenceCity} onChange={(value) => { this.setState({ residenceCity: value }) }} />
                                <NPTextInput label={t('label.residence.cap')} prefilled={this.props.profile.residenceCAP} onChange={(value) => { this.setState({ residenceCAP: value }) }} />
                                <NPTextInput label={t('label.residence.country')} prefilled={this.props.profile.residenceCountry} onChange={(value) => { this.setState({ residenceCountry: value }) }} />
                                <div style={{ display: "flex" }}>
                                    <NPTextInput margin="12px 12px 12px 0" label={t('label.phone.prefix')} prefilled={this.props.profile.phoneCountryCode} style="shortest" onChange={(value) => { this.setState({ phoneCountryCode: value }) }} />
                                    <NPTextInput label={t('label.phone')} prefilled={this.props.profile.phone} onChange={(value) => { this.setState({ phone: value }) }} />
                                </div>
                                <NPTextInput label={t('label.mail')} prefilled={this.props.profile.email} onChange={(value) => { this.setState({ email: value }) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="master-data-buttons-container" style={{ marginTop: "24px" }}>
                    <NPButton label={t('button.label.cancel')} secondary={true} onClick={this.onCancel} />
                    <div style={{ flex: 1 }}></div>
                    <NPButton label={t('button.label.save')} onClick={this.onSave} />
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(EditProfileMasterDataCard);