import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminPaymentsUploadScreen.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import NPDocUploader from '../forms/NPDocUploader';
import uploadSVG from '../images/upload-payments.svg';
import NPCoolRadioMulti from '../forms/NPCoolRadioMulti';
import AdminPupAPI from '../api/AdminPupAPI';
import IncomingPaymentsCard from '../cards/IncomingPaymentsCard';
import Popup from 'reactjs-popup';
import '../comp/popup/NPErrorPopup.css';
import NPErrorPopup from '../comp/popup/NPErrorPopup';

const cookies = new Cookies();

const banks = ["Fineco", "UniCredit", "Intesa"];
const bankCodes = ["fineco", "unicredit", "intesa"];

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploading: false
        };

        const { t } = this.props;

        this.bankNotes = [
            t('admin.pup.fineco.notes'),
            t('admin.pup.unicredit.notes'),
            t('admin.pup.intesa.notes')
        ]

        this.onDocSelected = this.onDocSelected.bind(this);
        this.reset = this.reset.bind(this);

    }

    componentDidMount() {
    }

    /**
     * When the file is selected, upload it
     * @param {File} file 
     */
    onDocSelected(file) {

        this.setState({ uploading: true, paymentsUploadCID: null });

        new AdminPupAPI().uploadPayments(this.state.bankCode, file).then((data) => {
            setTimeout(() => {
                this.setState({
                    uploading: false,
                    uploadedDoc: file,
                    paymentsUploadCID: data.cid,
                    errors: (data.message) ? data : null
                })
            }, 500);
        })

    }

    reset() {
        this.setState({
            uploadedDoc: null,
            uploading: false,
            errors: null, 
            paymentsUploadCID: null
        })
    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-pup-screen">

                <div className="input-container">
                    <NPCoolRadioMulti options={banks} label={t('admin.pup.bank.select.label')} notes={this.bankNotes} onChange={(selectedIndex) => { this.setState({ bankCode: bankCodes[selectedIndex] }) }} />
                </div>

                <div className="input-container">
                    <NPDocUploader uploading={this.state.uploading} uploadingLabel={t('generic.uploading')} label={t('admin.pup.bank.file.upload')} uploadedDoc={this.state.uploadedDoc} uploadLabel={t('admin.pup.bank.file.upload.label')} uploadedLabel={t('admin.pup.bank.file.uploaded')} onChange={this.onDocSelected} />
                </div>

                <div className="input-container">
                    {this.state.paymentsUploadCID && !this.state.errors && <IncomingPaymentsCard cid={this.state.paymentsUploadCID} />}
                </div>

                <Popup open={this.state.errors != null} closeOnDocumentClick={false}>
                    <NPErrorPopup error={this.state.errors} onClose={this.reset}/>
                </Popup>

            </div>
        )

    }
}

class Title extends Component {

    render() {

        const { t } = this.props;

        return (
            <div className="admin-pup-title">
                <div className="image-container"><img src={uploadSVG} width="100%" /></div>
                <div className="title-container">{t('admin.pup.title')}</div>
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(Content))
    )
);