import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminRebatesScreen.css';
import { withRouter } from 'react-router-dom';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import Cookies from 'universal-cookie';
import rebatesSVG from '../images/card-rebates.svg';
import rebateGuySVG from '../images/rebate-guy.svg';
import NPActionCard from '../cards/NPActionCard';
import RebatesListCard from '../cards/RebatesListCard';
import FeesAPI from '../api/FeesAPI';

const cookies = new Cookies();

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.loadRebateCodes = this.loadRebateCodes.bind(this);

    }

    componentDidMount() {
        this.loadRebateCodes();
    }

    async loadRebateCodes() {

        const response = await new FeesAPI().getRebateCodes();

        this.setState({ rebateCodes: response.rebateCodes })

    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-rebates-screen">
                <NPActionCard img={rebateGuySVG} title={t('admin.card.new.rebate')} text={t('admin.card.new.rebate.desc')} onClick={() => { this.props.history.push('/admin/rebates/new') }} />

                <RebatesListCard rebateCodes={this.state.rebateCodes} />
            </div>
        )

    }
}

class Title extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.location.state
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-profile-detail-title">
                <div className="image-container"><img src={rebatesSVG} width="100%" /></div>
                <div className="text-container">
                    <div className="">{t('admin.rebates.title')}</div>
                </div>
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withRouter(withTranslation()(Title)),
        withRouter(withTranslation()(Content)),
        "wide"
    )
);