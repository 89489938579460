import React from 'react';
import './ProfileIdentityDocCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import NPPhoto from '../../comp/NPPhoto';
import FileAPI from '../../api/FileAPI';
import ProfileAPI from '../../api/ProfileAPI';
import NPIconButton from '../../comp/NPIconButton';
import NPTextInput from '../../forms/NPTextInput';
import NPSelect from '../../forms/NPSelect';

import { ReactComponent as EditSVG } from '../../icons/pencil.svg';
import moment from 'moment-timezone';
import NPDateInput from '../../forms/NPDateInput';
import NPButton from '../../comp/NPButton';

const docTypes = [
    { value: 'cartaid', label: "Carta d'Identità" },
    { value: 'passport', label: 'Passaporto' },
    { value: 'license', label: 'Patente' }
]

/**
 * Displays the identity document data of a profile:
 * 
 * Parameters: 
 * 
 *  - showValidatedFields           :   (OPT, default false) - Boolean - Shows a green checkmark on OCR-validated fields, a warn otherwise
 *  - showNonValidatedFields        :   (OPT, default false) - Boolean - Shows a warning sign on non OCR-validated fields
 *  - showOCRData                   :   (OPT, default false) - Boolean - Shows the link to see OCR data
 *  - ownProfile                    :   (OPT, default false) - Boolean - The profile is the connected user's profile - This will load the user's own picture
 * 
 */
class EditProfileMasterDataCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.fieldVerified = this.fieldVerified.bind(this);
        this.loadPictures = this.loadPictures.bind(this);
        this.loadOCRData = this.loadOCRData.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    /**
     * Checks whether the field has been OCR verified
     */
    fieldVerified(field) {

        if (!this.props.profile) return false;

        // Codice Fiscale is treated differently: there's a field cfPass for that
        if (field == 'cf') {

            if (!this.props.profile.cfPass) return false;

            return true;
        }

        // Other fields are recognized through OCR
        if (!this.props.profile.ocrMatches || this.props.profile.ocrMatches.length == 0) return false;

        return this.props.profile.ocrMatches.includes(field);

    }

    /**
     * When the user cancels
     */
    onCancel() {
        if (this.props.onCancel) this.props.onCancel();
    }

    /**
     * When the user saves, propagate back the info
     */
    onSave() {
        if (this.props.onSave) this.props.onSave({
            docType: this.state.docType, 
            docNum: this.state.docNum, 
            docExpiryDate: this.state.docExpiryDate, 
            legalRepresentativeDocType: this.state.legalRepresentativeDocType, 
            legalRepresentativeDocNum: this.state.legalRepresentativeDocNum, 
            legalRepresentativeDocExpiryDate: this.state.legalRepresentativeDocExpiryDate, 
        })
    }

    componentDidMount() {
        this.loadPictures();
        this.loadOCRData();
    }

    loadOCRData() {
        new ProfileAPI().getOCRDataByUserId(this.props.profile.userId).then((data) => {
            this.setState({
                ocrTokens: data ? data.tokens : null
            })
        })
    }

    loadPictures() {

        let frontLink = this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocPhotoFrontLink : this.props.profile.docPictureFrontLink;
        let backLink = this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocPhotoBackLink : this.props.profile.docPictureBackLink;

        if (!this.props.ownProfile) {

            new FileAPI().adminDownloadIDPhotoStream(frontLink, this.props.profile.userId).then((imgSrc) => {
                this.setState({ idDocFront: imgSrc });
            })

            new FileAPI().adminDownloadIDPhotoStream(backLink, this.props.profile.userId).then((imgSrc) => {
                this.setState({ idDocBack: imgSrc });
            })

        }
        else {

            new FileAPI().downloadIDPhotoStream(frontLink).then((imgSrc) => {
                this.setState({ idDocFront: imgSrc });
            })

            new FileAPI().downloadIDPhotoStream(backLink).then((imgSrc) => {
                this.setState({ idDocBack: imgSrc });
            })

        }
    }

    render() {

        const { t } = this.props;

        let docDate = this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocExpiryDate : this.props.profile.docExpiryDate;

        const onChangeDocDate = (value) => {
            if (!value || value == '') return;
            if (this.props.profile.userType == 'company') this.setState({ legalRepresentativeDocExpiryDate: moment(value, 'DD.MM.YYYY').format('YYYYMMDD') })
            else this.setState({ docExpiryDate: moment(value, 'DD.MM.YYYY').format('YYYYMMDD') })
        }

        const onChangeDocType = (value) => {
            if (this.props.profile.userType == 'company') this.setState({ legalRepresentativeDocType: value })
            else this.setState({ docType: value })
        }

        return (
            <NPCard>
                <div className="identity-doc-card">
                    <div className="card-title font-large">{t(this.props.profile.userType == 'company' ? 'card.profile.identity.doc.leg.rep.title' : 'card.profile.identity.doc.title')}</div>
                    <div className="card-content">
                        <div className="column">
                            <div style={{ maxWidth: "80%" }}><NPSelect options={docTypes} label={t('label.doc.type')} prefilled={this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocType : this.props.profile.docType} onChange={onChangeDocType} /></div>
                            <NPTextInput label={t('label.doc.num')} style="short" transform="uppercase" prefilled={this.props.profile.userType == 'company' ? this.props.profile.legalRepresentativeDocNum : this.props.profile.docNum} onChange={(value) => { this.props.profile.userType == 'company' ? this.setState({ legalRepresentativeDocNum: value }) : this.setState({ docNum: value }) }} />
                            <NPDateInput label={t('label.doc.expiry.date')} prefilled={docDate ? moment(docDate, "YYYYMMDD").format('DD.MM.YYYY') : ""} onChange={onChangeDocDate} />
                        </div>
                        <div className="column">
                            <NPPhoto ocrLink={this.props.showOCRData} ocrData={this.state.ocrTokens} photo={this.state.idDocFront} />
                        </div>
                        <div className="column">
                            <NPPhoto photo={this.state.idDocBack} />
                        </div>
                    </div>
                </div>
                <div class="master-data-buttons-container" style={{ marginTop: "24px" }}>
                    <NPButton label={t('button.label.cancel')} secondary={true} onClick={this.onCancel} />
                    <div style={{ flex: 1 }}></div>
                    <NPButton label={t('button.label.save')} onClick={this.onSave} />
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(EditProfileMasterDataCard);