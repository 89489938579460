import React from 'react';
import './ProfileMasterDataCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';

/**
 * Displays the master data of a profile:
 *  - First name, last name, sex, etc. 
 *  - Residence
 *  - Birth info
 * 
 * Parameters: 
 * 
 *  - showValidatedFields           :   (OPT, default false) - Boolean - Shows a green checkmark on OCR-validated fields, a warn otherwise
 *  - showNonValidatedFields        :   (OPT, default false) - Boolean - Shows a warning sign on non OCR-validated fields
 * 
 */
class ProfileMasterDataCompanyCard extends React.Component {

    constructor(props) {
        super(props);

        this.fieldVerified = this.fieldVerified.bind(this);
    }

    /**
     * Checks whether the field has been OCR verified
     */
    fieldVerified(field) {

        if (!this.props.profile) return false;

        // Codice Fiscale is treated differently: there's a field cfPass for that
        if (field == 'cf') {

            if (this.props.profile.cfPass === false) return false;

            return true;
        }

        // Other fields are recognized through OCR
        if (!this.props.profile.ocrMatches || this.props.profile.ocrMatches.length == 0) return false;

        return this.props.profile.ocrMatches.includes(field);

    }

    render() {

        const { t } = this.props;

        let birthProvince = this.props.profile.birthProvince ? this.props.profile.birthProvince.toUpperCase() : null;

        return (
            <NPCard>
                <div className="master-data-card">
                    <div className="left">
                        <div className="card-title font-large">{t('card.profile.master.data.company.title')}</div>
                        <div className="body-container">
                            <div className="column">
                                <NPLabeledValue label="label.legal.name" value={this.props.profile.legalName} marginVertical="6px" />
                                <NPLabeledValue label="label.phone" value={`${this.props.profile.phoneCountryCode} ${this.props.profile.phone}`} marginVertical="6px" checkmark={this.props.profile.phoneVerifiedOn != null ? true : false}  />
                                <NPLabeledValue label="label.mail" value={`${this.props.profile.email}`} marginVertical="6px" />
                            </div>
                            <div className="column">
                                <NPLabeledValue label="label.piva" value={this.props.profile.piva} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('piva')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('piva')} />
                            </div>
                        </div>
                        <div className="card-title font-large" style={{ marginTop: 24 }}>{t('card.profile.master.data.company.legal.rep')}</div>
                        <div className="body-container">
                            <div className="column">
                                <NPLabeledValue label="label.first.name" value={this.props.profile.legalRepresentativeFirstName} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('legalRepresentativeFirstName')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('legalRepresentativeFirstName')} />
                                <NPLabeledValue label="label.middle.names" value={this.props.profile.legalRepresentativeMiddleNames} marginVertical="6px" />
                            </div>
                            <div className="column">
                                <NPLabeledValue label="label.last.name" value={this.props.profile.legalRepresentativeLastName} marginVertical="6px" checkmark={this.props.showValidatedFields && this.fieldVerified('legalRepresentativeLastName')} warnIfNoCheckmark={this.props.showValidatedFields} warning={this.props.showNonValidatedFields && !this.fieldVerified('legalRepresentativeLastName')} />
                                <NPLabeledValue label="label.cf" value={this.props.profile.legalRepresentativeCF} marginVertical="6px" />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="card-title font-large">{t('card.profile.master.data.reg.office.title')}</div>
                        <div className="column">
                            <NPLabeledValue label="label.residence.address" value={this.props.profile.regOfficeAddress} marginVertical="6px" />
                            <NPLabeledValue label="label.residence.city" value={this.props.profile.regOfficeCity} marginVertical="6px" />
                            <NPLabeledValue label="label.residence.cap" value={this.props.profile.regOfficeCAP} marginVertical="6px" />
                            <NPLabeledValue label="label.residence.province" value={this.props.profile.regOfficeProvince ? this.props.profile.regOfficeProvince.toUpperCase() : null} marginVertical="6px" />
                            <NPLabeledValue label="label.residence.country" value={this.props.profile.regOfficeCountry ? this.props.profile.regOfficeCountry.toUpperCase() : null} marginVertical="6px" />
                        </div>
                    </div>
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(ProfileMasterDataCompanyCard);