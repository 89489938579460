import NPAPI from './NPAPI';

export default class AdminPupAPI {

    uploadPayments(bank, file) {

        const formData = new FormData()
        formData.append('file', file);

        return new NPAPI().fetch('adminPup', '/pups/' + bank, {
            method: 'POST',
            body: formData
        }).then((response) => response.json());

    }

}