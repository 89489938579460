
import { ReactComponent as PleggitLogoNoColor } from '../images/logo/logo-no-color.svg'
// import './Site.css';
import './Footer.css';

export default function Footer(props) {

    const onClickTerms = () => { window.open("https://pleggit.com/terms", "_blank") }
    const onClickPrivacy = () => { window.open("https://pleggit.com/privacy", "_blank") }
    const onClickSupport = () => { window.open("https://pleggit.com/support", "_blank") }
    const onClickMedia = () => { window.open("https://pleggit.com/media", "_blank") }

    return (

        <div className="footer">
            <div className="logo"><PleggitLogoNoColor /></div>
            <div className="footer-items">
                <div className="footer-item" onClick={onClickPrivacy}>Privacy</div>
                <div className="footer-item" onClick={onClickTerms}>T&C</div>
                <div className="footer-item" onClick={onClickSupport}>Supporto</div>
                <div className="footer-item" onClick={onClickMedia}>Media & Comunicazione</div>
            </div>
            <div className="copyright">© 2022 Pleggit. All rights reserved.</div>
        </div>

    )
}