import React from 'react';
import NPCard from './NPCard';
import './NPAdminMenuCard.css';

/**
 * Parameters
 */
export default function NPAdminMenuCard(props) {

    return (
        <div className="np-admin-menu-card focused-easeInOut-motion" onClick={props.onClick}>
            <div className="image-container">{props.img}</div>
            <div className="text-container">
                <div className="title">{props.title}</div>
            </div>
        </div>
    )
}