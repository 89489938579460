import React from 'react';
import './TranlogCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import { ReactComponent as TickSVG } from '../../icons/tick-circle.svg';
import { ReactComponent as WarnSVG } from '../../icons/exclamation-circle.svg';
import NPList from '../../comp/list/NPList';
import TranlogAPI from '../../api/TranlogAPI';

class TranlogCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.loadLogs = this.loadLogs.bind(this);
        this.prepData = this.prepData.bind(this);
    }

    componentDidMount() {
        this.loadLogs();
    }

    loadLogs() {
        new TranlogAPI().getTransactionLogs(this.props.transaction.id).then((data) => {
            this.setState({ logs: data.logs })
        })
    }

    prepData(item) {

        return {
            values: [
                { value: item.timestamp },
                { value: item.msg }
            ]
        }

    }

    render() {

        const { t } = this.props;

        return (
            <NPCard>
                <div className="tranlog-card">
                    <div className="card-title font-large">{t('label.transaction.history.' + this.props.transaction.partyType)}</div>

                    <NPList
                        data={this.state.logs}
                        dataConverter={this.prepData}
                        types={["string", "string"]}
                        elementsSizing={["35%"]}
                        headers={[t('card.txlog.list.header.time'), t('card.txlog.list.header.msg')]}

                    />
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(TranlogCard);