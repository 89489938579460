import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminProfileDetailScreen.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import NPCard from '../cards/NPCard';
import NPLabeledValue from '../comp/NPLabeledValue';
import NPOCRConfidence from '../comp/NPOCRConfidence';
import NPButton from '../comp/NPButton';
import ProfileAPI from '../api/ProfileAPI';
import ProfileMasterDataCard from '../cards/profile/ProfileMasterDataCard';
import ProfileMasterDataCompanyCard from '../cards/profile/ProfileMasterDataCompanyCard';
import ProfileIdentityDocCard from '../cards/profile/ProfileIdentityDocCard';
import ProfileVisuraDocCard from '../cards/profile/ProfileVisuraDocCard';
import NPActionCard from '../cards/NPActionCard';
import warningSVG from '../images/warning-white-large.svg';
import Popup from 'reactjs-popup';
import NPTextArea from '../forms/NPTextArea';

import { ReactComponent as VerifiedSVG } from '../images/profile-verified.svg';
import { ReactComponent as WarningSVG } from '../images/warning-illustration.svg';

import EditProfileMasterDataCard from '../cards/profile/EditProfileMasterDataCard';
import EditProfileIdentityDocCard from '../cards/profile/EditProfileIdentityDocCard';
import { PrivacyTermsFlagsCard } from '../cards/profile/PrivacyTermsFlagsCard';
import ProfileMasterDataCardP4AUser from '../cards/profile/ProfileMasterDataCardP4AUser';
import { ProfileAgencyCard } from '../cards/profile/ProfileAgencyCard';
import { ProfileMarketplaceCard } from '../cards/profile/ProfileMarketplaceCard';

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.location.state,
            idDocFront: null,
            idDocBack: null,
        };

        this.profileVerified = this.profileVerified.bind(this);
        this.postProfileRequiresUserFix = this.postProfileRequiresUserFix.bind(this);
        this.reload = this.reload.bind(this);
        this.deleteProfile = this.deleteProfile.bind(this);
        this.onRequestToDeleteProfile = this.onRequestToDeleteProfile.bind(this);
        this.onSaveProfileMasterData = this.onSaveProfileMasterData.bind(this);
        this.onSaveDocData = this.onSaveDocData.bind(this);
        this.onSaveFlags = this.onSaveFlags.bind(this);
    }

    componentDidMount() {
        this.reload();
    }

    reload() {

        new ProfileAPI().getProfileById(this.state.profile.id).then((data) => {
            this.setState({
                profile: data
            })
        })

    }

    async deleteProfile() {

        const result = await new ProfileAPI().deleteProfile(this.state.profile.id);

        this.props.history.push("/admin/profiles");

    }

    /**
     * Reacts to the admin user saving the user data that has been edited
     * 
     * @param {object} profileData master data of the profile
     */
    async onSaveProfileMasterData(profileData) {

        // Update the profile
        new ProfileAPI().updateProfile(
            profileData,
            {
                adminCreation: true,
                email: profileData.email,
                userId: this.state.profile.userId
            }
        ).then(this.reload)

        // Close the edit mode
        this.setState({ editMasterDataMode: false })

    }

    /**
     * Reacts to the admin user saving the user doc data that has been edited
     * 
     * @param {object} docData doc data of the profile
     */
    async onSaveDocData(docData) {

        // Update the profile
        new ProfileAPI().updateProfile(
            docData,
            {
                adminCreation: true,
                email: this.state.profile.email,
                userId: this.state.profile.userId
            }
        ).then(this.reload)

        // Close the edit mode
        this.setState({ editDocDataMode: false })

    }

    /**
     * Reacts to the admin user saving the user flags
     * 
     * @param {object} flags flags (terms and privacy) for the profile
     */
    async onSaveFlags(flags) {

        // Update the profile
        new ProfileAPI().updateProfile(
            flags,
            {
                adminCreation: true,
                email: this.state.profile.email,
                userId: this.state.profile.userId
            }
        ).then(this.reload)

        // Close the edit mode
        this.setState({ editFlagsMode: false })

    }

    /**
     * Reacts to the user clicking the delete profile button: 
     * it will show the confirmation popup
     */
    onRequestToDeleteProfile() {
        this.setState({ showConfirmDelete: true })
    }

    /**
     * Sets the profile as verified (by human)
     */
    profileVerified() {

        new ProfileAPI().setProfileVerified(this.state.profile.id, "verified").then(this.reload);
    }

    /**
     * Sets the profile as needing a user fix
     */
    postProfileRequiresUserFix() {

        new ProfileAPI().setProfileVerified(this.state.profile.id, "requiresUserAction", this.state.rejectionMessage).then(this.reload);

        this.setState({ showConfirmReject: false })
    }

    /**
     * States whether the passed profile is "complete" (in terms of user-completeness).
     * User-completeness means that the user has inserted all information expected from him, in its context. 
     * 
     * If a user comes from an Agency (P4A), user-completeness is more loosely defined.
     */
    isProfileComplete(userProfile) {

        // Check if the user comes from an Agency. If not, normal user-completeness applies
        if (!userProfile.agencyId) return userProfile.userComplete;

        // If the user comes from an Agency, less information is needed
        if (userProfile.cf) {

            return (userProfile.cf && userProfile.firstName && userProfile.lastName && userProfile.residenceAddress && userProfile.residenceCAP && userProfile.residenceCity && userProfile.residenceProvince && userProfile.residenceCountry)
        }
        else return (userProfile.piva && userProfile.legalName && userProfile.regOfficeAddress && userProfile.regOfficeCAP && userProfile.regOfficeCity && userProfile.regOfficeProvince && userProfile.regOfficeCountry)

    }

    render() {

        const { t } = this.props;

        // Profile verified vs Click to verify
        let profileStatusOrAction = (
            <div className="profile-buttons-container">
                <div className="button-container"><NPButton label={t('button.label.profile.checked')} onClick={this.profileVerified} /></div>
                <div className="button-container"><NPButton warning={true} outline={true} filled={false} label={t('button.label.profile.needs.user.fix')} onClick={() => { this.setState({ showConfirmReject: true }) }} /></div>

                <Popup open={this.state.showConfirmReject} onClose={() => { this.setState({ showConfirmReject: false }) }}>
                    <NPCard highlightColor="complementary" highlight="top">
                        <div className="reject-profile-popup">
                            <NPTextArea
                                label="Inserisci un messaggio per l'utente (opzionale)"
                                rows={3}
                                placeholder={t('label.profile.reject.msg.placeholder')}
                                onChange={(value) => { this.setState({ rejectionMessage: value }) }}
                            />
                            <div className="button-container">
                                <NPButton warning={true} outline={true} filled={false} label={t('button.label.profile.needs.user.fix')} onClick={this.postProfileRequiresUserFix} />
                            </div>
                        </div>
                    </NPCard>
                </Popup>
            </div>
        );
        if (this.state.profile.profileVerified) profileStatusOrAction = (
            <div className="profile-verified-status">
                <div className="status-left">
                    <NPLabeledValue label={t('label.profile.verified.date')} value={this.state.profile.profileVerifiedDate} type="date" marginBottom="12px" />
                    <NPLabeledValue label={t('label.profile.verified.by')} value={this.state.profile.profileVerifiedBy} />
                </div>
                <div className="status-right">
                    <VerifiedSVG />
                </div>
            </div>
        )
        else if (this.state.profile.profileRequiresUserFix) profileStatusOrAction = (
            <div className="profile-buttons-container">
                <div className="button-container"><NPButton label={t('button.label.profile.force.approve')} onClick={this.profileVerified} /></div>
            </div>
        )

        // In case the profile is not user complete, show that
        let profileIncompleteWarning;
        if (!this.isProfileComplete(this.state.profile)) profileIncompleteWarning = (
            <NPCard type="warning">
                <div className="profile-incomplete-card">
                    <div className="left">
                        <div className="title font-large">{t('card.profile.warning.incomplete')}</div>
                        <div className="font-small">{t('card.profile.warning.incomplete.desc')}</div>
                    </div>
                    <div className="right">
                        <WarningSVG />
                    </div>
                </div>
            </NPCard>
        )

        // For Profiles created by an Admin user (or service), show a card that allows edit of data
        let adminCard;
        if (this.state.profile.createdBy) adminCard = (
            <NPCard highlight="left" highlightColor="primary">
                <div style={{ display: "flex" }}>
                    <div className='left' style={{ flex: 1, textAlign: "left" }}>
                        <div className='title font-large'>Profilo creato da {this.state.profile.createdBy.indexOf('gserviceaccount') >= 0 ? "servizio" : "utente"} Admin</div>
                        <div className='font-small'>Creato da: <b>{this.state.profile.createdBy}</b></div>
                    </div>
                    <div className='right'>
                    </div>
                </div>
            </NPCard>
        )

        let masterDataCard, editMasterDataCard;
        if (this.state.profile.piva) {
            if (!this.state.profile.agencyId) masterDataCard = <ProfileMasterDataCompanyCard profile={this.state.profile} showValidatedFields={true} />
            else masterDataCard = <ProfileMasterDataCardP4AUser profile={this.state.profile} />
        }
        else {
            // Check if the user comes from an Agency or not
            if (!this.state.profile.agencyId) {
                masterDataCard = <ProfileMasterDataCard profile={this.state.profile} showValidatedFields={true} onEdit={() => { this.setState({ editMasterDataMode: true }) }} />
                editMasterDataCard = <EditProfileMasterDataCard profile={this.state.profile} onSave={this.onSaveProfileMasterData} onCancel={() => { this.setState({ editMasterDataMode: false }) }} />
            }
            else {
                masterDataCard = <ProfileMasterDataCardP4AUser profile={this.state.profile} />
            }
        }

        // Card showing Agency data
        let agencyDataCard;
        if (this.state.profile.agencyId) agencyDataCard = <ProfileAgencyCard profile={this.state.profile} />

        // Card showing Marketplace data
        let marketplaceDataCard;
        if (this.state.profile.marketplaceId) marketplaceDataCard = <ProfileMarketplaceCard profile={this.state.profile} />

        let visura;
        if (this.state.profile.userType == 'company') visura = <ProfileVisuraDocCard profile={this.state.profile} />

        return (
            <div className="admin-profile-detail-screen">

                {this.state.profile.userComplete && (
                    <NPCard>
                        <div className="ocr-container">
                            <div className="left">
                                <NPOCRConfidence confidence={this.state.profile.ocrConfidence} ocrPass={this.state.profile.ocrPass} cfPass={this.state.profile.cfPass} profileVerified={this.state.profile.profileVerified} requestedUserFix={this.state.profile.profileRequiresUserFix} messageToUser={this.state.profile.userFixMessage} />
                            </div>
                            <div className="right">
                                {profileStatusOrAction}
                            </div>
                        </div>
                    </NPCard>
                )}

                {profileIncompleteWarning}

                {agencyDataCard}
                
                {marketplaceDataCard}

                {adminCard}

                {!this.state.editMasterDataMode && masterDataCard}
                {this.state.editMasterDataMode && editMasterDataCard}

                {!this.state.editDocDataMode && !this.state.profile.agencyId && <ProfileIdentityDocCard profile={this.state.profile} showValidatedFields={true} showOCRData={true} onEdit={() => { this.setState({ editDocDataMode: true }) }} />}
                {this.state.editDocDataMode && <EditProfileIdentityDocCard profile={this.state.profile} onSave={this.onSaveDocData} onCancel={() => { this.setState({ editDocDataMode: false }) }} />}

                <PrivacyTermsFlagsCard editable={!this.state.profile.agencyId} profile={this.state.profile} editMode={this.state.editFlagsMode} onEdit={() => { this.setState({ editFlagsMode: true }) }} onSave={this.onSaveFlags} onCancel={() => { this.setState({ editFlagsMode: false }) }} />

                {visura}

                <NPActionCard type="danger-action" img={warningSVG} title={t('card.profile.delete.title')} text={t('card.profile.delete.desc')} onClick={this.onRequestToDeleteProfile} />

                <Popup open={this.state.showConfirmDelete} onClose={() => { this.setState({ showConfirmDelete: false }) }}>
                    <NPCard highlightColor="complementary" highlight="top">
                        <div className="delete-profile-popup">
                            <div className="warn">
                                Attenzione!
                            </div>
                            <div className="title">
                                Sei sicuro di volere eliminare questo profilo?
                            </div>
                            <div className="buttons-container">
                                <NPButton outline={true} filled={false} label={t('button.label.no')} onClick={() => { this.setState({ showConfirmDelete: false }) }} />
                                <div style={{ flex: 1, display: "flex" }}></div>
                                <NPButton warning={true} outline={true} filled={false} label={t('button.label.yes')} onClick={this.deleteProfile} />
                            </div>
                        </div>
                    </NPCard>
                </Popup>

            </div>
        )

    }
}

class Title extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.location.state
        }
    }

    render() {

        const { t } = this.props;

        let name = this.state.profile.firstName + ' ' + this.state.profile.lastName;
        if (this.state.profile.userType == 'company') name = this.state.profile.legalName;

        return (
            <div className="admin-profile-detail-title">
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withRouter(withTranslation()(Title)),
        withRouter(withTranslation()(Content)),
        "wide"
    )
);