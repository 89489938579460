import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NPCard from './NPCard';
import './ProfileCard.css';
import NPProgressBar from '../comp/progress/NPProgressBar';
import { ReactComponent as UserSVG } from '../icons/user.svg';
import { ReactComponent as NewSVG } from '../icons/new.svg';
import { ReactComponent as IDCardSVG } from '../icons/id-card.svg';
import { ReactComponent as StampSVG } from '../icons/stamp.svg';
import { ReactComponent as GoalSVG } from '../icons/championship.svg';
import ProfileAPI from '../api/ProfileAPI';
import { withRouter } from 'react-router';
import NPButton from '../comp/NPButton';
import eventBus from '../evt/NPEventBus';
import * as config from '../Config';

const stepAvatars = [(<NewSVG />), (<UserSVG />), (<IDCardSVG />), (<StampSVG />), (<GoalSVG />)];

class ProfileCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileCompletionStep: 0,
            profileCompleted: false
        };

        this.loadProfileCompletion = this.loadProfileCompletion.bind(this);
        this.loadProfileData = this.loadProfileData.bind(this);
        this.onCardClick = this.onCardClick.bind(this);
        this.getCompletionText = this.getCompletionText.bind(this);
        this.getAdditionalText = this.getAdditionalText.bind(this);
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {

        this.reload();
        
        // Subscriptions
        eventBus.subscribeToEvent(config.EVENTS.profileVerified, this.reload);
        eventBus.subscribeToEvent(config.EVENTS.profileOCRFailed, this.reload);
    }

    componentWillUnmount() {

        // Unsubscribe
        eventBus.unsubscribeToEvent(config.EVENTS.profileVerified, this.reload);
        eventBus.unsubscribeToEvent(config.EVENTS.profileOCRFailed, this.reload);
    }
    
    reload() {
        this.loadProfileCompletion();
        this.loadProfileData();
    }

    loadProfileData() {

        new ProfileAPI().getProfileData().then((data) => {

            this.setState({ profile: data });

        })
    }

    /**
     * Loads the profile completion info
     */
    loadProfileCompletion() {

        new ProfileAPI().getProfileCompletion().then((completion) => {

            const { t } = this.props;

            if (completion && completion.step) {

                this.setState({
                    profileCompletionStep: completion.step,
                    profileCompleted: completion.completed,
                    helperText: t('card.profile.completion.step' + completion.step + '.popup.text'),
                    stepAvatar: stepAvatars[completion.step - 1]
                })
            }
        })

    }

    /**
     * Go to the profile creation page
     */
    onCardClick() {

        // If the OCR has run and there are problems go to the profile's details
        if (this.state.profile && this.state.profile.userComplete === true)
            this.props.history.push('/profile', { profile: this.state.profile });
        // else if (this.state.profile && this.state.profile.userComplete === true && this.state.profile.ocrPass == null && this.state.profile.cfPass === null)
        //     return;
        else
            this.props.history.push('/profile/new');

    }

    /**
     * @returns the text that describes the completion level of the profile
     */
    getCompletionText() {

        const { t } = this.props;

        // If the OCR has run and there are problems (OCR Pass is fale) and the profile hasn't been completed, warn the user
        if (this.state.profile && this.state.profile.profileVerified == false && this.state.profile.userComplete === true) 
            return (<span style={{ color: 'var(--color-complementary)', fontWeight: 'bold' }}>{t('card.profile.completion.warn.errors')}</span>)

        return t('card.profile.completion.step' + this.state.profileCompletionStep);
    }

    /**
     * @returns the text that describes the completion level to put in the popup
     */
    getPopupText() {

        const { t } = this.props;

        // If the OCR has run and there are problems (OCR Pass is fale) and the profile hasn't been completed, warn the user
        if (this.state.profile && this.state.profile.profileVerified == false && this.state.profile.userComplete === true )
            return (<span className="font-normal">{t('card.profile.completion.warn.errors.tooltip')}</span>)

        return t('card.profile.completion.step' + this.state.profileCompletionStep + '.popup.text');
    }

    /**
     * @returns Any additional text to give more info to the user
     */
    getAdditionalText() {

        const { t } = this.props;

        // If the OCR has run and there are problems (OCR Pass is fale) and the profile hasn't been completed, warn the user
        if (this.state.profile && this.state.profile.profileVerified == false && this.state.profile.userComplete === true )
            return (
                <div className="additional-info font-small">
                    <div style={{ display: 'flex' }}><NPButton label={t('card.profile.completion.warn.errors.button.label')} outline={true} filled={false} /></div>
                </div>
            )

        return null;

    }

    render() {

        const { t } = this.props;

        let progressBar;
        if (this.state.profile != null && this.state.profileCompletionStep != null) progressBar = (
            <NPProgressBar
                steps={4}
                currentStep={this.state.profileCompletionStep}
                currentStepAvatar={this.state.stepAvatar}
                currentStepDescription={this.getPopupText()}
                lastStepAvatar={<GoalSVG />}
                warning={!this.state.profileCompleted}
            />
        )

        return (
            <div className="profile-card">
                <NPCard layout="column" type={!this.state.profileCompleted ? 'warning' : ''} onClick={this.onCardClick}>
                    <div className="title bold"><span style={{ opacity: 0.6 }}>{t('card.profile.completion.label')}:</span> {this.getCompletionText()}</div>
                    <div className="progress-and-icon-container">
                        <div className="progress-bar-container">
                            {progressBar}
                        </div>
                        {/* {warningImage} */}
                    </div>
                    {this.getAdditionalText()}
                </NPCard>
            </div>
        )
    }
}

export default withRouter(withTranslation()(ProfileCard));