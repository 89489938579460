import React from 'react';
import './NPTextArea.css';

/**
 * 
 * @param {object} props supported properties: 
 *  - rows                  : number of rows (default 5)
 *  - value                 : value of the field
 *  - placeholder           : value of the optional placeholder
 * 
 * Callbacks
 *  - onChange              : called whenever the value is changed
 * @returns 
 */
export default function TextArea(props) {

    const rows = props.rows ? props.rows : 5;

    return (
        <div className="np-text-area">
            <div className="np-input-label">{props.label}</div>
            <textarea rows={rows} onChange={(evt) => {props.onChange(evt.target.value)}} placeholder={props.placeholder}/>
        </div>
    )
}