import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminProfiles.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import NPDocUploader from '../forms/NPDocUploader';
import profileSVG from '../images/profile.svg';
import NPCoolRadioMulti from '../forms/NPCoolRadioMulti';
import AdminPupAPI from '../api/AdminPupAPI';
import IncomingPaymentsCard from '../cards/IncomingPaymentsCard';
import Popup from 'reactjs-popup';
import '../comp/popup/NPErrorPopup.css';
import NPErrorPopup from '../comp/popup/NPErrorPopup';
import NPCard from '../cards/NPCard';
import ProfilesListCard from '../cards/ProfilesListCard';

const cookies = new Cookies();

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

    }

    componentDidMount() {
    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-profiles-screen">

                <ProfilesListCard />

            </div>
        )

    }
}

class Title extends Component {

    render() {

        const { t } = this.props;

        return (
            <div className="admin-profiles-title">
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(Content)), 
        "wide"
    )
);