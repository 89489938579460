import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminNewRebateCodeScreen.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import rebatesSVG from '../images/card-rebates.svg';
import FeesAPI from '../api/FeesAPI';
import NPSelect from '../forms/NPSelect';
import rebateGuySVG from '../images/rebate-guy.svg';
import NPCard from '../cards/NPCard';
import NPLabeledValue from '../comp/NPLabeledValue';
import NPOCRConfidence from '../comp/NPOCRConfidence';
import NPButton from '../comp/NPButton';
import { ReactComponent as VerifiedSVG } from '../images/profile-verified.svg';
import ProfileAPI from '../api/ProfileAPI';
import { ReactComponent as WarningSVG } from '../images/warning-illustration.svg';
import ProfileMasterDataCard from '../cards/profile/ProfileMasterDataCard';
import ProfileMasterDataCompanyCard from '../cards/profile/ProfileMasterDataCompanyCard';
import ProfileIdentityDocCard from '../cards/profile/ProfileIdentityDocCard';
import ProfileVisuraDocCard from '../cards/profile/ProfileVisuraDocCard';
import NPActionCard from '../cards/NPActionCard';
import NPTextInput from '../forms/NPTextInput';
import PLeggitAnimatedLoader from '../comp/PleggitAnimatedLoader';

const cookies = new Cookies();

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.loadRebateClasses = this.loadRebateClasses.bind(this);

        this.onCancel = this.onCancel.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onChangeRebateClass = this.onChangeRebateClass.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
    }

    componentDidMount() {
        this.loadRebateClasses();
    }

    async loadRebateClasses() {

        const rebateClasses = await new FeesAPI().getRebateClasses();

        const transformedRebateClasses = rebateClasses.map((c) => { return { value: c.code, label: c.label } })

        this.setState({ rebateClasses: transformedRebateClasses })
    }

    onCancel() { this.props.history.push('/admin/rebates') }

    onChangeRebateClass(value) { this.setState({ rebateClass: value }) }

    onChangeNotes(value) { this.setState({ notes: value }) }

    async onConfirm() {

        this.setState({ loading: true });

        const response = await new FeesAPI().newRebateCode(this.state.rebateClass, this.state.notes);

        this.setState({
            loading: false,
            rebateCode: response.rebateCode
        })

    }

    render() {

        const { t } = this.props;

        if (!this.state.rebateClasses) return <div></div>

        if (this.state.loading) return (
            <div className="admin-new-rebate-code-screen center">
                <div className="loading-text">Un attimo, sto creando il Codice Rebate...</div>
                <div><PLeggitAnimatedLoader /></div>
            </div>
        )

        if (this.state.rebateCode) return (
            <div className="admin-new-rebate-code-screen center">
                <div className="loading-text">Ecco il tuo Codice Rebate!</div>
                <NPLabeledValue label="Codice Rebate" value={this.state.rebateCode} textAlign="center" size="large" copy={true}/>

                <div className="buttons-container">
                    <NPButton label={t('button.label.back')} secondary={true} onClick={this.onCancel} />
                </div>
            </div>

        )

        return (
            <div className="admin-new-rebate-code-screen">
                <NPSelect label={t('form.label.select.rebate.class')} options={this.state.rebateClasses} onChange={this.onChangeRebateClass} defaultValue={this.state.rebateClasses[0]} />
                <NPTextInput label={t('form.label.rebate.notes')} onChange={this.onChangeNotes} />

                <div className="buttons-container">
                    <NPButton label={t('button.label.cancel')} secondary={true} onClick={this.onCancel} />
                    <div style={{ display: 'flex', flex: 1 }}></div>
                    <NPButton label={t('button.label.generate.code')} onClick={this.onConfirm} />
                </div>
            </div>
        )

    }
}

class Title extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.location.state
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-profile-detail-title">
                <div className="image-container"><img src={rebatesSVG} width="100%" /></div>
                <div className="text-container">
                    <div className="">{t('admin.rebates.new.code.title')}</div>
                </div>
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withRouter(withTranslation()(Title)),
        withRouter(withTranslation()(Content)),
        "wide"
    )
);