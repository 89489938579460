import React from 'react';
import { withTranslation } from 'react-i18next';
import './TransactionsListCard.css';
import NPCard from './NPCard';
import TranAPI from '../api/TranAPI';
import NPList from '../comp/list/NPList';
import { withRouter } from 'react-router';
import { ReactComponent as CertifiedSVG } from '../icons/certified-circle.svg';
import { ReactComponent as MatchedSVG } from '../icons/matched.svg';
import Cookies from 'universal-cookie';
import NPTextInput from '../forms/NPTextInput';
import {ReactComponent as SearchSVG} from '../images/search.svg';
import NPAdminButton from '../comp/NPAdminButton';

const cookies = new Cookies();

const TxFilter = {
    ONGOING: "ongoing",
    BO: "backoffice",
    DISPUTE: "dispute",
    ARCHIVED: "archived"
}

class TransactionsListCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: cookies.get('transactionListFilter') ? cookies.get('transactionListFilter') : "ongoing"
        }

        this.loadTransactions = this.loadTransactions.bind(this);
        this.listDataConverter = this.listDataConverter.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.countTransactions = this.countTransactions.bind(this);
        this.onTransactionClick = this.onTransactionClick.bind(this);
        this.onChangeSearchFilter = this.onChangeSearchFilter.bind(this);
        this.searchTransaction = this.searchTransaction.bind(this);
    }

    componentDidMount() {
        this.loadTransactions(this.state.filter);
        this.countTransactions("all");
        this.countTransactions("ongoing");
        this.countTransactions("completed");
        this.countTransactions("cancelled");
        this.countTransactions("payout");
        this.countTransactions("blocked");
        this.countTransactions("disputed");
    }

    countTransactions(filter) {

        new TranAPI().getTransactions(filter).then((data) => {

            this.setState({
                [filter]: data.count ? data.count : 0
            })
        })

    }

    /**
     * Loads the transactions
     */
    loadTransactions(filters) {

        new TranAPI().getTransactions(filters).then((data) => {

            this.setState({
                transactions: data.transactions,
                count: data.count
            })
        })

    }

    onChangeSearchFilter(value) {
        this.setState({ filterLicensePlates: value, filter: null }, this.searchTransaction)
    }

    async searchTransaction() {

        const transactions = await new TranAPI().adminSearchTransaction({ licensePlates: this.state.filterLicensePlates })

        this.setState({ transactions: transactions.transactions, count: transactions.count })
    }

    changeFilter(filterName) {

        cookies.set('transactionListFilter', filterName, { path: '/' });

        this.loadTransactions(filterName);

        this.setState({ filter: filterName })

    }


    /**
     * Converter for the NPList
     */
    listDataConverter(item) {

        const { t } = this.props;

        // Define the icon and tooltip that has to be shown for the item
        let icon = null, tooltip = null, tooltipType = null, iconName = null;

        if (item.completed) {
            icon = <CertifiedSVG />;
            iconName = "Completed";
            tooltip = t('tooltip.transaction.completed');
            tooltipType = 'ok';
        }
        else if (item.match) {
            icon = <MatchedSVG />;
            iconName = "Matched";
            tooltip = t('tooltip.transaction.matched');
            tooltipType = 'warning';
        }

        return {
            values: [
                { value: item.licensePlates },
                { value: t('catalog.' + item.itemType) },
                { value: item.creationTime },
                { value: t('party.type.' + item.partyType) },
                // { value: icon, iconName: iconName, tooltip: tooltip, tooltipType: tooltipType }
            ]
        }

    }


    onTransactionClick(item) {

        this.props.history.push('/admin/transactions/detail', { transaction: item });
    }

    render() {

        const { t } = this.props;

        let headerClass = "header focused-easeInOut-motion";
        if (this.state.filtersVisible) headerClass += ' fill-primary dark-background';

        let listStyle = { marginTop: 12 };
        if (this.state.filtersVisible) listStyle = { marginTop: 0 };

        return (
            <NPCard>
                <div className="transactions-list-card">

                    <div className={headerClass}>
                        <div className="title font-large">{t('card.transactions.list.title')}</div>
                        <div className="search-container">
                            <NPTextInput label="" placeholder="Cerca per targa" transform="uppercase" onChange={this.onChangeSearchFilter} disableValidation={true} icon={<SearchSVG/>} />
                        </div>
                    </div>

                    <div className="admin-buttons">
                        <NPAdminButton title="Tutte le transazioni" count={this.state.all} selected={!this.state.filter || this.state.filter == 'all'} onPress={() => { this.changeFilter(null) }} />
                        <NPAdminButton title="In corso" count={this.state.ongoing} selected={this.state.filter == "ongoing"} onPress={() => { this.changeFilter("ongoing") }} />
                        <NPAdminButton title="Completate" count={this.state.completed} selected={this.state.filter == "completed"} onPress={() => { this.changeFilter("completed") }} />
                        <NPAdminButton title="Annullate" count={this.state.cancelled} selected={this.state.filter == "cancelled"} onPress={() => { this.changeFilter("cancelled") }} />
                    </div>

                    <div className="todo-buttons-container">
                        <div className="label">Transazioni che richiedono il tuo intervento</div>
                        <div className="admin-buttons bo-todo">
                            <NPAdminButton title="Da liquidare" count={this.state.payout} selected={this.state.filter == "payout"} onPress={() => { this.changeFilter("payout") }} />
                            <NPAdminButton title="Bloccate" count={this.state.blocked} selected={this.state.filter == "blocked"} onPress={() => { this.changeFilter("blocked") }} />
                            <NPAdminButton title="Disputa" count={this.state.disputed} selected={this.state.filter == "disputed"} onPress={() => { this.changeFilter("disputed") }} />
                        </div>
                    </div>

                    <NPList
                        style={listStyle}
                        data={this.state.transactions}
                        dataConverter={this.listDataConverter}
                        types={['string', 'string', 'date', 'string']}
                        elementsSizing={["25%", "15%", "20%", null, "15%"]}
                        headers={[t('card.transactions.list.list.header.license.plates'), t('card.transactions.list.list.header.type'), t('card.transactions.list.list.header.creation.date'), t('card.transactions.list.list.header.party.type')]}
                        filtersOn={this.state.filtersVisible}
                        filterHandlers={[null, null, null, this.onFilterStatus]}
                        onItemClick={this.onTransactionClick}
                    />

                </div>
            </NPCard>
        )
    }
}

export default withRouter(withTranslation()(TransactionsListCard));