import './TransactionPaymentCard.css';
import { useState } from "react";
import NPButton from "../../comp/NPButton";
import NPLabeledValue from "../../comp/NPLabeledValue";
import NPCard from "../NPCard";
import AgencyTranAPI from "../../api/AgencyTranAPI";
import Popup from "reactjs-popup";
import moment from "moment-timezone";
import { NPValidationError } from "../../comp/NPValidationError";
import { NPLoadingBar } from "../../comp/NPLoadingBar";
import { NPCardMessage } from "../../comp/NPCardMessage";
import MarketplaceAPI from '../../api/MarketplaceAPI';

export function TransactionPaymentCard({ transaction, onPaymentReceived }) {

    const [sending, setSending] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [error, setError] = useState()

    // Calculate the amount based on the type of transaction
    let amount = parseFloat(transaction.price);
    if (transaction.agencyId) amount += transaction.agencyFees ? parseFloat(transaction.agencyFees) : 0;
    else if (transaction.marketplaceId) amount += transaction.buyerFee ? parseFloat(transaction.buyerFee) : 0;
    else amount += transaction.fee;

    // Define whether the Buyer has paid
    let buyerPaid = false;
    if (transaction.buyerPaid === true || transaction.buyerPaidOn != null) buyerPaid = true;

    /**
     * Confirm that the payment was received
     */
    const postPaymentReceived = async () => {

        setShowConfirm(false)

        const timer = setTimeout(() => { setSending(true) }, 400)

        let result;
        if (transaction.agencyId) result = await new AgencyTranAPI().postPaymentReceived(transaction.id, transaction.paymentCode);
        else if (transaction.marketplaceId) result = await new MarketplaceAPI().postPaymentReceived(transaction.id, transaction.paymentCode);

        clearTimeout(timer)
        setSending(false)

        if (result && result.code != 201) { setError(result.message) }

        if (onPaymentReceived) onPaymentReceived();

    }

    // If the buyer has made the payment already..
    if (buyerPaid === true) return (
        <NPCard>
            <NPCardMessage
                icon="ok"
                noBorder={true}
                text={`Il Pagamento del Compratore è stato ricevuto.`}
                date={transaction.buyerPaidOn}
            />
        </NPCard>
    )

    return (
        <NPCard>
            {!sending &&
                <>
                    <div className="status">
                        {!buyerPaid &&
                            <>
                                <div className="title">Il pagamento non è ancora stato effettuato.</div>
                                {error && <NPValidationError errorMessage={error} />}
                                <div className="info-text">In attesa di un bonifico che abbia il seguente importo e causale:</div>
                                <div className="row">
                                    <NPLabeledValue label="Importo" value={amount} type="currency" />
                                    <NPLabeledValue label="Causale" value={transaction.paymentCode} />
                                </div>
                            </>
                        }
                    </div>
                    {!buyerPaid && <div className="button-container"><NPButton label="Pagamento Ricevuto" onClick={() => { setShowConfirm(true) }} /></div>}
                </>
            }

            {sending && <NPLoadingBar label="Conferma pagamento ricevuto in corso.." />}

            <Popup open={showConfirm} onClose={() => { setShowConfirm(false) }}>
                <NPCard highlightColor="complementary" highlight="top">
                    <div className="delete-profile-popup">
                        <div className="warn">
                            Attenzione!
                        </div>
                        <div>
                            L'importo deve essere di <b>€ {amount.toLocaleString({ type: "currency", currency: "EUR" })}</b>. <br />
                            La causale deve contenere questo codice: <b>{transaction.paymentCode}</b>.
                        </div>
                        <div style={{ marginTop: 12 }}></div>
                        <div className="title">
                            <b>Sei sicuro</b> di aver ricevuto il bonifico e controllato sia importo che causale?
                        </div>
                        <div className="buttons-container">
                            <NPButton outline={true} filled={false} label="No" onClick={() => { setShowConfirm(false) }} />
                            <div style={{ flex: 1, display: "flex" }}></div>
                            <NPButton warning={true} outline={true} filled={false} label="Si" onClick={postPaymentReceived} />
                        </div>
                    </div>
                </NPCard>
            </Popup>


        </NPCard>
    )
}