import React from 'react';
import './NPAdminButton.css';

export default function NPAdminButton(props) {

    return (
        <div className={`admin-button selected-${props.selected}`} onClick={props.onPress}>
            <div className="count">{props.count ? props.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : (props.count == 0 ? 0 : "-")}</div>
            <div className="title">{props.title}</div>
        </div>
    )
}

