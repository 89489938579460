import React from 'react';
import NPToolbar from '../../comp/NPToolbar';
import './WithTwoColumnsLayout.css';
import './InAppLayout.css';

export default function withTwoColumnsLayout(Title, Left, Right) {

    return class extends React.Component {

        constructor(props) {
            super(props);
        }

        render() {

            return (
                <div className="in-app-screen">
                    <NPToolbar />
                    <div className="page">
                        <div className="page-title font-righteous font-xlarge"><Title /></div>
                        <div className="two-col">
                            <div className="main-content">
                                <Left />
                            </div>
                            <div className="side-content">
                                <Right />
                            </div>
                        </div>
                    </div>
                </div>
            )

        }
    }
}