import NPAPI from './NPAPI';

export default class TranAPI {

  /**
   * Retrieves the dispute
   */
  getDispute(disputeId) {

    return new NPAPI().fetch('dispute', '/disputes/' + disputeId).then((response) => response.json());

  }

  postDisputeUpdate(disputeId, updateCode) {

    return new NPAPI().fetch('dispute', '/disputes/' + disputeId, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        update: updateCode
      })
    }).then((response) => response.json());

  }

  getInjunctionLetter(tid) {

    return new NPAPI().fetch('dispute', `/injunctions/${tid}/letter`)
        .then((response) => {
            if (response.ok) return response.body;
            else throw new Error('No letter to be downloaded');
        })
        .then(body => {
            const reader = body.getReader();

            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            // Enqueue the next data chunk into our target stream
                            controller.enqueue(value);
                            return pump();
                        })
                    }
                }
            })
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .catch((error) => {
            console.log('Request failed', error);
        });

  }

  checkInjunctionLetterDeadlines() {

    return new NPAPI().fetch('dispute', '/tasks/disputes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: {
          data: "ewogICAgICAgICAgICAidGFzayI6ICJjaGVja0luanVuY3Rpb25EZWFkbGluZSIsIAoiY2lkIjogIjAwMDk5ODlhODBzNzhkYTA5OHMwZDkwMDAiCiAgICAgICAgfQ=="
        }
      })
    }).then((response) => response.json());


  }
}
