import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './NPErrorPopup.css';
import errorSVG from '../../images/popup-sorry.svg';
import { ReactComponent as CloseSVG } from '../../icons/close.svg';

class Error extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick() {

        if (this.props.onClose) this.props.onClose();
    }

    render() {

        const { t } = this.props;

        if (!this.props.error) return (<div></div>)

        // Error message
        let message = this.props.error.message;
        if (this.props.error.i18n) message = t(this.props.error.message);
        if (typeof this.props.error.message == 'object') message = t('popup.error.generic');

        // CID
        let cid = this.props.error.cid;

        return (
            <div className="np-error-popup">
                <div className="image-container"><img src={errorSVG} width="100%" /></div>
                <div className="text-container">
                    <div className="title font-xlarge">{t('popup.error.title')}</div>
                    <div className="title font-normal">{message}</div>
                    <div className="cid font-small">Correlation ID: {cid}</div>
                </div>
                <div className="close fill-accent dark-background focused-easeInOut-motion" onClick={this.onClick}>
                    <CloseSVG />
                </div>
            </div>
        )

    }
}

export default withTranslation()(Error);