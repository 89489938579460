import React from 'react';
import NPToolbar from '../../comp/NPToolbar';
import './WithSingleColumnLayout.css';
import './InAppLayout.css';
import NPBreadCrumb from '../../comp/NPBreadCrumb';
import NPButton from '../../comp/NPButton';

/**
 * 
 * @param {Component} Title (OPT) the title component 
 * @param {*} Content (MAND) the content of the page
 * @param {*} style (OPT) a style string
 * Can be
 *  - "default" - default style
 *  - "wide" - wide style, where the content is going to take a good part of the page
 * @param {object} params A set of parameters:
 *  - breadcrumb        : (OPT, (true | false) default true) shows a breadcrumb. 
 * @returns 
 */
export default function withTwoColumnsLayout(Title, Content, style, params) {

    return class extends React.Component {

        render() {

            let layoutStyle = style ? style : 'default';

            let layoutClass = 'in-app-screen ' + layoutStyle;

            let breadcrumb = (<NPBreadCrumb />)

            if (params) {
                if (params.breadcrumb == false) breadcrumb = null;
            }

            return (
                <div className={layoutClass}>
                    <NPToolbar />
                    <div className="page single">
                        {breadcrumb}
                        {Title && <div className="page-title font-xlarge"><Title /></div>}
                        <div className="content">
                            <Content/>
                        </div>
                    </div>
                </div>
            )

        }
    }
}