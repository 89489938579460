import NPAPI from './NPAPI';

export default class FileAPI {

    getTxDocTypes() {
        return {
            pp: 'propertyproof',
            pt: 'propertytransfer'
        }
    }

    /**
     * Uploads a photo of the ID
     * 
     * @param {string} type the type of file to upload
     * Can be either "front" or "back"
     * 
     * @param {File} file the file to upload
     */
    uploadIDPhoto(type, file) {

        const formData = new FormData()
        formData.append('file', file);

        return new NPAPI().fetch('fs', '/files/id/' + type, {
            method: 'POST',
            body: formData
        }).then((response) => response.json());

    }

    uploadPropertyTransfer(file, transactionId) {

        const formData = new FormData()
        formData.append('file', file);

        return new NPAPI().fetch('fs', '/files/transactions/' + transactionId + '/' + this.getTxDocTypes().pt, {
            method: 'POST',
            body: formData
        }).then((response) => response.json());

    }

    uploadPropertyProof(file, transactionId) {

        const formData = new FormData()
        formData.append('file', file);

        return new NPAPI().fetch('fs', '/files/transactions/' + transactionId + '/' + this.getTxDocTypes().pp, {
            method: 'POST',
            body: formData
        }).then((response) => response.json());

    }

    /**
     * 
     * @param {string} type the type of transaction doc. Refer at getTxDocTypes() for available types
     * @returns 
     */
    downloadIDPhotoStream(type) {

        return new NPAPI().fetch('fs', '/files/id/' + type)
            .then((response) => {
                if (response.ok) return response.body;
                else throw new Error('No photo to be loaded');
            })
            .then(body => {
                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            })
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .catch((error) => {
                console.log('Request failed', error);
            });
    }

    adminDownloadIDPhotoStream(type, userId) {

        return new NPAPI().fetch('fs', '/admin/files/id/' + type + '?userId=' + userId)
            .then((response) => {
                if (response.ok) return response.body;
                else throw new Error('No photo to be loaded');
            })
            .then(body => {
                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            })
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .catch((error) => {
                console.log('Request failed', error);
            });
    }

    /**
     * 
     * @returns 
     */
    downloadVisuraStream() {

        return new NPAPI().fetch('fs', '/files/visura')
            .then((response) => {
                if (response.ok) return response.body;
                else throw new Error('No photo to be loaded');
            })
            .then(body => {
                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            })
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .catch((error) => {
                console.log('Request failed', error);
            });
    }

    adminDownloadVisuraPhotoStream(userId) {

        return new NPAPI().fetch('fs', '/admin/files/visuraCamerale?userId=' + userId)
            .then((response) => {
                if (response.ok) return response.body;
                else throw new Error('No photo to be loaded');
            })
            .then(body => {
                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            })
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .catch((error) => {
                console.log('Request failed', error);
            });
    }

    /**
     * Returns the document of a transaction
     * @param {string} transactionId id of the transaction
     * @param {string} docType propertyproof or propertytransfer
     * @param {string} userId OPTIONAL: the user id to whom the tx belongs. If this is passed, the API called will be different (admin path)
     * @returns the doc as stream
     */
    downloadTransactionDoc(transactionId, docType, userId) {

        let baseURL = '/files/transactions/';
        if (userId) baseURL = '/files/' + userId + '/transactions/';

        return new NPAPI().fetch('fs', baseURL + transactionId + '/' + docType)
            .then((response) => {
                if (response.ok) return response.body;
                else throw new Error('No photo to be loaded');
            })
            .then(body => {
                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            })
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .catch((error) => {
                console.log('Request failed', error);
            });
    }
}