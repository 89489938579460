import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminUsersScreen.css';
import './NPComplexList.css';
import { withRouter } from 'react-router-dom';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import Cookies from 'universal-cookie';
import AuthAPI from '../api/AuthAPI';
import NPCard from '../cards/NPCard';
import NPIconButton from '../comp/NPIconButton';

import { ReactComponent as CompanySVG } from '../icons/company.svg';
import { ReactComponent as UserSVG } from '../icons/user.svg';
import { ReactComponent as OperatorSVG } from '../icons/operator.svg';
import { ReactComponent as AgencySVG } from '../icons/agency.svg';
import { ReactComponent as MarketplaceSVG } from '../icons/marketplace.svg';
import { ReactComponent as ArrowDownSVG } from '../icons/arrow-down.svg';
import { ReactComponent as ArrowUpSVG } from '../icons/arrow-up.svg';

import Tippy from '@tippyjs/react';
import moment from 'moment-timezone';
import NPButton from '../comp/NPButton';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const cookies = new Cookies();

function AdminUsersScreen(props) {

    const [count, setCount] = useState()
    const [users, setUsers] = useState()

    /**
     * Loads the registrations
     */
    const loadUsers = async () => {

        const apiResponse = await new AuthAPI().getRegistrations()

        if (!apiResponse) return;

        setCount(apiResponse.count)
        setUsers(apiResponse.users)

    }

    /**
     * Deletes the specified user
     */
    const deleteUser = async ({ userId }) => {

        await new AuthAPI().deleteUser(userId)

        await loadUsers();

    }

    useEffect(() => { loadUsers() }, [])

    return (
        <div className="screen admin-users-screen">
            <div className='card-title'>
                <div>Utenze Pleggit</div>
                <div className="hfill"></div>
                <div className='count bold'> {count}</div>
            </div>
            <NPCard>
                <NPComplexList users={users} onDeleteUser={deleteUser} />
            </NPCard>
        </div>
    )

}

function NPComplexList({ users, onDeleteUser }) {

    return (
        <div className="np-complex-list">
            {
                users && users.map((user) => <UserItem user={user} key={Math.random()} onDeleteUser={onDeleteUser} />)
            }
        </div>
    )
}

function UserItem({ user, onDeleteUser }) {

    const [detailVisible, setDetailVisible] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    return (
        <div className="list-item">
            <div className="main-line">
                <div className="cell col" style={{ marginRight: 12, marginTop: 6 }}>
                    <div className={`icon ${user.userType}`}>
                        {user.userType == 'company' && <Tippy content="Azienda"><CompanySVG /></Tippy>}
                        {user.userType == 'private' && <Tippy content="Utente Privato"><UserSVG /></Tippy>}
                        {user.userType == 'operator' && <Tippy content="Operatore"><OperatorSVG /></Tippy>}
                        {user.userType == 'agency' && <Tippy content="Agenzia"><AgencySVG /></Tippy>}
                    </div>
                </div>
                <div className="cell col" style={{ flex: 1 }}>
                    <div className="font-large">{user.email}</div>
                    <div className="font-xsmall low-relevance">{user.userId}</div>
                    <div className="row" style={{ marginTop: 6 }}>
                        <div className='icon accentmkt small' style={{ marginLeft: 0 }}>
                            {user.agencyId && <Tippy content="Questo Utente provenie da un Agenzia"><AgencySVG /></Tippy>}
                            {user.marketplaceId && <Tippy content="Questo Utente provenie da un Marketplace"><MarketplaceSVG /></Tippy>}
                        </div>
                        {user.agencyId && <div className="font-small">Agenzia Marinoni</div>}
                        {user.marketplaceId && <div className="font-small">{user.marketplaceId == 'AFTR' ? 'Affare Trattore' : user.marketplaceId}</div>}
                    </div>
                </div>
                <div className='hfill'></div>
                <div className="cell expander">
                    <NPIconButton image={detailVisible ? <ArrowUpSVG /> : <ArrowDownSVG />} noBackground={true} onClick={() => { setDetailVisible(!detailVisible) }} />
                </div>
            </div>
            {detailVisible &&
                <>
                    <div className="detail-line">
                        <div className='cell' style={{ minWidth: 90, width: "20%" }}>
                            <div className="labeled">
                                <div className='label'>Registrazione</div>
                                <div className="font-normal">{moment(user.registrationDate, 'YYYYMMDD').format('DD MMM YYYY')}</div>
                            </div>
                        </div>
                        <div className="cell row" style={{ width: "14%" }}>
                            <div className={`tag ${user.userType}`}>{user.userType}</div>
                        </div>
                    </div>
                    <div className='detail-line actions'>
                        <NPButton label="Elimina Utenza" warning={true} outline={true} filled={false} onClick={() => { setShowConfirmDelete(true) }} />
                    </div>
                </>
            }

            <Popup open={showConfirmDelete} onClose={() => { setShowConfirmDelete(false) }}>
                <NPCard highlightColor="complementary" highlight="top">
                    <div className="delete-profile-popup">
                        <div className="warn">
                            Attenzione!
                        </div>
                        <div className="title">
                            Sei sicuro di volere eliminare questa utenza?
                        </div>
                        <div className="buttons-container">
                            <NPButton outline={true} filled={false} label="No" onClick={() => { setShowConfirmDelete(false) }} />
                            <div style={{ flex: 1, display: "flex" }}></div>
                            <NPButton warning={true} outline={true} filled={false} label="Si" onClick={() => { onDeleteUser(user); setShowConfirmDelete(false) }} />
                        </div>
                    </div>
                </NPCard>
            </Popup>
        </div >
    )

}

class Title extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.location.state
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div className="admin-profile-detail-title">
            </div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withRouter(withTranslation()(Title)),
        withRouter(withTranslation()(AdminUsersScreen)),
        "wide"
    )
);