import React from 'react';
import './DisputeCard.css';
import NPCard from '../NPCard';
import { withTranslation, Trans } from 'react-i18next';
import DisputeAPI from '../../api/DisputeAPI';
import { ReactComponent as TickSVG } from '../../icons/tick-circle.svg';
import { ReactComponent as WarnSVG } from '../../icons/exclamation-circle.svg';
import { ReactComponent as RobotSVG } from '../../images/robot.svg';
import { ReactComponent as LoadingSVG } from '../../icons/loader-ball-triangle.svg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import Popup from 'reactjs-popup';
import NPButton from '../../comp/NPButton';
import TranAPI from '../../api/TranAPI';
import moment from 'moment-timezone';
import NPList from '../../comp/list/NPList';
import NPCoolRadio from '../../forms/NPCoolRadio';
import { ReactComponent as SuccessSVG } from '../../images/success.svg';
import PleggitAnimatedLoader from '../../comp/PleggitAnimatedLoader';

const DISPUTE_UPDATE_CODES = {

    propertyTransferred: { code: "property-transferred" },
    propertyNotTransferred: { code: "property-not-transferred-yet" },
    letterSent: { code: "letter-sent" },
    letterDeadlineExpire: { code: "buyer-deadline-expired" },
    transactionCompletedByUser: { code: "transaction-completed-by-user" },
    fundsReleased: { code: "funds-released" },
    disputeRejected: { code: "dispute-rejected" },
    disputeUnblockedSeller: { code: "dispute-unblocked-seller" },
    disputeUnblockedBuyer: { code: "dispute-unblocked-buyer" },
}

/**
 * Displays the key data of the dispute and the possible actions
 * 
 * Parameters
 *  - sellerTransaction         :   (MAND) the transaction of the seller
 * 
 * Callbacks
 *  - onPayoutDone              :   (OPT) called when the payout is done
 */
class DisputeCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            popup: false,
        }

        this.loadDispute = this.loadDispute.bind(this);
        this.prepData = this.prepData.bind(this);
        this.getBackOfficeAction = this.getBackOfficeAction.bind(this);
        this.onConfirmPTACI = this.onConfirmPTACI.bind(this);
        this.onNoPTACI = this.onNoPTACI.bind(this);
        this.onInjunctionSent = this.onInjunctionSent.bind(this);
        this.onFundsReleased = this.onFundsReleased.bind(this);
        this.onUnblock = this.onUnblock.bind(this);
        this.onForceDeadline = this.onForceDeadline.bind(this);
        this.onShowInjunctionLetter = this.onShowInjunctionLetter.bind(this);

    }


    componentDidMount() {
        this.loadDispute();
    }

    loadDispute() {

        return new Promise((success, failure) => {

            if (!this.props.sellerTransaction) { success(); return; }

            new DisputeAPI().getDispute(this.props.sellerTransaction.disputeId).then((dispute) => {
                this.setState({ dispute: dispute }, success)
            })
        })

    }

    /**
     * Confirms that the property transfer has been performed
     */
    onConfirmPTACI() {
        new DisputeAPI().postDisputeUpdate(this.props.sellerTransaction.disputeId, DISPUTE_UPDATE_CODES.propertyTransferred.code).then(this.loadDispute);
    }

    /**
     * Confirms that the property transfer is not yet registered 
     */
    onNoPTACI() {
        new DisputeAPI().postDisputeUpdate(this.props.sellerTransaction.disputeId, DISPUTE_UPDATE_CODES.propertyNotTransferred.code).then(this.loadDispute);
    }

    /**
     * Confirms that the Back Office has sent the injunction
     */
    onInjunctionSent() {
        new DisputeAPI().postDisputeUpdate(this.props.sellerTransaction.disputeId, DISPUTE_UPDATE_CODES.letterSent.code).then(this.loadDispute);
    }

    /**
     * Confirms that the funds have been released
     */
    onFundsReleased() {
        new DisputeAPI().postDisputeUpdate(this.props.sellerTransaction.disputeId, DISPUTE_UPDATE_CODES.fundsReleased.code).then(this.loadDispute).then(this.props.onPayoutDone);
    }

    /**
     * Unblocks the dispute by favouring one of the two parties 
     * @param {string} partyType the party that received the money (buyer or seller)
     */
    onUnblock(partyType) {

        let code = DISPUTE_UPDATE_CODES.disputeUnblockedSeller.code;
        if (partyType == 'buyer') code = DISPUTE_UPDATE_CODES.disputeUnblockedBuyer.code;

        new DisputeAPI().postDisputeUpdate(this.props.sellerTransaction.disputeId, code).then(this.loadDispute).then(this.props.onPayoutDone);
    }

    /**
     * Show the injunction letter PDF
     */
    async onShowInjunctionLetter() {

        this.setState({ downloadingInjunctionLetter: true })

        const pdfSource = await new DisputeAPI().getInjunctionLetter(this.state.dispute.transactionId);

        this.setState({ downloadingInjunctionLetter: false })

        window.open(pdfSource, '_blank', 'noopener,noreferrer');
    }

    /**
     * Forces to bypass the injunction letter deadline
     */
    onForceDeadline() {
        new DisputeAPI().checkInjunctionLetterDeadlines().then(this.loadDispute).then(this.props.onDeadlineForced);
    }

    getDisputeStateText(dispute) {

        if (dispute.status == 'open') return "aperta";
        else if (dispute.status == 'verification') return "verifica all'ACI"
        else if (dispute.status == 'letter') return "invio diffida"
        else if (dispute.status == 'waiting-for-letter-deadline') return "in attesa della scadenza del tempo per adempiere"
        else if (dispute.status == 'release') return "rilascia i fondi"
        else if (dispute.status == 'closed') return "chiusa"
        else return dispute.status

    }

    getBackOfficeAction(dispute) {

        const { t } = this.props;

        let actionMsg;
        let actions;

        if (this.props.sellerTransaction.disputeResolutionCode == 'resolvedByUser') return (

            <div className="action-container">
                <div>{t('dispute.action.closed.byuser')}</div>
                <div className="success-image-container"><SuccessSVG /></div>
            </div>

        )

        if (dispute.status == 'open' || dispute.status == 'verification') return (

            <div className="action-container">
                <div className="message">{dispute.status == 'open' ? t('dispute.action.verify') : t('dispute.action.verify.again')}</div>
                <div className="label">Il passaggio di proprietà è stato effettuato?</div>
                <div className="result">
                    <div className="button-container"><NPButton label={t('dispute.pt.check.yes')} raised={false} onClick={this.onConfirmPTACI} /></div>
                    <div className="button-container"><NPButton label={t('dispute.pt.check.no')} outline={true} filled={false} onClick={this.onNoPTACI} /></div>
                </div>
            </div>

        )

        if (dispute.status == 'letter') {

            if (this.state.downloadingInjunctionLetter) return (

                <div className="action-container">
                    <div className="message">{t('dispute.action.letter')}</div>
                    <div className="loader-container">
                        <PleggitAnimatedLoader />
                    </div>
                </div>

            )
            else return (

                <div className="action-container">
                    <div className="message">{t('dispute.action.letter')}</div>
                    <div className="result">
                        <NPButton label={t('dispute.action.show.injunction')} onClick={this.onShowInjunctionLetter} />
                    </div>
                    <div className="label" style={{ textAlign: 'center', marginTop: 24 }}>{t('dispute.question.sent')}</div>
                    <div className="result">
                        <div className="button-container"><NPButton label={t('dispute.sent.yes')} raised={false} onClick={this.onInjunctionSent} /></div>
                    </div>
                </div>
            )
        }

        if (dispute.status == 'waiting-for-letter-deadline') return (

            <div className="action-container">
                <div className="message">{dispute.disputeType == 'ecodes' ? t('dispute.action.wait.letter.deadline.pay.seller') : t('dispute.action.wait.letter.deadline.pay.buyer')}</div>
                <div className="result">
                    {/* <div className="button-container"><NPButton label={t('dispute.no.wait.for.deadline')} raised={false} onClick={this.onForceDeadline} /></div> */}
                </div>
            </div>

        )

        if (dispute.status == 'release') {

            return (

                <div className="action-container">
                    <div className="message">
                        {(dispute.disputeType == 'ecodes' || !dispute.disputeType) && t('dispute.action.release.funds')}
                        {dispute.disputeType == 'withdrawal' && t('dispute.action.release.funds.buyer')}
                    </div>
                    <div className="label" style={{ textAlign: 'center', marginTop: 24 }}>
                        {(dispute.disputeType == 'ecodes' || !dispute.disputeType) && t('dispute.question.funds.released')}
                        {dispute.disputeType == 'withdrawal' && t('dispute.question.funds.released.buyer')}
                    </div>
                    <div className="result">
                        <div className="button-container"><NPButton label={t('dispute.funds.released')} raised={false} onClick={this.onFundsReleased} /></div>
                    </div>
                </div>

            )
        }

        if (dispute.status == 'rejected') return (

            <div className="action-container">
                <div className="message">{t('dispute.action.solve.block')}</div>
                <div className="result">
                    <div className="button-container"><NPButton label={t('dispute.funds.release.to.seller')} raised={false} onClick={() => { this.onUnblock("seller") }} /></div>
                    <div className="button-container"><NPButton label={t('dispute.funds.release.to.buyer')} raised={false} onClick={() => { this.onUnblock("buyer") }} /></div>
                </div>
            </div>

        )

        if (dispute.status == 'closed') return (

            <div className="action-container">
                <div>{t('dispute.action.closed')}</div>
                <div className="success-image-container"><SuccessSVG /></div>
            </div>

        )

    }

    getDisputeHistory(dispute) {

        const firstUpdate = {
            code: "open",
            timestamp: moment(dispute.signedOn, 'YYYYMMDD HH:mm:ss').format("YYYYMMDD HH:mm")
        }

        let history = [firstUpdate];

        if (dispute.updates) history.push(...dispute.updates)

        return history;

    }

    prepData(item) {

        const { t } = this.props;

        let message;
        if (item.code === 'open') message = t('dispute.updates.open.msg')
        else if (item.code == 'property-not-transferred-yet') message = t('dispute.updates.property.not.transferred.yet')
        else if (item.code == 'property-transferred') message = t('dispute.updates.property.transferred')
        else if (item.code == 'letter-sent') message = t('dispute.updates.letter.sent')
        else if (item.code == 'buyer-deadline-expired') message = t('dispute.deadline.expired')
        else if (item.code == 'funds-released') message = t('dispute.updates.funds.released')
        else if (item.code == 'transaction-completed-by-user') message = t('dispute.updates.closed.by.users')
        else if (item.code == 'dispute-unblocked-seller') message = t('dispute.updates.unblocked.seller')
        else if (item.code == 'dispute-unblocked-buyer') message = t('dispute.updates.unblocked.buyer')
        else message = item.code;

        return {
            values: [
                { value: moment(item.timestamp, 'YYYYMMDD HH:mm').format("DD.MM.YYYY HH:mm") },
                { value: message }
            ]
        }

    }

    render() {

        const { t } = this.props;

        if (!this.props.sellerTransaction) return null;

        // Loading
        let loading;
        if (!this.state.dispute) return (
            <NPCard highlight="top" highlightColor="complementary">
                <div className="loader-container"><LoadingSVG /></div>
            </NPCard>
        )

        return (
            <NPCard highlight="top" highlightColor="complementary">
                <div className="dispute-card">

                    <div className="header">
                        <div className="title font-large">
                            {this.state.dispute.status != 'rejected' && t('label.dispute.exists')}
                            {this.state.dispute.status == 'rejected' && <Trans t={t}>label.dispute.blocked</Trans>}
                        </div>
                        <div className="subtitle">
                            {t('label.dispute.state')} {this.props.sellerTransaction.disputeId}: <span className="dispute-status">{this.getDisputeStateText(this.state.dispute)}</span>
                        </div>
                        <div className="explanation">
                            {t('label.dispute.openedby')}<b> {this.props.sellerTransaction.partyType == 'seller' && this.state.dispute.userId == this.props.sellerTransaction.user ? 'Venditore' : "Compratore"}</b> {t('label.dispute.anditsabout')} <b>{this.state.dispute.disputeType == 'withdrawal' ? t('label.dispute.about.withdrawal') : t('label.dispute.about.ecodes')}</b>.`
                        </div>
                        {loading}
                    </div>

                    <div className="dispute-body">
                        <div className="left">
                            <div className="title font-normal">{t('label.dispute.history')}</div>
                            <div className="list-container">

                                <NPList
                                    data={this.getDisputeHistory(this.state.dispute)}
                                    dataConverter={this.prepData}
                                    types={["string", "string"]}
                                    elementsSizing={["35%"]}
                                    headers={[t('card.dispute.list.header.time'), t('card.dispute.list.header.msg')]}

                                />

                            </div>
                        </div>
                        <div className="right">
                            <div className="title font-normal">{t('label.dispute.actions')}</div>
                            <div className="actions-container">
                                {this.getBackOfficeAction(this.state.dispute)}
                            </div>
                        </div>
                    </div>

                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(DisputeCard);