import NPAPI from './NPAPI';

export default class TranlogAPI {

  /**
   * Retrieves the logs of the transaction
   * @param {string} tid transaction id
   * @returns the transaction logs
   */
  getTransactionLogs(tid) {

    return new NPAPI().fetch('tranlog', '/logs/' + tid).then((response) => response.json());

  }

}
