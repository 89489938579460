import React from 'react';
import './ProfileForTxCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

/**
 * Parameters
 * 
 *  - highlight           : (OPT, default false) highlights this card
 *  - highlightColor      : (OPT, default primary color) color of the highlight
 */
class ProfileForTxCard extends React.Component {

    constructor(props) {
        super(props);

        this.userType = this.props.profile && this.props.profile.piva ? "company" : "private"

    }

    render() {

        const { t } = this.props;

        if (!this.props.profile) return (
            <NPCard highlight={this.props.highlight ? "top" : null} highlightColor={this.props.highlightColor}>
                <div className="profile-for-tx-card">
                    <div className="card-title font-large">{t('party.type.' + this.props.partyType)}</div>
                    <div className=''>
                        Utente non ancora registrato
                    </div>
                </div>
            </NPCard>
        )

        let left;
        if (this.props.profile.legalName) left = (
            <div className="left">
                <NPLabeledValue label="label.legal.name" value={this.props.profile.legalName} marginVertical="6px" />
                <NPLabeledValue label="label.piva" value={this.props.profile.piva} marginVertical="6px" />
            </div>
        )
        else left = (
            <div className="left">
                <NPLabeledValue label="label.first.name" value={this.props.profile.firstName} marginVertical="6px" />
                <NPLabeledValue label="label.cf" value={this.props.profile.cf} marginVertical="6px" />
            </div>
        )

        let right;
        if (this.props.profile.firstName) right = (
            <div className="right">
                <NPLabeledValue label="label.last.name" value={this.props.profile.lastName} marginVertical="6px" />
                <NPLabeledValue label="label.profile.creation.date" value={this.props.profile.creationDate} type="date" marginVertical="6px" />
            </div>
        )

        return (
            <NPCard highlight={this.props.highlight ? "top" : null} highlightColor={this.props.highlightColor}>
                <div className="profile-for-tx-card">
                    <div className="card-title font-large">{t('party.type.' + this.props.partyType)}</div>
                    <div className='id' onClick={() => { this.props.history.push('/admin/profiles/detail', { profile: this.props.profile }) }}>{this.props.profile.id}</div>
                    <div className=''>
                        <NPLabeledValue label="label.email" value={this.props.profile.email} copy={true} />
                    </div>
                    <div className="two-col">
                        {left}
                        {right}
                    </div>
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(withRouter(ProfileForTxCard));