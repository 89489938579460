import React from 'react';
import { withTranslation } from 'react-i18next';
import './NPDateInput.css';
import { ReactComponent as WarningSVG } from '../icons/exclamation-mark.svg';
import { ReactComponent as TickSVG } from '../icons/tick.svg';
import { ReactComponent as InfoSVG } from '../icons/info.svg';
import { ReactComponent as CalendarSVG } from '../icons/calendar.svg';
import NPPopup from '../comp/popup/NPPopup';
import helpMeSVG from '../images/help.svg';
import NPButton from '../comp/NPButton';
import moment, { months } from 'moment-timezone';

class NPDateInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            validationErrors: null,
            validationPopupOpen: false,
            validationPopupPosition: { top: 0, left: 0 },
            value: this.props.prefilled
        };

        this.infoIconWidth = 20;
        this.infoIconMargin = 12;
        this.feedbackIconMargin = 12;

        this.validate = this.validate.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
        // Define the position of the validation popup
        let element = this.el;

        this.setState({
            validationPopupPosition: {
                top: element.clientHeight / 2,
                left: element.clientWidth + 12
            },
            infoPopupPosition: {
                top: element.clientHeight / 2,
                left: element.clientWidth - 34
            },
            infoPopupWidth: element.clientWidth / 1.3
        })

    }


    validate(value) {

        const { t } = this.props;

        let validationErrors = [];
        let validationPromises = [];

        // Validations
        validationPromises.push(new Promise((success, failure) => {

            // Mandatoriness
            if (this.props.mandatory) {
                if (!value) validationErrors.push({ message: t('forms.validation.error.mandatory') });
                else if (value.trim() == '') validationErrors.push({ message: t('forms.validation.error.mandatory') });
            }

            // Format
            if (value && value != '' && !/^\d{1,2}\W\d{1,2}\W\d{4}$/.test(value)) {
                validationErrors.push({ message: t('forms.validation.error.date.format') });
            }

            success();
        }));

        // If a validator has been passed use it
        if (this.props.validator) validationPromises.push(new Promise((success, failure) => {

            this.props.validator(value).then(() => { success(); }, (validationError) => {

                validationErrors.push(validationError);

                success();

            });
        }));

        Promise.all(validationPromises).then(() => {

            // Show validation errors, if any
            if (validationErrors.length > 0) {
                this.setState({
                    validationErrors: validationErrors
                })
            }
            else this.setState({ validationErrors: [] });
        })

    }

    changeValue(val) {

        let value = val.target.value;

        this.setState((prevState) => {

            // 1. Let's replace any non number with a '.'
            value = value.replace(/[^0-9]+/g, '.');

            // DAY -------------------------
            // 2. The string can only start with a number
            if (/^[^0-9]$/.test(value)) value = '';

            // 3. Check the range of days => max 31 days 
            if ('00' == value) value = '0';
            if (/^3[2-9]$/.test(value)) value = '3';

            // 4. If the first char is zero, the second has to be a number
            if (/^0[^1-9]$/.test(value)) value = '0';

            // 5. If the user puts a '.' as a second char, add a zero (1. => 01.)
            if (/^[1-9]\.$/.test(value)) value = '0' + value;

            // 6. If there are two digits, there can only be a dot after 
            if (/^[0-9]{3}$/.test(value)) value = value.substring(0, 2);

            // MONTH -----------------------
            if (/^[0-9]{2}\..*$/.test(value)) {

                let dayString = value.substring(0, 3);
                let monthString = value.substring(3);

                // 6. Avoid 00 and 0.
                if ('00' == monthString) monthString = '0';
                if ('0.' == monthString) monthString = '0';

                // 7. If the user puts a '.' as a second char, add a zero (1. => 01.)
                if (/^[1-9]\.$/.test(monthString)) monthString = '0' + monthString;

                // 8. If the first char is 1, the second char has to be 0, 1 or 2
                if (/^1[3-9]$/.test(monthString)) monthString = '1';

                // 9. If the first char is 2+ => make it a 02, 03, etc..
                if (/^[2-9]$/.test(monthString)) monthString = '0' + monthString;

                // 10. Only 2 digits months
                if (/^[0-9]{3,}$/.test(monthString)) monthString = monthString.substring(0, 2);

                value = dayString + monthString;

                // YEAR -----------------------
                if(/^[0-9]{2}\.[0-9]{2}\..*$/.test(value)) {

                    monthString = value.substring(3, 6);
                    let yearString = value.substring(6);

                    // 11. Year can only start with 1 or 2
                    if (yearString == 0) yearString = '';
                    if (/^[3-9].*$/.test(yearString)) yearString = '';

                    // 12. Second char must be a number
                    if (/^[0-9][^0-9]$/.test(yearString)) yearString = yearString.substring(0, 1);

                    // 13. Third char must be a number
                    if (/^[0-9]{2}[^0-9]$/.test(yearString)) yearString = yearString.substring(0, 2);

                    // 14. Fourth char must be a number
                    if (/^[0-9]{3}[^0-9]$/.test(yearString)) yearString = yearString.substring(0, 3);

                    // 15. Max length must be 4 numbers
                    if (/^[0-9]{4}.*$/.test(yearString)) yearString = yearString.substring(0, 4);

                    value = dayString + monthString + yearString;
                }
                
            }

            return { value: value }

        }, () => {
            if (this.props.onChange) this.props.onChange(this.state.value);
        });


    }

    onButtonClick() { }

    onBlur(event) {

        let value = event.target.value;

        if (!this.props.button) this.validate(value);

    }

    render() {

        const { t } = this.props;

        // Classes 
        let textInputClass = 'outline-primary';
        if (this.state.validationErrors && this.state.validationErrors.length > 0) textInputClass += ' border-complementary';
        else textInputClass += ' border-ok';

        let textInputContainerClass = 'text-input-container';
        if (this.props.style) textInputContainerClass += ' ' + this.props.style;

        // Feedback icon (Validation result)
        let icon;
        let iconRightPosition = (this.props.info ? (this.infoIconMargin + this.infoIconWidth + this.feedbackIconMargin) : this.feedbackIconMargin) + 'px';
        if (this.state.validationErrors && this.state.validationErrors.length > 0) icon = (
            <div className="feedback-icon complementary" style={{ right: iconRightPosition }}>
                <WarningSVG />
            </div>
        )
        else if (this.state.validationErrors && this.state.validationErrors.length == 0) icon = (
            <div className="feedback-icon primary" style={{ right: iconRightPosition }}>
                <TickSVG />
            </div>
        )
        else icon = (
            <div className="feedback-icon primary" style={{ right: iconRightPosition }}>
                <CalendarSVG />
            </div>
        )

        // Info icon
        let infoIcon;
        if (this.props.info) infoIcon = (
            <div className="info-icon accent" onMouseOver={() => { this.setState({ infoPopupOpen: true }) }} onMouseOut={() => { this.setState({ infoPopupOpen: false }) }}>
                <InfoSVG />
            </div>
        )

        // Info popup
        let infoPopup;
        if (this.props.info && this.state.infoPopupOpen) infoPopup = (
            <NPPopup
                position={this.state.infoPopupPosition}
                style="info"
                horizontalPlacement="left"
                verticalPlacement="centered"
                width={this.state.infoPopupWidth}
            >
                <div className="info-popup-content">
                    <div className="info-popup-img"><img src={helpMeSVG} width="100%" /></div>
                    <div className="info-popup-text">{this.props.info}</div>
                </div>
            </NPPopup>
        )

        // Validation Popup
        let popup;
        if (this.state.validationErrors && this.state.validationErrors.length > 0) popup = (
            <NPPopup
                position={this.state.validationPopupPosition}
                verticalPlacement="centered"
                style="validation"
                wrapText={false}
            >
                {this.state.validationErrors[0].message}
            </NPPopup>
        )

        // Button
        let button;
        if (this.props.button) button = (
            <NPButton label={this.props.button.label} onClick={this.onButtonClick} />
        )

        return (
            <div className="np-date-input">
                <div className="np-input-label">
                    {this.props.label}
                </div>
                <div className="npti-content">
                    <div className={textInputContainerClass} ref={(el) => { this.el = el }} onMouseOver={() => { this.setState({ validationPopupOpen: true }) }} onMouseOut={() => { this.setState({ validationPopupOpen: false }) }}>
                        <input
                            className={textInputClass}
                            type="text"
                            placeholder="DD.MM.YYYY"
                            onBlur={this.onBlur}
                            value={this.state.value}
                            onChange={this.changeValue}
                        />
                        {infoIcon}
                        {infoPopup}
                        {icon}
                        {this.state.validationErrors && this.state.validationPopupOpen && popup}
                    </div>
                    <div className="npti-button-container">
                        {button}
                    </div>
                </div>
            </div>
        )
    }

}
export default withTranslation()(NPDateInput);