import { useEffect, useState } from 'react'
import AuthAPI from '../../api/AuthAPI'
import NPLabeledValue from '../../comp/NPLabeledValue'
import { ReactComponent as AgencySVG } from '../../icons/agency.svg'
import NPCard from '../NPCard'
import './ProfileAgencyCard.css'

export function ProfileAgencyCard({ profile }) {

    const [agency, setAgency] = useState()

    const loadAgencyDetails = async () => {

        if (!profile && !profile.agencyId) return;

        const apiResponse = await new AuthAPI().getRegistrationDetails(profile.agencyId);

        setAgency(apiResponse);
    }

    useEffect(() => { loadAgencyDetails() }, [])

    return (
        <NPCard>
            <div className='agency-card-content'>
                <div className='header'>
                    <div className='icon'><AgencySVG /></div>
                    <div className="title">Utente proveniente da Agenzia</div>
                </div>
                <div className='card-content row mobile-col'>
                    <div style={{ flex: 1 }}>
                        <NPLabeledValue marginVertical={6} label="Id Agenzia" value={profile.agencyId} />
                    </div>
                    <div style={{ flex: 1 }}>
                        {agency && <NPLabeledValue marginVertical={6} label="Email" value={agency.email} />}
                    </div>
                </div>
            </div>
        </NPCard>
    )

}