import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Stores the google user in cookies
 * @param {object} authenticatedUser the user to store. Contains the following
 * - userId - the user ID
 * - email  - the user email
 * - name - the user complete name
 * - token - the received token
 * 
 * Optionally, these fields can be passed, if available (based on the auth provider): 
 * - givenName - user first name
 * - familyName - ser last name
 */
export function storeUser(authenticatedUser, authProvider) {

    // Define the user
    let user = { 
        userId: authenticatedUser.userId,
        authProvider: authProvider, 
        name: authenticatedUser.name, 
        givenName: authenticatedUser.givenName, 
        familyName : authenticatedUser.familyName, 
        email: authenticatedUser.email, 
        idToken: authenticatedUser.token, 
        role: authenticatedUser.role
     };

    // Set the cookies
    cookies.set('user', user, { path: '/' });

}