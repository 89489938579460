import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NPCard from '../NPCard';
import './NewProfileCard.css';
import { ReactComponent as ProfileSVG } from '../../images/profile.svg';
import { withRouter } from 'react-router-dom';

class NewProfileCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.onNewProfileClick = this.onNewProfileClick.bind(this);
    }

    onNewProfileClick() {
        this.props.history.push('/profile/new');
    }

    render() {

        const { t } = this.props;

        return (
            <NPCard type="action" onClick={this.onNewProfileClick}>
                <div className="dashboard-profile-create-button">
                    <div className="image-container"><ProfileSVG /></div>
                    <div className="text-container">
                        <div className="font-xlarge bold">{t('dashboard.button.create.profile')}</div>
                        <div className="font-normal bold desc">{t('dashboard.explain.create.profile')}</div>
                    </div>
                </div>
            </NPCard>
        )

    }
}

export default withRouter(withTranslation()(NewProfileCard));