import React, { Component } from 'react';
import './NPProgressBar.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';

/**
 * Progress bar for forms, profile completion, etc..
 * 
 * Parameters: 
 * 
 *  - steps                 : (MAND) (int) number of steps to show
 *  - currentStep           : (MAND) (int) the number of the current step, 1-based
 *  - currentStepAvatar     : (OPT) (ReactComponent) react component to use as an avatar for the current step
 *  - warning               : (OPT, default false) pass true to highlight the current step as a warning
 * 
 */
export default class NPProgressBar extends Component {

    constructor(props) {
        super(props);

        this.state = { }

    }

    render() {

        // Building the steps!
        let steps = [];
        for (var i = 0; i < this.props.steps; i++) {

            let stepContainerClass = 'step-container';
            if (this.props.warning && i == this.props.currentStep - 1) stepContainerClass += ' complementary';
            else if (i == this.props.currentStep - 1) stepContainerClass += ' primary reduced-opacity';
            else if (i < this.props.currentStep - 1) stepContainerClass += ' primary';
            if (i >= this.props.currentStep) stepContainerClass += ' greyed';

            let stepClass = 'step';
            if (i == 0) stepClass += ' first';
            if (i == this.props.steps - 1) stepClass += ' last';

            let avatar;
            if (this.props.currentStepAvatar && i == this.props.currentStep - 1) avatar = (
                <div className="avatar">
                    {React.cloneElement(this.props.currentStepAvatar)}
                </div>
            )
            if (this.props.lastStepAvatar && i == this.props.steps - 1) avatar = (
                <div className="avatar">
                    {React.cloneElement(this.props.lastStepAvatar)}
                </div>
            )

            let step = (
                <div key={'step-' + i} className={stepContainerClass} >
                    <div className={stepClass}></div>
                    {avatar}
                </div>
            )

            steps.push(step);
        }

        let popupImageClass = 'image';
        if (this.props.warning) popupImageClass += ' complementary';

        let popupContent = (
            <div className="np-progress-bar-popup-content">
                <div className={popupImageClass}>
                    {this.props.currentStepAvatar && React.cloneElement(this.props.currentStepAvatar)}
                </div>
                <div className="text">
                    {this.props.currentStepDescription}
                </div>
            </div>
        )

        return (
            <Tippy content={popupContent} theme="light">
                <div className="profile-progress-container">
                    {steps}
                </div>
            </Tippy>
        )
    }
}
