import React from 'react';
import './NPPhoto.css';
import Popup from 'reactjs-popup';
import { ReactComponent as NoPhotoSVG } from '../icons/no-photo.svg';
import { ReactComponent as VisionSVG } from '../icons/vision.svg';
import NPIconButton from './NPIconButton';
import { withTranslation } from 'react-i18next';

/**
 * Parameters
 * 
 *  - ocrLink           :   (OPT, default false) - Boolean - Shows a link to access the OCR data
 */
class NPPhoto extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            popup: false
        }

        this.enlargeImage = this.enlargeImage.bind(this);
        this.onPictureLoaded = this.onPictureLoaded.bind(this);
    }

    enlargeImage(evt) {

        evt.stopPropagation();

        if (this.props.photo) {
            this.setState({
                popup: true
            })
        }
    }

    /**
     * When the picture has loaded
     * @param {event} event the event
     */
    onPictureLoaded(event) {

        let pic = event.target;
        let picContainer = pic.parentNode;

        let width = pic.offsetWidth;
        let height = pic.offsetHeight;

        let containerWidth = picContainer.offsetWidth;
        let containerHeight = picContainer.offsetHeight;

        let ratio = width / height;

        if (width > height) {
            let newWidth = containerWidth * 1.1;
            pic.width = newWidth;
            pic.height = newWidth / ratio;
            pic.style.top = '-' + ((pic.height - containerHeight) / 2) + 'px';
            pic.style.left = '-' + ((pic.width - containerWidth) / 2) + 'px';
        }
        else {
            // Important: those calculations seem a bit crazy, but it's because I'm gonna rotate the pic
            let newHeight = containerWidth * 1.1;
            pic.width = newHeight * ratio;
            pic.height = newHeight;
            pic.style.top = '-' + ((pic.height - containerHeight) / 2) + 'px';
            pic.style.left = '-' + ((pic.width - containerWidth) / 2) + 'px';
            pic.style.transform = 'rotate(90deg)';

            this.setState({
                mustBeRotated: true
            })
        }

    }

    render() {

        const { t } = this.props;

        let clazz = 'np-photo focused-easeInOut-motion';

        let image;
        if (this.props.photo) image = (
            <div className="uploaded-pic-container" onClick={this.enlargeImage}>
                <img className="uploaded-picture" src={this.props.photo} onLoad={this.onPictureLoaded} />
            </div>
        )
        else if (!this.props.photo) image = (
            <div className="no-pic-container complementary">
                <NoPhotoSVG />
            </div>
        )

        let ocrButton;
        if (this.props.ocrLink && this.props.ocrData) ocrButton = (
            <div className="button-container">
                <NPIconButton image={<VisionSVG />} tooltip={t('tooltip.button.view.ocr.data')} onClick={() => { this.setState({ ocrPopup: true }) }} />
            </div>
        )

        return (
            <div className={clazz} >
                {image}
                {ocrButton}

                <Popup open={this.state.popup} onClose={() => { this.setState({ popup: false }) }}>
                    <div className="np-photo-popup">
                        <img className="enlarged-picture" src={this.props.photo} />
                    </div>
                </Popup>

                <Popup open={this.state.ocrPopup} onClose={() => { this.setState({ ocrPopup: false }) }}>
                    <div className="np-photo-ocr-popup">

                        <div className="font-xlarge title">{t('admin.profiles.ocr.tokens')}</div>
                        <div className="font-small subtitle">{this.props.ocrData ? this.props.ocrData.length : 0} {t('admin.profiles.ocr.tokens.found.on.picture')}</div>

                        {this.props.ocrData && this.props.ocrData.map((item, i) => {

                            return (
                                <div className="ocr-token font-small" key={'ocrToken-' + i}>
                                    {item}
                                </div>    
                            )
                        })} 
                    </div>
                </Popup>

            </div>
        )
    }
}

export default withTranslation()(NPPhoto);