import React from 'react';
import './TransactionKeyDetailsCard.css';
import NPCard from '../NPCard';
import { withTranslation } from 'react-i18next';
import NPLabeledValue from '../../comp/NPLabeledValue';
import { ReactComponent as TickSVG } from '../../icons/tick-circle.svg';
import { ReactComponent as WarnSVG } from '../../icons/exclamation-circle.svg';
import NPButton from '../../comp/NPButton';
import InvoiceAPI from '../../api/InvoiceAPI';
import Popup from 'reactjs-popup';
import { toHaveFocus } from '@testing-library/jest-dom/dist/matchers';

class TransactionKeyDetailsCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.onShowInvoiceData = this.onShowInvoiceData.bind(this);
    }

    async onShowInvoiceData() {

        try {

            this.setState({downloadingInvoiceData: true})
            
            const invoiceData = await new InvoiceAPI().getInvoiceData(this.props.transaction.id);
    
            let invoiceACubeRequest = "No information was sent to ACube";
            if (invoiceData && invoiceData.acubeRequest) {
                if (typeof invoiceData.acubeRequest == "string") invoiceACubeRequest = JSON.stringify(JSON.parse(invoiceData.acubeRequest), null, 2)
                else invoiceACubeRequest = JSON.stringify(invoiceData.acubeRequest, null, 2);
            }
    
            this.setState({
                popup: true, 
                downloadingInvoiceData: false, 
                invoiceACubeRequest: invoiceACubeRequest
            })

        } catch (error) {

            this.setState({
                popup: true, 
                downloadingInvoiceData: false, 
                invoiceACubeRequest: "" + error
            })
            
        }

    }

    render() {

        const { t } = this.props;

        return (
            <NPCard>
                <div className="tx-key-details-card">
                    <div className="card-title font-large">{t('label.transaction.' + this.props.transaction.partyType)}</div>
                    <div className="two-col">
                        <div className="left">
                            <NPLabeledValue label="label.tx.name" value={this.props.transaction.name ? this.props.transaction.name : this.props.transaction.licensePlates} marginVertical="6px" />
                        </div>
                        <div className="right">
                            <NPLabeledValue label="label.tx.created.on" value={this.props.transaction.creationTime} type="datetime-nospace" marginVertical="6px" />
                        </div>
                    </div>
                    <NPLabeledValue label="label.iban" value={this.props.transaction.iban} marginVertical="6px" />
                    <div className="button-container">
                        <NPButton label={!this.state.downloadingInvoiceData ? t("button.label.see.acube.invoice") : t("button.label.see.acube.invoice.ongoing") } disabled={this.state.downloadingInvoiceData} filled={false} outline={true} onClick={this.onShowInvoiceData} />
                    </div>
                </div>

                <Popup open={this.state.popup} onClose={() => { this.setState({ popup: false }) }}>
                        <NPCard>
                            <div className="acube-invoice-data-container">
                                <pre>{this.state.invoiceACubeRequest}</pre>
                            </div>
                        </NPCard>
                    </Popup>
            </NPCard>
        )
    }
}

export default withTranslation()(TransactionKeyDetailsCard);