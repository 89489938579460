import React from 'react';
import './NPOCRConfidence.css';
import { ReactComponent as RobotSVG } from '../images/robot.svg';
import { withTranslation } from 'react-i18next';

class NPOCRConfidence extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {

        const { t } = this.props;

        // Styles
        let confidenceBarClass = 'confidence-bar';
        let confidenceVoteClass = 'confidence-vote';

        if (this.props.ocrPass && this.props.cfPass) {
            confidenceBarClass += ' fill-primary';
            confidenceVoteClass += ' border-primary primary';
        }
        else if (this.props.profileVerified) {
            confidenceBarClass += ' fill-primary';
            confidenceVoteClass += ' border-primary primary';
        }
        else {
            confidenceBarClass += ' fill-complementary';
            confidenceVoteClass += ' border-complementary complementary';
        }

        // Advice
        let advice = t('profile.ocr.confidence.advice.manual.check');
        if (this.props.profileVerified) advice = t('profile.ocr.confidence.advice.verified');
        else if (this.props.requestedUserFix) advice = t('profile.ocr.confidence.advice.userfix')

        return (
            <div className="np-ocr-confidence">
                <div className={confidenceBarClass}></div>
                <div className="advice-container">
                    <div className="font-large advice">{advice}</div>
                    {this.props.messageToUser &&
                        <div className="user-message">
                            <span className="label">Messaggio all'utente: </span>" {this.props.messageToUser} "
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withTranslation()(NPOCRConfidence);