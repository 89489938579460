import './AdminScreen.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import withProtection from './hoc/WithProtection';
import NPAdminMenuCard from '../cards/NPAdminMenuCard';
import Cookies from 'universal-cookie';
import { ReactComponent as UserSVG } from '../icons/user.svg';
import { ReactComponent as HandshakeSVG } from '../icons/handshake.svg';
import { ReactComponent as UserTechSVG } from '../icons/user-tech.svg';
import { ReactComponent as AgencySVG } from '../icons/agency.svg';
import { ReactComponent as MarketplaceSVG } from '../icons/marketplace.svg';

const cookies = new Cookies();

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {

    }

    render() {

        const { t } = this.props;

        return (
            <div class="admin-dashboard">
                {/* <NPActionCard img={paySVG} title={t('admin.card.upload.pay.title')} text={t('admin.card.upload.pay.desc')} onClick={() => {this.props.history.push('/admin/pup')}}/> */}
                <NPAdminMenuCard img={<UserTechSVG/>} title="Utenze Pleggit" onClick={() => { this.props.history.push('/admin/users') }} />
                <NPAdminMenuCard img={<UserSVG/>} title="Profili Pleggit" onClick={() => { this.props.history.push('/admin/profiles') }} />
                <NPAdminMenuCard img={<HandshakeSVG/>} title="Transazioni" onClick={() => { this.props.history.push('/admin/transactions') }} />
                <NPAdminMenuCard img={<AgencySVG/>} title="Transazioni Agenzia" onClick={() => { this.props.history.push('/admin/agencyTransactions') }} />
                <NPAdminMenuCard img={<MarketplaceSVG/>} title="Transazioni Marketplace" onClick={() => { this.props.history.push('/admin/mktTransactions') }} />
                {/* <NPAdminMenuCard img={rebatesSVG} title={t('admin.card.rebates.title')} text={t('admin.card.rebates.desc')} onClick={() => { this.props.history.push('/admin/rebates') }} /> */}
                {/* <NPAdminMenuCard img={monkeySVG} title={t('admin.card.monkey.title')} text={t('admin.card.monkey.desc')} onClick={() => {this.props.history.push('/admin/monkey')}}/> */}
            </div>
        )

    }
}

class Title extends Component {

    render() {

        const { t } = this.props;

        let user = cookies.get('user');
        if (!user || !user.givenName) console.log("[dashboard] - For some reason the user cookie or the user's given name was not found...");

        return (
            <div></div>
        )
    }
}

export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(Content)),
        "wide"
    )
);